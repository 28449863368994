import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getUserName,
  getId,
  setPassword,
  setFirstTimeLogin,
} from '../../localStorage';
import {
  resetNotification,
  setNotification,
} from '../Notification/actions';
import { modifyUser, getAccountInformation } from '../../api';
import { NotificationTypes } from '../../constantsApp';
import { mutationRequest, queryRequest } from '../../utils/request';
import { HANDLE_CHANGE_PASSWORD, GET_ACCOUNT_INFORMATION } from './constants';

// ///start
export function* changePasswordSaga({ newPassword, cb }) {
  yield put(resetNotification());
  const payload = {
    id: getId(),
    userId: getUserName(),
    password: newPassword,
  };
  try {
    const response = yield call(mutationRequest, modifyUser(payload));
    if (response && response.modifyUser) {
      setPassword(newPassword);
      setFirstTimeLogin(false);
      if (cb) {
        cb(true);
      }
      yield put(
        setNotification({
          type: NotificationTypes.success,
          message: 'Modify password success',
        }),
      );
    } else {
      if (cb) {
        cb(false);
      }
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: `Modify password failed `,
        }),
      );
    }
  } catch (err) {
    const messageErr = `Modify password failed ${err}`;
    if (cb) {
      cb(false);
    }
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: messageErr,
      }),
    );
  }
}

export function* getAccountInformationSaga({ payload, cb }) {
  try {
    const response = yield call(queryRequest, getAccountInformation(payload));
    if (response.getAccountInformation) {
      if (cb) cb({ success: true, data: response.getAccountInformation });
    } else if (cb) cb({ success: false, data: {} });
  } catch (err) {
    if (cb) cb({ success: false, data: {} });
  }
}

// Individual exports for testing
export default function* dashBoardPageSaga() {
  yield takeLatest(HANDLE_CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(GET_ACCOUNT_INFORMATION, getAccountInformationSaga);
}
