import { typePermissons, groupNamePermissions } from './constants';

const getTypePermission = ({ listPermission, type }) => {
  if (listPermission.length) {
    const itemCheck = listPermission.filter(item => item.permission === type)[0];
    if (itemCheck && itemCheck.type === typePermissons.readOnly) return 1;
    if (itemCheck && itemCheck.type === typePermissons.readWrite) return 2;
  }
  return 0;
};

const checkPermissionCreateUser = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.createUser,
  });

const checkPermissionProcessAccount = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.processAccount,
  });

const checkPermissionSearchOffers = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.searchOffers,
  });

const checkPermissionAddCreditCard = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.addCreditCard,
  });

const checkPermissionModifyCreditCard = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.modifyCreditCard,
  });

const checkPermissionModifyOffers = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.modifyOffers,
  });

const checkPermissionViewSubscription = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.viewSubscription,
  });

const checkPermissionViewBalances = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.viewBalances,
  });

const checkPermissionViewBills = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.viewBills,
  });

const checkPermissionViewInvoice = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.viewInvoice,
  });

const checkPermissionViewTransactions = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.viewTransactions,
  });

const checkPermissionApplyPayment = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.applyPayment,
  });

const checkPermissionModifyUser = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.modifyUser,
  });

const checkPermissionModifyContact = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.modifyContact,
  });

const checkPermissionThirdPartySelfCare = ({ listPermission = [] }) =>
  getTypePermission({
    listPermission,
    type: groupNamePermissions.thirdPartySelfCare,
  });

export {
  checkPermissionCreateUser,
  checkPermissionProcessAccount,
  checkPermissionSearchOffers,
  checkPermissionAddCreditCard,
  checkPermissionModifyCreditCard,
  checkPermissionModifyOffers,
  checkPermissionViewSubscription,
  checkPermissionViewBalances,
  checkPermissionViewBills,
  checkPermissionViewInvoice,
  checkPermissionViewTransactions,
  checkPermissionApplyPayment,
  checkPermissionModifyUser,
  checkPermissionModifyContact,
  checkPermissionThirdPartySelfCare,
};
