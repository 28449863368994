export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('persistState');
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
const whiteList = ['token', 'sidebar'];
export const saveState = state => {
  try {
    const dupState = {};
    whiteList.map(key => (dupState[key] = state[key]));
    const serializedState = JSON.stringify(dupState);
    localStorage.setItem('persistState', serializedState);
  } catch (err) {
    console.log('saveState error', err);
  }
};

export function getAccountId() {
  const result = localStorage.getItem('accountId') ? localStorage.getItem('accountId') : '';
  return result;
}
export function setAccountId(accountId) {
  localStorage.setItem('accountId', accountId);
}

export function getId() {
  return localStorage.getItem('id') ? localStorage.getItem('id') : '';
}
export function setId(id) {
  localStorage.setItem('id', id);
}

export function getUserName() {
  const result = localStorage.getItem('userName') ? localStorage.getItem('userName') : '';
  return result;
}
export function setUserName(userName) {
  localStorage.setItem('userName', userName);
}

export function getPassword() {
  const result = localStorage.getItem('password') ? localStorage.getItem('password') : '';
  return result;
}
export function setPassword(password) {
  localStorage.setItem('password', password);
}

export function getFirstTimeLogin() {
  return localStorage.getItem('isFirstTimeLogin') ? localStorage.getItem('isFirstTimeLogin') : '';
}
export function setFirstTimeLogin(isFirstTimeLogin) {
  localStorage.setItem('isFirstTimeLogin', isFirstTimeLogin);
}

export function getLogoBase64() {
  return localStorage.getItem('lgB64') || '';
}

export function setLogoBase64(logo) {
  localStorage.setItem('lgB64', logo);
}

export function getRounding() {
  return localStorage.getItem('rounding') || '';
}

export function setRounding(rounding) {
  localStorage.setItem('rounding', rounding);
}
