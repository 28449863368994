/* eslint-disable indent */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { SLIDE_UP } from 'react-ladda';
import PropTypes from 'prop-types';
import injectSaga from '../../utils/injectSaga';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { getCities, getDataCardFromURL } from '../../utils/utils';
import imgAccountDetails from '../../assets/images/account_details.png';
import imgCreditCard from '../../assets/images/credit_card.png';
import imgSelectPackage from '../../assets/images/select_package.png';
import planning from '../../assets/images/planning.png';
import imgCreatePackage from '../../assets/images/img-create-package.png';
import { cardTokenFields } from '../../constantsApp';
import Switch from 'react-switch';
import {
  getSelfCareOffers,
  getBundleByPackageId,
  searchPackage,
} from '../../containers/App/actions';
import {
  AccountDetails,
  ProgressBarRegister,
  CredidCardDetails,
  CreatePackageDetails,
  SelectPackage,
  CreatePackage,
  CreateUserWithAccountExist,
} from '../../components/RegisterNew';
import { createSelfCare, searchBundles } from './actions';
import saga from './saga';
import './styles.scss';
import LogoPng from '../../assets/images/logo-01.svg';
import { ButtonCustom } from '../../components/common';
import constants from './constants';

const creditCardDataCallback = {
  cardToken: '',
  cardExpiry: '',
  last4CC: '',
  merchant: 'qabrain3',
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listValidateStage0: [],
      listValidateStage1: [],
      dataCreate: {
        packageId: '',
        selfCareAddress: { country: 'USA', state: '', city: '' },
        selfCareContact: {},
        creditCard: { merchant: 'embrix' },
        bundles: [],
      },
      optionCity: [
        {
          label: props.t('message.msgSelectState'),
          value: 'Please select State before',
          isDisabled: true,
        },
      ],
      stage: 0,
      focusCard: '',
      listBundleIdSelected: [],
      isCreateUserWithAccountExist: false,
      dataCreateWithAccountExist: {},
      packageNameSelected: '',
    };
  }

  componentDidMount() {
    const urlTokenCreditCard = window.location.href;
    if (urlTokenCreditCard) {
      creditCardDataCallback.cardToken = getDataCardFromURL(
        urlTokenCreditCard,
        cardTokenFields.token,
      );
      creditCardDataCallback.cardExpiry = getDataCardFromURL(
        urlTokenCreditCard,
        cardTokenFields.expiryDate,
      );
      creditCardDataCallback.last4CC = getDataCardFromURL(
        urlTokenCreditCard,
        cardTokenFields.last4cc,
      );
    }
    if (
      creditCardDataCallback.cardToken &&
      creditCardDataCallback.cardExpiry &&
      creditCardDataCallback.last4CC &&
      localStorage.getItem('stateCallback')
    ) {
      const stateCallback = JSON.parse(localStorage.getItem('stateCallback'));
      if (stateCallback && stateCallback.dataCreate) {
        stateCallback.dataCreate.creditCard = creditCardDataCallback;
        this.setState(
          {
            dataCreate: stateCallback.dataCreate,
            stage: stateCallback.stage,
          },
          () => localStorage.removeItem('stateCallback'),
        );
      }
    }
  }

  onChange = ({ name, value, subFieldName = '' }) => {
    const { dataCreate } = this.state;
    const newDataCreate = cloneDeep(dataCreate);
    const focusCard = name === 'last4CC' ? 'cvc' : '';
    if (name === 'last4CC' && value.length > 4) return;
    if (!subFieldName) {
      newDataCreate[name] = value;
    }
    if (subFieldName) {
      newDataCreate[subFieldName][name] = value;
    }
    this.setState({
      dataCreate: newDataCreate,
      focusCard,
    });
  };

  onFocusSelectCity = () => {
    const {
      dataCreate: {
        selfCareAddress: { state },
      },
    } = this.state;
    if (state) {
      const newOptionCity = getCities(state).map(item => ({
        label: item.city,
        value: item.city,
      }));
      this.setState({ optionCity: newOptionCity });
    }
  };

  onBlurSelectCity = () => {
    this.setState({
      optionCity: [
        {
          label: 'Please select State before',
          value: 'Please select State before',
          isDisabled: true,
        },
      ],
    });
  };

  validateForm = step => {
    const {
      stage,
      dataCreate: {
        selfCareContact: { firstName, lastName, email, number },
        selfCareAddress: { street, city, state, country, postalCode },
        password,
        retypePassword,
        userId,
        creditCard: { cardToken, cardExpiry, last4CC },
      },
    } = this.state;

    if (stage === 0) {
      const listValidateStage0 = [];
      if (!firstName) listValidateStage0.push('firstName');
      if (!lastName) listValidateStage0.push('lastName');
      if (!email) listValidateStage0.push('email');
      if (!number) listValidateStage0.push('number');
      if (!street) listValidateStage0.push('street');
      if (!city) listValidateStage0.push('city');
      if (!state) listValidateStage0.push('state');
      if (!postalCode) listValidateStage0.push('postalCode');
      if (!country) listValidateStage0.push('country');
      if (!password) listValidateStage0.push('password');
      if (!retypePassword) listValidateStage0.push('retypePassword');
      if (!userId) listValidateStage0.push('userId');

      if (listValidateStage0.length)
        return this.setState({ listValidateStage0 });
    }

    if (stage === 1) {
      const listValidateStage1 = [];
      if (!cardToken) listValidateStage1.push('cardToken');
      if (!cardExpiry) listValidateStage1.push('cardExpiry');
      if (!last4CC) listValidateStage1.push('last4CC');
      if (listValidateStage1.length)
        return this.setState({ listValidateStage1 });
    }
    return this.setState({ stage: step || stage + 1 });
  };

  onHandleNextButton = () => {
    this.validateForm();
  };

  onHandlePreviousButton = () => {
    const { stage } = this.state;
    this.setState({ stage: stage - 1 });
  };

  onChangeStage = stage => {
    this.validateForm(stage);
    // this.setState({ stage });
  };

  onSelectPackage = (packageId, packageName) => {
    const { dataCreate } = this.state;
    const newDataCreate = cloneDeep(dataCreate);
    newDataCreate.packageId = packageId;
    this.setState(
      { dataCreate: newDataCreate, packageNameSelected: packageName },
      () => this.doGetBundleByPackageId(),
    );
  };

  doGetBundleByPackageId = () => {
    const { dataCreate } = this.state;
    const newDataCreate = cloneDeep(dataCreate);
    this.props.getBundleByPackageId(
      newDataCreate.packageId,
      ({ success, data }) => {
        if (success && data.length) {
          newDataCreate.bundles = data;
          this.setState({ dataCreate: newDataCreate });
        }
      },
    );
  };

  onHandleSelectNewBundle = bundles => {
    this.setState({ listBundleIdSelected: bundles });
  };

  onHandleSubmit = () => {
    const {
      dataCreate,
      stage,
      listBundleIdSelected,
      isCreateUserWithAccountExist,
      dataCreateWithAccountExist,
    } = this.state;
    if (!isCreateUserWithAccountExist) {
      const newPayload = cloneDeep(dataCreate);
      delete newPayload.retypePassword;
      if (stage === 2) {
        delete newPayload.bundles;
      }
      if (stage === 3) {
        delete newPayload.packageId;
        const listBundles = listBundleIdSelected.flatMap(item => item.bundleId);
        let newListBundleIds = '';
        listBundles.forEach(el => {
          newListBundleIds += `bundleId: "${el.bundleId}", `;
        });
        newListBundleIds = `[{${newListBundleIds}}]`;
        newPayload.bundles = newListBundleIds;
      }
      return this.props.createSelfCare(newPayload);
    }
    const newPayloadWithAccountExist = cloneDeep(dataCreateWithAccountExist);
    if (
      !newPayloadWithAccountExist.password ||
      newPayloadWithAccountExist.password !==
        newPayloadWithAccountExist.retypePassword
    ) {
      return alert('Entered Password does not matching');
    }
    delete newPayloadWithAccountExist.retypePassword;
    return this.props.createSelfCare(newPayloadWithAccountExist);
  };

  onChangeModePackage = checked => {
    this.setState({ stage: checked ? 3 : 2 });
  };

  onChangeModeExistAccount = checked => {
    this.setState({ isCreateUserWithAccountExist: checked });
  };

  onHandleChangeDataWithAccountExist = ({ name, value }) => {
    const { dataCreateWithAccountExist } = this.state;
    const newData = cloneDeep(dataCreateWithAccountExist);
    newData[name] = value;
    this.setState({ dataCreateWithAccountExist: newData });
  };

  render() {
    const {
      dataCreate,
      optionCity,
      stage,
      focusCard,
      isCreateUserWithAccountExist,
      dataCreateWithAccountExist,
      packageNameSelected,
      listValidateStage0,
      listValidateStage1,
    } = this.state;
    const { t } = this.props;
    let titleLeftView = '';
    let contentLeftView = '';
    let srcImageLeftView = '';
    if (stage === 0) {
      titleLeftView = t('label.accountDetails');
      contentLeftView = t(constants.contentLeftViewAccountDetails);
      srcImageLeftView = imgAccountDetails;
    }

    if (stage === 1) {
      titleLeftView = t('label.creditCardDetails');
      contentLeftView = t(constants.contentLeftViewCreditCardDetails);
      srcImageLeftView = imgCreditCard;
    }

    if (stage === 2) {
      titleLeftView = t('label.selectPackage');
      contentLeftView = t(constants.contentLeftViewSelectPackage);
      srcImageLeftView = imgSelectPackage;
    }

    if (stage === 3) {
      titleLeftView = t('label.createPackage');
      contentLeftView = t(constants.contentLeftViewCreatePackage);
      srcImageLeftView = imgCreatePackage;
    }

    if (isCreateUserWithAccountExist) {
      titleLeftView = t('label.createUser');
      contentLeftView = t(constants.contentLeftViewCreateUser);
      srcImageLeftView = planning;
    }

    return (
      <div className="register-page">
        <div className="register-page__form d-flex flex-wrap">
          <div className="col-md-12 mr-5">
            <img src={LogoPng} alt="logo" className="logo-company" />
          </div>
        </div>
        <ProgressBarRegister
          stage={stage}
          onChangeStage={this.onChangeStage}
          isExistAccount={isCreateUserWithAccountExist}
        />
        <div className="col-md-12 d-flex form-register">
          <div className="row col-md-12 no-padding">
            <CreatePackageDetails
              title={titleLeftView}
              contentView={contentLeftView}
              imageView={srcImageLeftView}
            />
            <div className="col-md-9">
              {(stage === 2 || stage === 3) && (
                <div className="col-md-12 d-flex">
                  <h2 className="mr-2 mt-3">
                    {`${t('label.createYourPackage')} :`}
                  </h2>
                  <label className="mt-3 ml-1">
                    <Switch
                      onChange={this.onChangeModePackage}
                      checked={stage === 3}
                    />
                  </label>
                </div>
              )}
              {stage === 0 && (
                <div className="col-md-10 d-flex">
                  <h5 className="mr-2 ml-2 mt-2">
                    {t('message.msgHaveAccount')}
                  </h5>
                  <label className="d-flex mt-1">
                    <Switch
                      onChange={this.onChangeModeExistAccount}
                      checked={isCreateUserWithAccountExist}
                    />
                  </label>
                </div>
              )}
              {isCreateUserWithAccountExist && (
                <CreateUserWithAccountExist
                  data={dataCreateWithAccountExist}
                  onChange={this.onHandleChangeDataWithAccountExist}
                />
              )}
              {stage === 0 &&
                !isCreateUserWithAccountExist && (
                  <AccountDetails
                    data={dataCreate}
                    onChange={this.onChange}
                    optionCity={optionCity}
                    onFocusSelectCity={this.onFocusSelectCity}
                    onBlurSelectCity={this.onBlurSelectCity}
                    listValidateStage0={listValidateStage0}
                  />
                )}
              {stage === 1 &&
                !isCreateUserWithAccountExist && (
                  <CredidCardDetails
                    data={dataCreate}
                    onChange={this.onChange}
                    focusCard={focusCard}
                    stateCallBackFromURL={{ dataCreate, stage }}
                    listValidateStage1={listValidateStage1}
                  />
                )}

              {stage === 2 &&
                !isCreateUserWithAccountExist && (
                  <SelectPackage
                    data={dataCreate}
                    onChange={this.onChange}
                    getSelfCareOffers={this.props.getSelfCareOffers}
                    onSelectPackage={this.onSelectPackage}
                    packageNameSelected={packageNameSelected}
                    searchPackage={this.props.searchPackage}
                  />
                )}

              {stage === 3 &&
                !isCreateUserWithAccountExist && (
                  <CreatePackage
                    data={dataCreate}
                    searchBundles={this.props.searchBundles}
                    onHandleSelectBundle={this.onHandleSelectNewBundle}
                  />
                )}
              <div className="group-button col-md-12 p-0">
                {!isCreateUserWithAccountExist && (
                  <ButtonCustom
                    className="btn-submit btn-center"
                    type="button"
                    title={`<< ${t('label.previous')}`}
                    datastyle={SLIDE_UP}
                    disabled={stage === 0}
                    onClick={() => {
                      this.setState({ stage: stage - 1 });
                    }}
                  />
                )}
                <ButtonCustom
                  className={`btn-center btn-submit ml-2 ${
                    isCreateUserWithAccountExist
                      ? 'single-btn mr-2'
                      : 'multi-btn'
                  }`}
                  type="button"
                  title={
                    stage === 2 || stage === 3 || isCreateUserWithAccountExist
                      ? t('label.submit').toUpperCase()
                      : `${t('label.next')} >>`
                  }
                  onClick={
                    stage === 2 || stage === 3 || isCreateUserWithAccountExist
                      ? this.onHandleSubmit
                      : this.onHandleNextButton
                  }
                  datastyle={SLIDE_UP}
                />
                <ButtonCustom
                  className="btn-cancel btn-right"
                  type="button"
                  title={t('label.cancel')}
                  datastyle={SLIDE_UP}
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  getBundleByPackageId: PropTypes.func,
  createSelfCare: PropTypes.func,
  getSelfCareOffers: PropTypes.func,
  searchBundles: PropTypes.func,
  searchPackage: PropTypes.func,
};

const withConnect = connect(
  null,
  {
    getSelfCareOffers,
    getBundleByPackageId,
    createSelfCare,
    searchBundles,
    searchPackage,
  },
);

const withSaga = injectSaga({ key: 'registerSaga', saga });

export default withTranslation('common')(
  compose(
    withRouter,
    withConnect,
    withSaga,
  )(Register),
);
