import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { isEmpty, cloneDeep } from 'lodash';
import { MainHeader } from '../../components/Header';
import RawSmsDataSearchForm from './RawSmsDataSearchForm';
import {
  TablePagination,
  DataTable,
  SwitchExport,
  ButtonExport,
  ExcelExport,
  MultiInputGroup,
  AccountHeaderForm,
} from '../../components/common';
import { checkPermissionViewTransactions } from '../../utils/CheckPermissions';
import { makeGetListRawSmsData, makeGetListAllRawSmsData } from '../App/selectors';
import {
  uploadMultiPartFiles,
  getRawSmsData,
  getAllRawSmsData,
  onResetDefaultData,
  getOutputTemplateByType,
} from '../App/actions';
import './styles.scss';
import {
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  getPageTotalCount,
  configOutputTemplateByType,
} from '../../utils/utils';
import convertJson2Sheet from '../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../utils/PdfHelper/exportPdfFile';

const sortRawSmsData = {
  submitDate: {
    asc: 'submitDate_ASC',
    desc: 'submitDate_DESC',
  },
  scheduleDeliveryTime: {
    asc: 'scheduleDeliveryTime_ASC',
    desc: 'scheduleDeliveryTime_DESC',
  },
  durationMinutes: {
    asc: 'durationMinutes_ASC',
    desc: 'durationMinutes_DESC',
  },
  messageDeliveryStatus: {
    asc: 'messageDeliveryStatus_ASC',
    desc: 'messageDeliveryStatus_DESC',
  },
};

const tableColumns = [
  {
    name: 'startTime',
    label: 'label.startDate',
  },
  {
    name: 'endTime',
    label: 'label.endDate',
  },
  {
    name: 'serviceType',
    label: 'label.serviceType',
  },
  {
    name: 'submitDate',
    label: 'label.submitDate',
    sortable: true,
  },
  {
    name: 'addressSrcDigits',
    label: 'label.addressSrcDigits',
  },
  {
    name: 'srcTon',
    label: 'label.srcTon',
  },
  {
    name: 'srcApi',
    label: 'label.srcApi',
  },
  {
    name: 'addressDestDigits',
    label: 'label.addressDestDigits',
  },
  {
    name: 'destTon',
    label: 'label.destTon',
  },
  {
    name: 'destApi',
    label: 'label.destApi',
  },
  {
    name: 'scheduleDeliveryTime',
    label: 'label.scheduleDeliveryTime',
    sortable: true,
  },
  {
    name: 'validityPeriod',
    label: 'label.validityPeriod',
  },
  {
    name: 'protocolId',
    label: 'label.protocolId',
  },
  {
    name: 'priority',
    label: 'label.priority',
  },
  {
    name: 'registerDelivery',
    label: 'label.registerDelivery',
  },
  {
    name: 'replaceIfPresent',
    label: 'label.replacePresent',
  },
  {
    name: 'dataCoding',
    label: 'label.dataCoding',
  },
  {
    name: 'messageLength',
    label: 'label.messageLength',
  },
  {
    name: 'firstTwentyCharactersOfSMS',
    label: 'label.first20Chars',
  },
  {
    name: 'optTag',
    label: 'label.optTag',
  },
  {
    name: 'optLength',
    label: 'label.optLength',
  },
  {
    name: 'optVal',
    label: 'label.optVal',
  },
  {
    name: 'origNetworkId',
    label: 'label.origNetworkId',
  },
  {
    name: 'networkId',
    label: 'label.networkId',
  },
  {
    name: 'host',
    label: 'label.host',
  },
  {
    name: 'esmClass',
    label: 'label.esmClass',
  },
  {
    name: 'gsmsiei',
    label: 'label.gsmsiei',
  },
  {
    name: 'gsmudh',
    label: 'label.gsmudh',
  },
  {
    name: 'messageDeliveryStatus',
    label: 'label.messageDeliveryStatus',
    sortable: true,
  },
  {
    name: 'extIdCinta',
    label: 'label.extIdCinta',
  },
  {
    name: 'messageId',
    label: 'label.messageId',
  },
  {
    name: 'newSequenceNumber',
    label: 'label.sequenceNumber',
  },
  {
    name: 'commandId',
    label: 'label.commandId',
  },
  {
    name: 'commandLength',
    label: 'label.commandLength',
  },
  {
    name: 'pduType',
    label: 'label.pduType',
  },
  {
    name: 'aspName',
    label: 'label.aspName',
  },
  {
    name: 'messageStatus',
    label: 'label.messageStatus',
  },
  {
    name: 'messageType',
    label: 'label.messageType',
  },
];

let tableColumnsOutput = null;

export class RawSmsData extends React.PureComponent {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActivePdf: false,
      page: 0,
      size: 20,
      filter: {},
      sort: '',
      sorted: {},
      totalCount: null,
      isSearching: false,
      listRawSmsData: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      listRawSmsData: props.listRawSmsData,
      totalCount: getPageTotalCount({ ...state, items: props.listRawSmsData }),
    };
  }

  componentDidMount() {
    const { permissions } = this.props;
    const { modeGetRawSmsReport } = permissions || {};
    if (modeGetRawSmsReport) {
      this.doGetOutputTemplateByType();
      onResetDefaultData('listRawSmsData');
    }
  }

  onHandleSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListRawSmsData();
    });
  };

  doGetListRawSmsData = () => {
    const { filter, page, size, sorted } = this.state;
    const { getRawSmsData } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.startDate) {
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.endDate) {
      newFilter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.switchName) {
      newFilter.switchName = 'SMS';
    }
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort: !isEmpty(sorted) && sortRawSmsData[sorted.sortCol] ? sortRawSmsData[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    getRawSmsData(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistRawSmsData = cb => {
    const { filter, sorted } = this.state;
    const { getAllRawSmsData } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.startDate) {
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.endDate) {
      newFilter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.switchName) {
      newFilter.switchName = 'SMS';
    }
    const payload = {
      page: 1,
      size: 10000000,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortRawSmsData[sorted.sortCol][sorted.sortDir] : null,
    };
    getAllRawSmsData(payload, () => {
      if (cb) cb();
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListRawSmsData();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListRawSmsData());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListRawSmsData());
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistRawSmsData(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllRawSmsData, t } = this.props;
        convertJson2Pdf({
          data: listAllRawSmsData,
          t,
          title: t('label.rawSMSFiles').toLocaleUpperCase(),
          fileName: `${t('label.rawSMSFiles')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = () => {
    this.doGetAlllistRawSmsData(() => {
      const { listAllRawSmsData, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_RAW_SMS_REPORT',
          subject: `Embrix Report ${t('label.rawSMSFiles')}`,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('label.rawSMSFiles')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllRawSmsData,
            t,
            columns: tableColumnsOutput,
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllRawSmsData,
          t,
          title: t('label.rawSMSFiles').toLocaleUpperCase(),
          fileName: `${t('label.rawSMSFiles')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('label.rawSMSFiles')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('RAW_SMS_FILES_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumns,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  renderContent() {
    const { t, listAllRawSmsData, permissions } = this.props;
    const {
      listRawSmsData,
      page,
      size,
      isActivePdf,
      emails,
      totalCount,
      sorted,
      isSearching,
      exportColumnsSave,
    } = this.state;
    const { modeGetRawSmsReport, modeDownloadReport, modeGetMailReport } = permissions || {};

    if (!modeGetRawSmsReport) return '';
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    return (
      <div className="transactions">
        <div className="col-md-12 p-0">
          <br />
          <Row>
            <Col md={12}>
              <AccountHeaderForm title={t('label.rawSMSFiles')} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12} className="p-0">
              <RawSmsDataSearchForm onSubmit={this.onHandleSearch} isSearching={this.state.isSearching} />
            </Col>
            <Col md={12} className="p-0">
              <div className="mt-3 d-flex float-right mb-4 pt-3 pb-3">
                {!!modeDownloadReport && (
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                    />
                  </div>
                )}
                {!!modeDownloadReport && (
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet={t('label.rawSMSFiles').toLocaleUpperCase()}
                      multiDataSet={convertJson2Sheet({
                        data: listAllRawSmsData,
                        t,
                        title: t('label.rawSMSFiles').toLocaleUpperCase(),
                        columnsTable: tableColumnsOutput,
                      })}
                      fileName={`${t('label.rawSMSFiles')
                        .toLocaleLowerCase()
                        .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                    />
                  </>
                )}
                {!!modeGetMailReport && (
                  <div className="email-group">
                    <MultiInputGroup
                      label={t('label.emails')}
                      wrapperClass="email-form"
                      value={emails || []}
                      inputProps={{
                        placeholder: t('label.addAEmail'),
                      }}
                      onChange={this.onChangeEmail}
                    />
                    <button
                      type="submit"
                      onClick={() => this.sendEmail({ columns: tableColumnsOutput })}
                      disabled={!emails || !emails.length}
                      className="ladda-button btn btn-submit x-small mr-3 mt-0 float-right btn-default-height"
                    >
                      {t('label.email')}
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <br />
          <DataTable
            isFixedHeaderTable
            tableClass="table table-hover"
            columns={tableColumnsOutput}
            data={listRawSmsData && listRawSmsData.length ? listRawSmsData : []}
            onSort={this.onSortColumn}
            sorted={sorted}
            isLoading={isSearching}
          />
          <br />
          <br />
          <div className="mb-30">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }

  render() {
    const { permissionsSelfCare } = this.props;
    let modeViewTransactions = 2;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewTransactions = checkPermissionViewTransactions({
        listPermission,
      });
    }
    return (
      <div className="view-transactions-page">
        <MainHeader activeTab="Reports" />
        {modeViewTransactions !== 0 && (
          <div className="view-transactions-page__form">
            <div className="form-wrapper table-content">{this.renderContent()}</div>
          </div>
        )}
      </div>
    );
  }
}

RawSmsData.propTypes = {
  history: PropTypes.object,
  arOpsUnit: PropTypes.array,
  params: PropTypes.object,
  getAccountStatement: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  // permissionsSelfCare: makeGetPermissionSelfCare() || {},
  listRawSmsData: makeGetListRawSmsData() || [],
  listAllRawSmsData: makeGetListAllRawSmsData() || [],
});

const withConnect = connect(mapStateToProps, {
  getRawSmsData,
  getAllRawSmsData,
  onResetDefaultData,
  uploadMultiPartFiles,
  getOutputTemplateByType,
});

export default withTranslation('common')(compose(withConnect)(RawSmsData));
