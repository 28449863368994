import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { SLIDE_UP } from 'react-ladda';
import { MainHeader } from '../../components/Header';
import { GenericInput, ButtonCustom, AccountHeaderForm } from '../../components/common';
import { getAccountId } from '../../localStorage';
import { checkPermissionViewSubscription } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare } from '../App/selectors';
// import {
//   makeGetServiceUnitsAc,
//   makeGetSubscription,
//   makeErrorMessageSU,
//   makeErrorMessageSubscription,
// } from './selectors';
import { getAccountById, modifyAccountById } from '../App/actions';
import './styles.scss';

const listFiledsBillingProfile = [
  {
    label: 'label.billingDom',
    name: 'billingDom',
    type: 'number',
  },
  {
    label: 'label.billingSegment',
    name: 'billingSegment',
  },
  {
    label: 'label.billingFrequency',
    name: 'billingFrequency',
    type: 'select',
    tOptions: 'selections:billingFrequency',
  },
  {
    label: 'label.invoiceType',
    name: 'invoiceType',
    type: 'select',
    tOptions: 'selections:invoiceType',
  },
  {
    label: 'label.invoiceDelivery',
    name: 'invoiceDelivery',
    type: 'select',
    tOptions: 'selections:invoiceDelivery',
  },
];

export class BillingProfile extends React.Component {
  state = {
    billingData: {},
  };

  componentDidMount = () => {
    const { getAccountById } = this.props;
    getAccountById({ id: getAccountId() }, ({ success, data }) => {
      if (success) {
        this.setState({
          billingData: data && data.billingProfiles && data.billingProfiles[0] ? data.billingProfiles[0] : {},
        });
      }
    });
  };

  onChangeBillingData = ({ name, value }) => {
    const { billingData } = this.state;
    billingData[name] = value;
    this.setState({ ...billingData });
  };

  onSubmit = evt => {
    evt.preventDefault();
    const { billingData } = this.state;
    const { modifyAccountById } = this.props;
    modifyAccountById({ id: getAccountId(), billingProfiles: [billingData] });
  };

  render() {
    const { permissionsSelfCare, t } = this.props;
    // let modeViewSubscription = 0;
    // if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
    //   const listPermission = permissionsSelfCare.selfCareModulePermissions;
    //   modeViewSubscription = checkPermissionViewSubscription({
    //     listPermission,
    //   });
    // }
    const { billingData } = this.state;
    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="AccountData" />
        <div className="manage-page__form p-3">
          <div className="form-billing">
            <form className="col-md-12 row pt-4 group-input-billing" onSubmit={this.onSubmit}>
              <div className="col-md-12 p-0">
                <Row>
                  {/* <div className="col-md-12 pl-2 pr-0 pt-0 pb-3">
                    <h4 className="card-title-mb">
                      {t('label.billingProfile')}
                      <span className="account-number">
                        <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                        &nbsp;&nbsp;
                        <span className="account-detail">{getAccountId()}</span>
                      </span>
                    </h4>
                  </div> */}
                  <AccountHeaderForm title={t('label.billingProfile')} />
                </Row>
              </div>
              {listFiledsBillingProfile.map(val => (
                <GenericInput
                  key={val.name}
                  {...val}
                  wrapperClass="col-md-4"
                  value={billingData[val.name]}
                  onChange={this.onChangeBillingData}
                  readOnly
                  disabled
                />
              ))}
              <div className="col-md-12 p-0">
                <Row className="mt-sm-0 mx-0 row">
                  <Col sm={{ size: 12, offset: 8 }} className="row float-right">
                    <ButtonCustom
                      // loading={isSubmitting}
                      className="ml-auto mt-auto mb-auto mr-2 btn-cancel"
                      type="button"
                      title={t('label.cancel')}
                      titleloading="Modifying"
                      datastyle={SLIDE_UP}
                      onClick={() => {
                        this.props.history.push('/');
                      }}
                    />
                    {/* <ButtonCustom
                      className="btn-submit"
                      type="submit"
                      title={t('label.submit')}
                      // disabled={modeModifyUser === 1}
                      datastyle={SLIDE_UP}
                    /> */}
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

BillingProfile.propTypes = {
  getAccountById: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    getAccountById: (data, cb) => dispatch(getAccountById(data, cb)),
    modifyAccountById: (data, cb) => dispatch(modifyAccountById(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common'])(compose(withConnect)(BillingProfile));
