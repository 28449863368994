import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputGroup from '../InputGroup';
import DateGroup from '../DateGroup';
import SelectGroup from '../SelectGroup';
import DateTimeGroup from '../DateTimeGroup';
import MultiSelectGroup from '../MultiSelectGroup';
// import MonthYearGroup from '../MonthYearGroup';
// import CreatableSelectGroup from '../CreatableSelectGroup';

const GenericInput = ({ type, id, name, onChange, isCreateOption, isPlaceholder, label, isMulti, ...props }) => {
  const { t } = useTranslation('common');
  const inputId =
    id ||
    `${type}-${Math.random()
      .toString()
      .replace('.', '')}`;
  // const onPaste = e => {
  //   e.persist();
  //   let avalue = e.clipboardData.getData('Text');
  //   avalue = avalue ? avalue.trim() : null;
  //   if (onChange) {
  //     setTimeout(() => {
  //       onChange({ name, value: avalue, event: e });
  //     }, 4);
  //   }
  // };

  if (type === 'select') {
    if (isMulti) {
      return (
        <MultiSelectGroup
          {...props}
          name={name}
          id={inputId}
          label={t(label)}
          placeholder={null}
          // value={
          //   !isManualValue
          //     ? props && props.tOptions && t
          //       ? [...t(props.tOptions)()].find(e => e.value === props.value)
          //       : props.options && props.options.find(e => e.value === props.value)
          //     : {
          //         label: props.value,
          //         value: props.value,
          //       }
          // }
          onChange={e => onChange({ name, value: e ? e.value : '' })}
        />
      );
    }

    return (
      <SelectGroup
        name={name}
        id={inputId}
        placeholder={null}
        options={props && props.tOptions && t ? [...t(props.tOptions)()] : props.options}
        label={t(label)}
        {...props}
        value={
          props && props.tOptions && t
            ? [...t(props.tOptions)()].find(e => e.value === props.value)
            : props.options && props.options.find(e => e.value === props.value)
        }
        isDisabled={props.disabled}
        onChange={e => onChange({ name, value: e ? e.value : '' })}
      />
    );
  }
  if (type === 'date') {
    return (
      <DateGroup
        {...props}
        name={name}
        id={inputId}
        value={props.value}
        label={t(label)}
        onChange={e => onChange({ name, value: e })}
      />
    );
  }

  if (type === 'datetime') {
    return (
      <DateTimeGroup
        {...props}
        name={name}
        id={inputId}
        value={props.value}
        label={t(label)}
        onChange={e => onChange({ name, value: e })}
      />
    );
  }

  // if (type === 'monthYear') {
  //   return (
  //     <MonthYearGroup
  //       {...props}
  //       name={name}
  //       id={inputId}
  //       value={props.value}
  //       onChange={e => onChange({ name, value: e })}
  //     />
  //   );
  // }
  // if (type === 'checkbox') {
  //   return (
  //     <CheckGroup
  //       {...props}
  //       name={name}
  //       id={inputId}
  //       checked={props.value}
  //       onClick={e => onChange({ name, value: !props.value, event: e })}
  //     />
  //   );
  // }
  return (
    <InputGroup
      type={type}
      {...props}
      name={name}
      id={inputId}
      label={t(label)}
      value={props.value || props.value === 0 || props.value === '0' ? props.value : ''}
      onChange={e => onChange({ name, value: e.target.value, event: e })}
    />
  );
};

GenericInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

GenericInput.defaultProps = {
  type: 'text',
  value: '',
};
export default GenericInput;
