import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { isEmpty, cloneDeep, sortBy } from 'lodash';
import { MainHeader } from '../../components/Header';
import NonBroadsoftZeroDurationCDRSearchForm from './NonBroadsoftZeroDurationCDRSearchForm';
import {
  TablePagination,
  DataTable,
  SwitchExport,
  ButtonExport,
  ExcelExport,
  MultiInputGroup,
  AccountHeaderForm,
} from '../../components/common';
import { checkPermissionViewTransactions } from '../../utils/CheckPermissions';
import {
  makeGetListNonBroadsoftZeroDurationCdrs,
  makeGetListAllNonBroadsoftZeroDurationCdrs,
  makeGetAccountInfo,
} from '../App/selectors';
import {
  uploadMultiPartFiles,
  getNonBroadsoftZeroDurationCdrsReport,
  getAllNonBroadsoftZeroDurationCdrsReport,
  onResetDefaultData,
  getConfigIdd,
  getConfigIdo,
  getConfigIncomingOutgoingRoute,
  getConfigSreserved,
  getConfigOrganization,
  getConfigFailureCodesList,
  getOutputTemplateByType,
} from '../App/actions';
import './styles.scss';
import {
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  getPageTotalCount,
  configOutputTemplateByType,
} from '../../utils/utils';
import convertJson2Sheet from '../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../utils/PdfHelper/exportPdfFile';

const sortNonBroadsoftZeroDurationCDR = {
  answerTime: {
    asc: 'answerTime_ASC',
    desc: 'answerTime_DESC',
  },
  releaseTime: {
    asc: 'releaseTime_ASC',
    desc: 'releaseTime_DESC',
  },
  extDurationMinutes: {
    asc: 'durationMinutes_ASC',
    desc: 'durationMinutes_DESC',
  },
  extDurationSeconds: {
    asc: 'durationSeconds_ASC',
    desc: 'durationSeconds_DESC',
  },
};

const tableColumns = [
  {
    name: 'answerTime',
    label: 'label.answerTime',
    sortable: true,
  },
  {
    name: 'extIddAbc',
    label: 'label.extIddAbc',
  },
  {
    name: 'extIdoBcd',
    label: 'label.extIdoBcd',
  },
  {
    name: 'callingNumber',
    label: 'label.callingNumber',
  },
  {
    name: 'calledNumber',
    label: 'label.calledNumber',
  },
  {
    name: 'incomingRoute',
    label: 'label.incomingRoute',
  },
  {
    name: 'outgoingRoute',
    label: 'label.outgoingRoute',
  },
  {
    name: 'sreserved',
    label: 'label.sreserved',
  },
  {
    name: 'callStatus',
    label: 'label.callStatus',
  },
  {
    name: 'terminatingAccess',
    label: 'label.terminatingAccess',
  },
  {
    name: 'failureCode',
    label: 'label.failureCode',
  },
  {
    name: 'disconnectReason',
    label: 'label.disconnectReason',
  },
  {
    name: 'commercial',
    label: 'label.commercial',
  },
  {
    name: 'startTime',
    label: 'label.startTime',
  },
  {
    name: 'endTime',
    label: 'label.endTime',
  },
  {
    name: 'direction',
    label: 'label.direction',
  },
  {
    name: 'callDuration',
    label: 'label.callDuration',
  },
  {
    name: 'extDurationMinutes',
    label: 'label.extDurationMinutes',
    sortable: true,
  },
  {
    name: 'extDurationSeconds',
    label: 'label.extDurationSeconds',
    sortable: true,
  },
];

let tableColumnsOutput = null;

export class NonBroadsoftZeroDurationCDR extends React.PureComponent {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActivePdf: false,
      page: 0,
      size: 20,
      filter: {},
      sort: '',
      sorted: {},
      totalCount: null,
      isSearching: false,
      listNonBroadsoftZeroDurationCDR: [],
      failureCodesOption: [],
      iddOption: [],
      idoOption: [],
      incomingOutgoingRouteOption: [],
      sreservedOption: [],
      configOrganizationOption: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      listNonBroadsoftZeroDurationCDR: props.listNonBroadsoftZeroDurationCDR,
      totalCount: getPageTotalCount({ ...state, items: props.listNonBroadsoftZeroDurationCDR }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetNonBroadsoftZeroDurationCdrs },
      onResetDefaultData,
      getConfigIdd,
      getConfigIdo,
      getConfigIncomingOutgoingRoute,
      getConfigSreserved,
      getConfigOrganization,
    } = this.props;
    if (modeGetNonBroadsoftZeroDurationCdrs) {
      onResetDefaultData('listNonBroadsoftZeroDurationCDR');
      this.doGetConfigFailureCodesList();
      this.doGetOutputTemplateByType();
      // getConfigOrganization('', ({ success, data }) => {
      //   if (success) {
      //     let configOrganizationOption = [];
      //     if (data && data.configOrganizationList && data.configOrganizationList.length) {
      //       configOrganizationOption = data.configOrganizationList.map(val => {
      //         return { label: val.value, value: val.value };
      //       });
      //     }
      //     this.setState({
      //       configOrganizationOption:
      //         configOrganizationOption && configOrganizationOption.length
      //           ? sortBy(configOrganizationOption, ['label'])
      //           : [],
      //     });
      //   }
      // });
      // getConfigIdd('', ({ success, data }) => {
      //   if (success) {
      //     let iddOption = [];
      //     if (data && data.configIddList && data.configIddList.length) {
      //       iddOption = data.configIddList.map(val => {
      //         return { label: val.value, value: val.value };
      //       });
      //     }
      //     this.setState({
      //       iddOption: iddOption && iddOption.length ? sortBy(iddOption, ['label']) : [],
      //     });
      //   }
      // });
      // getConfigIdo('', ({ success, data }) => {
      //   if (success) {
      //     let idoOption = [];
      //     if (data && data.configIdoList && data.configIdoList.length) {
      //       idoOption = data.configIdoList.map(val => {
      //         return { label: val.value, value: val.value };
      //       });
      //     }
      //     this.setState({ idoOption: idoOption && idoOption.length ? sortBy(idoOption, ['label']) : [] });
      //   }
      // });
      getConfigIncomingOutgoingRoute('', ({ success, data }) => {
        if (success) {
          let incomingOutgoingRouteOption = [];
          if (data && data.configIncomingOutgoingRouteList && data.configIncomingOutgoingRouteList.length) {
            incomingOutgoingRouteOption = data.configIncomingOutgoingRouteList.map(val => {
              return { label: val.value, value: val.value };
            });
          }
          this.setState({
            incomingOutgoingRouteOption:
              incomingOutgoingRouteOption && incomingOutgoingRouteOption.length
                ? sortBy(incomingOutgoingRouteOption, ['label'])
                : [],
          });
        }
      });
      // getConfigSreserved('', ({ success, data }) => {
      //   if (success) {
      //     let sreservedOption = [];
      //     if (data && data.configSreservedList && data.configSreservedList.length) {
      //       sreservedOption = data.configSreservedList.map(val => {
      //         return { label: `${val.value}${val.description ? `: ${val.description}` : ''}`, value: val.value };
      //       });
      //     }
      //     this.setState({
      //       sreservedOption: sreservedOption && sreservedOption.length ? sortBy(sreservedOption, ['label']) : [],
      //     });
      //   }
      // });
    }
  }

  doGetConfigFailureCodesList = value => {
    const { getConfigFailureCodesList } = this.props;
    const { filter } = this.state;
    getConfigFailureCodesList({ platformName: value || filter?.switchName || 'BROADSOFT' }, ({ success, data }) => {
      if (success) {
        let failureCodesOption = [];
        if (data && data.length) {
          failureCodesOption = data.map(val => {
            return { label: `${val.value}${val.description ? `: ${val.description}` : ''}`, value: val.value };
          });
        }
        this.setState({
          failureCodesOption:
            failureCodesOption && failureCodesOption.length ? sortBy(failureCodesOption, ['label']) : [],
        });
      }
    });
  };

  onHandleSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetListNonBroadsoftZeroDurationCDR();
    });
  };

  doGetListNonBroadsoftZeroDurationCDR = () => {
    const { filter, page, size, sorted } = this.state;
    const { getNonBroadsoftZeroDurationCdrsReport, accountInfo } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.startDate) {
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.endDate) {
      newFilter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.switchName) {
      newFilter.switchName = 'BROADSOFT';
    }
    if (accountInfo?.organization) {
      newFilter.clientName = accountInfo?.organization;
    }
    const payload = {
      page: page + 1,
      size,
      filter: newFilter,
      sort:
        !isEmpty(sorted) && sortNonBroadsoftZeroDurationCDR[sorted.sortCol]
          ? sortNonBroadsoftZeroDurationCDR[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getNonBroadsoftZeroDurationCdrsReport(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistNonBroadsoftZeroDurationCDR = cb => {
    const { filter, sorted } = this.state;
    const { getAllNonBroadsoftZeroDurationCdrsReport, accountInfo } = this.props;
    const newFilter = cloneDeep(filter);
    if (!newFilter.startDate) {
      newFilter.startDate = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.endDate) {
      newFilter.endDate = moment()
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (!newFilter.switchName) {
      newFilter.switchName = 'BROADSOFT';
    }
    if (accountInfo?.organization) {
      newFilter.clientName = accountInfo?.organization;
    }
    const payload = {
      page: 1,
      size: 10000000,
      filter: newFilter,
      sort: !isEmpty(sorted) ? sortNonBroadsoftZeroDurationCDR[sorted.sortCol][sorted.sortDir] : null,
    };
    getAllNonBroadsoftZeroDurationCdrsReport(payload, () => {
      if (cb) cb();
    });
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetListNonBroadsoftZeroDurationCDR();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetListNonBroadsoftZeroDurationCDR());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetListNonBroadsoftZeroDurationCDR());
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistNonBroadsoftZeroDurationCDR(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllNonBroadsoftZeroDurationCDR, t } = this.props;
        convertJson2Pdf({
          data: listAllNonBroadsoftZeroDurationCDR,
          t,
          title: t('label.metaswitchZero').toLocaleUpperCase(),
          fileName: `${t('label.metaswitchZero')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = () => {
    this.doGetAlllistNonBroadsoftZeroDurationCDR(() => {
      const { listAllNonBroadsoftZeroDurationCDR, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_RAW_CDR_REPORT',
          subject: `Embrix Report ${t('label.metaswitchZero')}`,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('label.metaswitchZero')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllNonBroadsoftZeroDurationCDR,
            t,
            columns: tableColumnsOutput,
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllNonBroadsoftZeroDurationCDR,
          t,
          title: t('label.metaswitchZero').toLocaleUpperCase(),
          fileName: `${t('label.metaswitchZero')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('label.metaswitchZero')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetOutputTemplateByType = () => {
    const { getOutputTemplateByType, t } = this.props;
    getOutputTemplateByType('RAW_NON_BROADSOFT_ZERO_DURATION_CDR_REPORT', ({ success, data }) => {
      if (success && data) {
        this.setState({
          exportColumnsSave: configOutputTemplateByType({
            columns: tableColumns,
            data,
            t,
          }),
          idOutputTemplate: data?.id,
          typeOutputTemplate: data?.type,
        });
      }
    });
  };

  renderContent() {
    const { t, listAllNonBroadsoftZeroDurationCDR, permissions } = this.props;
    const {
      listNonBroadsoftZeroDurationCDR,
      page,
      size,
      isActivePdf,
      emails,
      totalCount,
      sorted,
      isSearching,
      failureCodesOption,
      iddOption,
      idoOption,
      incomingOutgoingRouteOption,
      sreservedOption,
      configOrganizationOption,
      exportColumnsSave,
    } = this.state;
    const { modeGetNonBroadsoftZeroDurationCdrs, modeDownloadReport, modeGetMailReport } = permissions || {};
    if (!modeGetNonBroadsoftZeroDurationCdrs) return '';
    const exportColumns = exportColumnsSave || {};
    tableColumnsOutput = exportColumns?.targetFields?.items || [];
    return (
      <div className="transactions">
        <div className="col-md-12 p-0">
          <br />
          <Row>
            <Col md={12}>
              <AccountHeaderForm title={t('label.metaswitchZero')} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12} className="p-0">
              <NonBroadsoftZeroDurationCDRSearchForm
                onSubmit={this.onHandleSearch}
                isSearching={this.state.isSearching}
                isMultipleOptionInject
                optionsInject={{
                  incomingRoute: incomingOutgoingRouteOption,
                  outgoingRoute: incomingOutgoingRouteOption,
                  failureCode: failureCodesOption,
                }}
                handleInputChange={({ name, value }) => {
                  if (name === 'switchName') {
                    this.doGetConfigFailureCodesList(value);
                  }
                }}
                onHandleClearInput={() => this.doGetConfigFailureCodesList('DIDWW')}
              />
            </Col>
            <Col md={12} className="p-0">
              <div className="mt-3 d-flex float-right mb-4 pt-3 pb-3">
                {!!modeDownloadReport && (
                  <div className="ml-auto mt-2 mb-auto mr-3">
                    <SwitchExport
                      onChange={this.onChangeSwitch}
                      wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                      title={t('label.excel')}
                      checked={isActivePdf}
                      rightTitle={t('label.pdf')}
                    />
                  </div>
                )}
                {!!modeDownloadReport && (
                  <>
                    <ButtonExport onExport={this.onExport} />
                    <ExcelExport
                      element={<button type="button" className="display-none" ref={this.buttonRef} />}
                      nameSheet={t('label.metaswitchZero').toLocaleUpperCase()}
                      multiDataSet={convertJson2Sheet({
                        data: listAllNonBroadsoftZeroDurationCDR,
                        t,
                        title: t('label.metaswitchZero').toLocaleUpperCase(),
                        columnsTable: tableColumnsOutput,
                      })}
                      fileName={`${t('label.metaswitchZero')
                        .toLocaleLowerCase()
                        .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                    />
                  </>
                )}
                {!!modeGetMailReport && (
                  <div className="email-group">
                    <MultiInputGroup
                      label={t('label.emails')}
                      wrapperClass="email-form"
                      value={emails || []}
                      inputProps={{
                        placeholder: t('label.addAEmail'),
                      }}
                      onChange={this.onChangeEmail}
                    />
                    <button
                      type="submit"
                      onClick={() => this.sendEmail({ columns: tableColumnsOutput })}
                      disabled={!emails || !emails.length}
                      className="ladda-button btn btn-submit x-small mr-3 mt-0 float-right btn-default-height"
                    >
                      {t('label.email')}
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <br />
          <DataTable
            isFixedHeaderTable
            tableClass="table table-hover"
            columns={tableColumnsOutput}
            data={
              listNonBroadsoftZeroDurationCDR && listNonBroadsoftZeroDurationCDR.length
                ? listNonBroadsoftZeroDurationCDR
                : []
            }
            onSort={this.onSortColumn}
            sorted={sorted}
            isLoading={isSearching}
          />
          <br />
          <br />
          <div className="mb-30">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }

  render() {
    const { permissionsSelfCare } = this.props;
    let modeViewTransactions = 2;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewTransactions = checkPermissionViewTransactions({
        listPermission,
      });
    }
    return (
      <div className="view-transactions-page">
        <MainHeader activeTab="Reports" />
        {modeViewTransactions !== 0 && (
          <div className="view-transactions-page__form">
            <div className="form-wrapper table-content">{this.renderContent()}</div>
          </div>
        )}
      </div>
    );
  }
}

NonBroadsoftZeroDurationCDR.propTypes = {
  history: PropTypes.object,
  arOpsUnit: PropTypes.array,
  params: PropTypes.object,
  getAccountStatement: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  // permissionsSelfCare: makeGetPermissionSelfCare() || {},
  listNonBroadsoftZeroDurationCDR: makeGetListNonBroadsoftZeroDurationCdrs() || [],
  listAllNonBroadsoftZeroDurationCDR: makeGetListAllNonBroadsoftZeroDurationCdrs() || [],
  accountInfo: makeGetAccountInfo() || {},
});

const withConnect = connect(mapStateToProps, {
  getNonBroadsoftZeroDurationCdrsReport,
  getAllNonBroadsoftZeroDurationCdrsReport,
  onResetDefaultData,
  uploadMultiPartFiles,
  getConfigIdd,
  getConfigIdo,
  getConfigIncomingOutgoingRoute,
  getConfigSreserved,
  getConfigOrganization,
  getConfigFailureCodesList,
  getOutputTemplateByType,
});

export default withTranslation('common')(compose(withConnect)(NonBroadsoftZeroDurationCDR));
