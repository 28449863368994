/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_REPOS = 'boilerplate/App/LOAD_REPOS';
export const LOAD_REPOS_SUCCESS = 'boilerplate/App/LOAD_REPOS_SUCCESS';
export const LOAD_REPOS_ERROR = 'boilerplate/App/LOAD_REPOS_ERROR';

export const GET_SELF_CARE_OFFERS = 'self-care/getSelfCareOffers';
export const GET_BUNDLE_ID_BY_PACKAGE_ID = 'GET_BUNDLE_ID_BY_PACKAGE_ID';
export const GET_ACCOUNT_BY_ID = 'GET_ACCOUNT_BY_ID';
export const MANAGE_ACCOUNT = 'MANAGE_ACCOUNT';

export const SEARCH_PACKAGE = 'SEARCH_PACKAGE';
// permission
export const GET_ROLE_AND_ROLE_GROUP_BY_ID = 'app/GET_ROLE_AND_ROLE_GROUP_BY_ID';
export const GET_ROLE_BY_ID = 'app/GET_ROLE_BY_ID';
export const GET_ROLE_ID_BY_ROLEGROUP = 'app/GET_ROLE_ID_BY_ROLEGROUP';
export const GET_PERMISSIONS_BY_ID_SUCCESS = 'app/GET_PERMISSIONS_BY_ID_SUCCESS';
export const REMOVE_PERMISSIONS = 'app/REMOVE_PERMISSIONS';
export const SELECT_INVOICE_UNITS_ID = 'app/SELECT_INVOICE_UNITS_ID';

export const MODIFY_ACCOUNT_BY_ID = 'MODIFY_ACCOUNT_BY_ID';
export const SEARCH_ORDERS = 'app/orders/search_orders';
export const GET_CUSTOMER_ACTIVITY = 'app/customers/get_customer_activity';
export const GET_ACTIVITY_BY_ID = 'app/customers/get_activity_by_id';
export const SEARCH_NOTE_UNITS = 'app/customers/SEARCH_NOTE_UNITS';
export const GET_EXTERNAL_BY_ACCOUNT_ID = 'app/customers/getExternalPOByAccountId';
export const GET_CUSTOM_ATTRIBUTES = 'app/customers/GET_CUSTOM_ATTRIBUTES';

export const GET_S3_BUCKET = 'app/select/GET_S3_BUCKET';
export const GET_S3_BUCKET_SUCESS = 'app/select/GET_S3_BUCKET_SUCESS';
export const READ_FILE_FROM_S3 = 'app/select/READ_FILE_FROM_S3';
export const GET_TENANT_SELF_CARE_CONFIG = 'app/select/GET_TENANT_SELF_CARE_CONFIG';
export const GET_TENANT_DEFAULT_CONFIG = 'congero/app/GET_TENANT_DEFAULT_CONFIG';
export const GET_AWS_S3_CREDENTIAL = 'app/select/GET_AWS_S3_CREDENTIAL';
export const GET_LOGO_SUCCESS = 'app/select/GET_LOGO_SUCCESS';
export const GET_OBJECT_FILE_BY_ID = 'app/select/GET_OBJECT_FILE_BY_ID';

export const SEARCH_PAYMENT_BY_ACCOUNT = 'app/select/SEARCH_PAYMENT_BY_ACCOUNT';
export const REDIRECT_MCM_SELF_CARE = 'app/select/REDIRECT_MCM_SELF_CARE';

export const UPLOAD_MULTI_PART_FILE = 'app/select/UPLOAD_MULTI_PART_FILE';
export const GET_CURRENCY_CONFIG = 'congero/app/GET_CURRENCY_CONFIG';
export const GET_CURRENCY_CONFIG_SUCCESS = 'congero/app/GET_CURRENCY_CONFIG_SUCCESS';
export const GET_ACCOUNT_BY_ID_SUCCESS = 'GET_ACCOUNT_BY_ID_SUCCESS';
export const GET_CCP_PROPERTIES_CONFIG = 'app/select/GET_CCP_PROPERTIES_CONFIG';
export const GET_CCP_PROPERTIES_CONFIG_SUCCESS = 'app/select/GET_CCP_PROPERTIES_CONFIG_SUCCESS';

// Get Raw File
export const ON_RESET_DEFAUL_DATA = 'ON_RESET_DEFAUL_DATA';
export const GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT = 'GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT';
export const GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS = 'GET_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS';
export const GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT = 'GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT';
export const GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS =
  'GET_ALL_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS';

export const GET_RAW_CDR_DATA_REPORT = 'GET_RAW_CDR_DATA_REPORT';
export const GET_RAW_CDR_DATA_REPORT_SUCCESS = 'GET_RAW_CDR_DATA_REPORT_SUCCESS';
export const GET_ALL_RAW_CDR_DATA_REPORT_DETAILS = 'GET_ALL_RAW_CDR_DATA_REPORT_DETAILS';
export const GET_ALL_RAW_CDR_DATA_REPORT_DETAILS_SUCCESS = 'GET_ALL_RAW_CDR_DATA_REPORT_DETAILS_SUCCESS';

export const GET_CONFIG_FAILURE_CODES = 'app/payment/GET_CONFIG_FAILURE_CODES';
export const GET_CONFIG_IDD = 'app/payment/GET_CONFIG_IDD';
export const GET_CONFIG_IDO = 'app/payment/GET_CONFIG_IDO';
export const GET_CONFIG_INCOMING_OUTGOING_ROUTE = 'app/payment/GET_CONFIG_INCOMING_OUTGOING_ROUTE';
export const GET_SPLIT_TAX_PER_LINE_CONFIG = 'app/payment/GET_SPLIT_TAX_PER_LINE_CONFIG';
export const GET_CONFIG_FAILURE_CODES_LIST = 'app/payment/GET_CONFIG_FAILURE_CODES_LIST';
export const GET_CONFIG_SRESERVED = 'app/payment/GET_CONFIG_SRESERVED';
export const GET_CONFIG_ORGANIZATION = 'app/payment/GET_CONFIG_ORGANIZATION';

export const GET_NON_BROAD_SOFT_RAW_CDR_DATA = 'GET_NON_BROAD_SOFT_RAW_CDR_DATA';
export const GET_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS = 'GET_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS';
export const GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA = 'GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA';
export const GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS = 'GET_ALL_NON_BROAD_SOFT_RAW_CDR_DATA_SUCCESS';

export const GET_OUTPUT_TEMPLATE_BY_TYPE = 'app/payment/GET_OUTPUT_TEMPLATE_BY_TYPE';

export const GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT = 'GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT';
export const GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS =
  'GET_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS';
export const GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT = 'GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT';
export const GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS =
  'GET_ALL_NONE_BROADSOFT_ZERO_DURATION_CDRS_REPORT_SUCCESS';

export const GET_RAW_SMS_DATA_REPORT = 'GET_RAW_SMS_DATA_REPORT';
export const GET_RAW_SMS_DATA_REPORT_SUCCESS = 'GET_RAW_SMS_DATA_REPORT_SUCCESS';
export const GET_ALL_RAW_SMS_DATA_REPORT_DETAILS = 'GET_ALL_RAW_SMS_DATA_REPORT_DETAILS';
export const GET_ALL_RAW_SMS_DATA_REPORT_DETAILS_SUCCESS = 'GET_ALL_RAW_SMS_DATA_REPORT_DETAILS_SUCCESS';

export const GET_DISTINCT_USAGE_TYPES = 'app/select/GET_DISTINCT_USAGE_TYPES';
export const GET_DISTINCT_ORGANIZATION = 'app/select/GET_DISTINCT_ORGANIZATION';

// On Demand
export const GET_LIST_ON_DEMAND = 'GET_LIST_ON_DEMAND';
export const GET_LIST_ON_DEMAND_SUCCESS = 'GET_LIST_ON_DEMAND_SUCCESS';
export const GET_ALL_LIST_ON_DEMAND = 'GET_ALL_LIST_ON_DEMAND';
export const GET_ALL_LIST_ON_DEMAND_SUCCESS = 'GET_ALL_ON_DEMAND_SUCCESS';

export const GET_DATA_SERVICE_REPORT_DETAILS = 'GET_DATA_SERVICE_REPORT_DETAILS';
export const GET_ALL_DATA_SERVICE_REPORT_DETAILS = 'GET_ALL_DATA_SERVICE_REPORT_DETAILS';