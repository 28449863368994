import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import { MainHeader } from '../../components/Header';
import { DataTable, TablePagination, AccountHeaderForm } from '../../components/common';
import PaymentsSearchForm from '../../components/Payment/PaymentsSearchForm';
import { getAccountId } from '../../localStorage';
import { makeGetPermissionSelfCare, makeGetCcpProperty } from '../App/selectors';
import { searchPaymentByAccount, getObjectFileById } from '../App/actions';
import RouteNames from '../App/RouteNames';
import { getPageTotalCount } from '../../utils/utils';

import './styles.scss';

const paymentsSort = {
  accountId: {
    asc: 'accountId_ASC',
    desc: 'accountId_DESC',
  },
  invoiceUnitId: {
    asc: 'invoiceUnitId_ASC',
    desc: 'invoiceUnitId_DESC',
  },
  paymentDate: {
    asc: 'paymentDate_ASC',
    desc: 'paymentDate_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  transactionId: {
    asc: 'transactionId_ASC',
    desc: 'transactionId_ASC',
  },
};

export class ViewCreditNotes extends React.Component {
  state = {
    page: 0,
    size: 20,
    filter: {},
    sorted: {},
    totalCount: null,
    isSearching: false,
    isActiveNext: false,
  };

  componentDidMount = () => {
    this.doSearchPayments();
  };

  doSearchPayments = () => {
    const { page, size, sorted, filter, totalCount } = this.state;
    const { searchPaymentByAccount } = this.props;
    const sort =
      sorted && sorted.sortCol && sorted.sortDir && paymentsSort[sorted.sortCol]
        ? paymentsSort[sorted.sortCol][sorted.sortDir]
        : null;
    this.setState({ isSearching: true });
    const params = {
      page: page + 1,
      size,
      sorted,
      filter: { ...filter, accountId: getAccountId(), fetchFinalizedPayment: 'true' },
    };
    if (sort) {
      params.sort = sort;
    }
    searchPaymentByAccount(params, ({ success, data }) => {
      this.setState({
        isSearching: false,
        page,
        size,
        filter,
        sort,
        data: success ? data : [],
        isActiveNext: !!(data && data.length === size),
        totalCount: getPageTotalCount({ totalCount: totalCount || data?.length, size, items: data || [], page }),
      });
    });
  };

  onSubmitSearch = filteredData => {
    this.setState({ filter: { ...filteredData }, page: 0 }, () => this.doSearchPayments());
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doSearchPayments());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchPayments());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchPayments());
  };

  doGetObjectFileById = itemId => {
    const { getObjectFileById, history } = this.props;
    getObjectFileById(itemId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath('/payment-details/:id', itemId),
          state: {
            fromPath: RouteNames.prettifyPath('/view-payment-details'),
            fileData: data,
          },
        });
      }
    });
  };

  render() {
    const { t, ccpProperty } = this.props;
    const { data, page, totalCount, size, sorted, isSearching } = this.state;
    const tableColumns = [
      {
        name: 'accountId',
        label: 'label.accountId',
        render: () => {
          return <span>{getAccountId()}</span>;
        },
      },
      {
        name: 'paymentDate',
        label: 'common:label.paymentDate',
        sortable: true,
      },
      {
        name: 'id',
        label: 'label.id',
        sortable: true,
        render: (colName, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm btn-view-file"
            onClick={() => this.doGetObjectFileById(item.id)}
          >
            {item.id}
          </button>
        ),
      },
      {
        name: 'transactionId',
        label: 'label.transactionId',
        sortable: true,
      },
      {
        name: 'externalTransId',
        label: 'common:label.externalId',
      },
      {
        name: 'status',
        label: 'common:label.status',
        sortable: true,
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:statusPayment')().find(val => val.value === item.status) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'amount',
        label: 'common:label.amount',
        render: (colName, item) => {
          return <span>{item.amount ? item.amount.toFixed(2) : ''}</span>;
        },
      },
      {
        name: 'currency',
        label: 'label.currency',
      },
      {
        name: 'method',
        label: 'common:label.paymentMethod',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.method) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'remainingAmount',
        label: 'common:label.remainingAmount',
      },
    ];

    let historicalFolioLink = null;
    if (ccpProperty && ccpProperty.ccpPropertyList && ccpProperty.ccpPropertyList.length) {
      const historicalFolioLinkProperty = ccpProperty.ccpPropertyList.find(
        val => val.property === 'historicalFolioLink'
      );
      if (historicalFolioLinkProperty) historicalFolioLink = historicalFolioLinkProperty.value;
    }

    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="Billing Data" />
        <div className="manage-page__form p-3">
          <div className="form-billing">
            <div className="col-md-12 row pt-4 group-input-billing" onSubmit={this.onSubmit}>
              <div className="col-md-12 p-0">
                <Row>
                  {/* <div className="col-md-12 pl-2 pr-0 pt-0 pb-3">
                    <h4 className="card-title-mb">
                      {t('label.payments')}
                      <span className="account-number">
                        <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                        &nbsp;&nbsp;
                        <span className="account-detail">{getAccountId()}</span>
                      </span>
                    </h4>
                  </div> */}
                  <AccountHeaderForm title={t('label.payments')} historicalFolioLink={historicalFolioLink} />
                </Row>
                <br />
              </div>
              <PaymentsSearchForm onSubmit={this.onSubmitSearch} />
            </div>
            <div className="group-input-billing">
              <DataTable
                columns={tableColumns}
                data={data || []}
                isLoading={isSearching}
                onSort={this.onSortColumn}
                sorted={sorted}
                isFixedHeaderTable
              />
              <div className="col-md-12 pl-0 pr-0 pt-3">
                <div className="mb-30">
                  <TablePagination
                    pageNumber={page}
                    pageSize={size}
                    totalCount={totalCount}
                    onPageChange={this.onPageChange}
                    onSizeChange={this.onSizeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ViewCreditNotes.propTypes = {
  searchPaymentByAccount: PropTypes.func.isRequired,
  getObjectFileById: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
  ccpProperty: makeGetCcpProperty() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    searchPaymentByAccount: (data, cb) => dispatch(searchPaymentByAccount(data, cb)),
    getObjectFileById: (data, cb) => dispatch(getObjectFileById(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common'])(compose(withConnect)(ViewCreditNotes));
