import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
const FormHeader = ({ title = '', detail = {}, className }) => (
 
  <div className="form-header">
   
     One Off Payment I
      {!isEmpty(detail) && (
        <span>
          <span className="detail-name">{detail.name}</span>
          <span className="detail-value">{detail.value}</span>
        </span>
      )}
    
  </div>
);

FormHeader.propTypes = {
  detail: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default FormHeader;
