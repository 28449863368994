import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Button } from 'reactstrap';
import Select from 'react-select';
import { MainHeader } from '../../components/Header';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { MakePayment } from '../../components/MakePayment';
import { getAccountId } from '../../localStorage';
import { dataSelect } from '../../constantsApp';
import { checkPermissionApplyPayment } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare } from '../App/selectors';
import { FormHeader } from '../../components/form';
import makeSelectMakePaymentPage from './selectors';
import reducer from './reducer';
import saga from './saga';
import { getAccountById, manageAccount } from '../App/actions';
import './styles.scss';

export class MakePaymentPage extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      makePayment: {},
      creditCards: [{}],
    };
  }

  componentDidMount() {
    this.doGetAccountDetails();
  }

  doGetAccountDetails = () => {
    this.props.getAccountById(
      {
        id: getAccountId(),
      },
      ({ success, data }) => {
        if (success && data && data.paymentProfiles && data.paymentProfiles[0] && data.paymentProfiles[0].creditCards) {
          this.setState({ creditCards: data.paymentProfiles[0].creditCards });
        }
      }
    );
  };

  handleAddCreditCardFromURL = payload => {
    this.props.manageAccount(payload, success => {
      if (success) {
        this.props.history.push('/make-payment');
        this.doGetAccountDetails();
      }
    });
  };

  onChangeValueForm = (key, value) => {
    const { makePayment } = this.state;
    makePayment[key] = value;
    this.setState({
      ...makePayment,
    });
  };

  renderContent() {
    const { makePayment, creditCards } = this.state;
    const { permissionsSelfCare } = this.props;
    let modeApplyPayment = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeApplyPayment = checkPermissionApplyPayment({ listPermission });
    }
    return (
      <div className="payment">
        <FormHeader
          title="One-Off payment"
          detail={{ name: 'Account Number:', value: getAccountId() }}
          className="large-font-size"
        />
        {/* <div className="header-sort">
          <Select
            value={dataSelect.transactionType.find(
              el => el.value === makePayment.type,
            )}
            options={dataSelect.makePayment}
            placeholder="Payment Type"
            className="form__form-group-select"
            onChange={val =>
              this.onChangeValueForm('type', val ? val.value : '')
            }
            isClearable
          />
          <Button disabled color="success">
            Account Id
          </Button>
        </div> */}
        <FormHeader title="Credit Card Payment" className="large-font-size" />
        <MakePayment
          history={this.props.history}
          data={creditCards}
          modeApplyPayment={modeApplyPayment}
          handleAddCreditCardFromURL={this.handleAddCreditCardFromURL}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="make-payment-page">
        <MainHeader activeTab="MakePayment" />
        <div className="make-payment-page__form">
          <div className="form-payment">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

MakePaymentPage.propTypes = {
  history: PropTypes.object,
  manageAccount: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  makePayment: makeSelectMakePaymentPage(),
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

const withConnect = connect(mapStateToProps, { getAccountById, manageAccount });

const withReducer = injectReducer({ key: 'makePaymentReducer', reducer });
const withSaga = injectSaga({ key: 'makePaymentSaga', saga });

export default compose(withReducer, withSaga, withConnect)(MakePaymentPage);
