import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Col, Input, Row, FormFeedback, Tooltip } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Cards from 'react-credit-cards';
import { SLIDE_UP } from 'react-ladda';
import { FaRegTrashAlt } from 'react-icons/fa';
import { getAccountId, getUserName } from '../../localStorage';
import { cardTokenFields, urlGetCreditCard } from '../../constantsApp';
import { getDataCardFromURL } from '../../utils/utils';
import { ButtonCustom, ModalAccept } from '../common';

class ManageCreditCard extends Component {
  constructor() {
    super();
    this.state = {
      isSubmitting: false,
      hadPressSubmit: false,
      account: {},
      cardToken: '',
      cardExpiry: '',
      last4CC: '',
      isTokenFromURL: false,
      tooltipOpen: false,
      focusCard: '',
      isOpenModalDelete: false,
      cardTokenFromAPI: '',
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    let token = '';
    let expiryDate = '';
    let last4 = '';
    if (search) {
      token = getDataCardFromURL(search, cardTokenFields.token);
      expiryDate = decodeURIComponent(getDataCardFromURL(search, cardTokenFields.expiryDate));
      last4 = getDataCardFromURL(search, cardTokenFields.last4cc);
    }
    if (token && expiryDate && last4)
      this.setState({
        cardToken: token,
        cardExpiry: expiryDate,
        last4CC: last4,
        isTokenFromURL: true,
      });
  }

  toggle = () => {
    const { tooltipOpen } = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  };

  componentWillReceiveProps(nextProps) {
    const { isTokenFromURL, cardTokenFromAPI } = this.state;
    const { account } = nextProps;
    if (JSON.stringify(this.state.account) !== JSON.stringify(nextProps.account)) {
      const paymentProfile =
        account && account.paymentProfiles && account.paymentProfiles.length > 0 && account.paymentProfiles[0]
          ? account.paymentProfiles[0]
          : {};
      const creditCard =
        paymentProfile &&
        paymentProfile.creditCards &&
        paymentProfile.creditCards.length > 0 &&
        paymentProfile.creditCards[0]
          ? paymentProfile.creditCards[0]
          : {};

      if (!isTokenFromURL) {
        this.setState({
          account,
          cardToken: creditCard.cardToken,
          cardExpiry: creditCard.cardExpiry,
          last4CC: creditCard.last4CC,
          cardTokenFromAPI: creditCard.cardToken,
        });
        return;
      }
      this.setState(
        {
          account,
        },
        () => {
          this.handleSubmit();
          this.setState({ isTokenFromURL: false });
        }
      );
    }
  }

  onDeleteCardToken = () => {
    const { cardToken } = this.state;
    const { manageAccount } = this.props;
    const payloadDeleteToken = {
      accountId: '',
      creditCard: { cardToken: '' },
    };
    try {
      payloadDeleteToken.accountId = getAccountId();
      payloadDeleteToken.creditCard.cardToken = cardToken;
      manageAccount(payloadDeleteToken, success => {
        if (success) {
          window.open(`${urlGetCreditCard.URL}${window.location}`, '_self');
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  onChangeText = evt => {
    let nameCardFocus = '';
    if (evt.target.name === 'last4CC') {
      nameCardFocus = 'cvc';
      this.setState({ focusCard: nameCardFocus });
      if (evt.target.value.length > 4) return;
    } else {
      this.setState({ focusCard: '' });
    }
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  renderCreditCard() {
    const { cardToken, cardExpiry, last4CC, hadPressSubmit, cardTokenFromAPI } = this.state;
    const { modeModifyCreditCard, modeAddCreditCard, t } = this.props;

    const domCardExpiry = document.querySelector('.rccs__expiry__valid');
    if (domCardExpiry) document.querySelector('.rccs__expiry__valid').textContent = 'Expiry';

    return (
      <div className="col-md-8 d-flex flex-wrap no-padding">
        <div className="row col-md-12 no-padding">
          <Col md={7}>
            <FormGroup>
              <Label for="cardToken">{`${t('label.creditCardToken')}:`}</Label>
              <div className="group-card-token">
                <Input
                  value={cardToken || ''}
                  type="text"
                  className="col-md-7"
                  name="cardToken"
                  id="cardToken"
                  disabled={modeModifyCreditCard === 1 || modeAddCreditCard === 1}
                  placeholder="Card Number"
                  onChange={this.onChangeText}
                />
                <button
                  className="button-delete-card"
                  type="button"
                  id="remove-card-token"
                  disabled={!cardTokenFromAPI || modeModifyCreditCard === 1 || modeAddCreditCard === 1}
                  onClick={() => {
                    this.setState({ isOpenModalDelete: true });
                  }}
                >
                  <FaRegTrashAlt />
                  <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpen}
                    autohide={false}
                    target="remove-card-token"
                    toggle={this.toggle}
                  >
                    {t('label.removeCreditCard')}
                  </Tooltip>
                </button>
                <div className="col-md-5">
                  <ButtonCustom
                    className="btn-submit pl-2"
                    type="button"
                    disabled={modeModifyCreditCard === 1 || modeAddCreditCard === 1}
                    style={{ paddingLeft: 3 }}
                    title={t('label.addCreditCard')}
                    titleloading="Modifying"
                    datastyle={SLIDE_UP}
                    onClick={() => {
                      window.open(`${urlGetCreditCard.URL}${window.location}`, '_self');
                    }}
                  />
                </div>
              </div>

              <FormFeedback valid={!hadPressSubmit}>
                <b>{t('label.creditCardNumber')}</b> 
                {' '}
                {t('message.canNotBeBlank')}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={5}>
            <FormGroup>
              <Label for="last4CC">{`${t('label.creditCardCVV')}:`}</Label>
              <Input
                type="text"
                disabled={modeModifyCreditCard === 1}
                name="last4CC"
                id="last4CC"
                placeholder="Last 4CC"
                value={last4CC || ''}
                onChange={this.onChangeText}
              />
              <FormFeedback valid={!hadPressSubmit}>{t('message.cvvMsg')}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="cardExpiry">{`${t('label.creditCardExpiry')}:`}</Label>
              <Input
                type="text"
                name="cardExpiry"
                id="cardExpiry"
                placeholder="MM/YYYY"
                value={cardExpiry || ''}
                disabled={modeModifyCreditCard === 1}
                onChange={this.onChangeText}
              />
              <FormFeedback valid={!hadPressSubmit}>
                <b>{t('label.creditCardExpiry')}</b> 
                {' '}
                {t('message.canNotBeBlank')}
              </FormFeedback>
            </FormGroup>
          </Col>
        </div>
      </div>
    );
  }

  handleSubmit = evt => {
    if (evt) evt.preventDefault();

    const { cardToken, cardExpiry, last4CC } = this.state;
    const { manageAccount } = this.props;
    const { account } = this.state;
    const contacts = account.contacts || [];
    const contact = contacts && contacts.length > 0 ? contacts[0] : {};
    const addresses = account.addresses || [];
    const address = addresses && addresses.length > 0 ? addresses[0] : {};
    manageAccount(
      {
        creditCard: {
          cardToken,
          cardExpiry,
          last4CC,
          merchant: 'qabrain3', // hard code
        },
        accountId: getAccountId(),
        userId: getUserName(),
        selfCareAddress: {
          street: address.street,
          city: address.city,
          state: address.state,
          country: address.country,
          postalCode: address.postalCode,
        },
        selfCareContact: {
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          number: contact.number,
        },
      },
      () => {
        this.setState({ isSubmitting: false });
        this.props.history.push('/manage-credit-card');
      }
    );
  };

  render() {
    const { isSubmitting, cardToken, cardExpiry, focusCard, last4CC, isOpenModalDelete } = this.state;
    const { modeModifyCreditCard, t } = this.props;
    return (
      <Form>
        <div className="col-md-12 d-flex no-padding">
          {this.renderCreditCard()}
          <div className="col-md-4">
            <Cards
              cvc={last4CC || ''}
              expiry={cardExpiry || ''}
              focused={focusCard}
              name={`Token: ${cardToken || ''}`}
              number=""
            />
          </div>
        </div>

        <Row className="mt-sm-3 mx-0">
          <Col sm={{ size: 12, offset: 8 }} className="row float-right mt-5">
            <ButtonCustom
              className="btn-cancel ml-auto mt-auto mb-auto mr-2"
              type="button"
              title={t('label.cancel')}
              titleloading="Modifying"
              datastyle={SLIDE_UP}
              onClick={() => {
                this.props.history.push('/');
              }}
            />
            {modeModifyCreditCard !== 0 && (
              <ButtonCustom
                loading={isSubmitting}
                className="btn-submit"
                type="submit"
                title={t('label.submit')}
                titleloading="Modifying"
                disabled={modeModifyCreditCard === 1}
                datastyle={SLIDE_UP}
                onClick={this.handleSubmit}
              />
            )}
          </Col>
        </Row>
        <ModalAccept
          onAcceptDelete={this.onDeleteCardToken}
          isOpen={isOpenModalDelete}
          title={t('message.msgDeleteToken')}
          onToggle={() => {
            this.setState({
              isOpenModalDelete: !isOpenModalDelete,
            });
          }}
        />
      </Form>
    );
  }
}

ManageCreditCard.propTypes = {
  manageAccount: PropTypes.func,
  account: PropTypes.object,
  history: PropTypes.object,
  modeModifyCreditCard: PropTypes.number,
  modeAddCreditCard: PropTypes.number,
};

export default withTranslation('common')(ManageCreditCard);
