import { call, takeLatest, put } from 'redux-saga/effects';
import { queryRequest } from '../../utils/request';
import { push } from 'react-router-redux';
import { userLogin } from '../../api';
import {
  resetNotification,
  setNotification,
} from '../Notification/actions';
import { isConnecting, isEndConnected } from '../Loader/actions';
import { NotificationTypes } from '../../constantsApp';
import {
  setAccountId,
  setUserName,
  setPassword,
  setFirstTimeLogin,
  setId,
} from '../../localStorage';
import * as types from './types';

// callback when create failed
export function* userLoginSage({ payload, cb }) {
  yield put(isConnecting());
  yield put(resetNotification());
  try {
    const response = yield call(queryRequest, userLogin(payload));
    if (response.userLogin) {
      setAccountId(response.userLogin.accountId);
      setUserName(response.userLogin.userId);
      setPassword(payload.password);
      setFirstTimeLogin(response.userLogin.firstTimeLogin);
      setId(response.userLogin.id);
      yield put(isEndConnected());
      yield cb({ success: true });
      yield put(push('/'));
    } else {
      yield put(isEndConnected());
      yield put(
        setNotification({
          type: NotificationTypes.error,
          message: `Login failure!`,
        }),
      );
      yield cb({ success: false });
    }
  } catch (err) {
    yield put(
      setNotification({
        type: NotificationTypes.error,
        message: `Login failure! ${err}`,
      }),
    );
    yield put(isEndConnected());
    yield cb({ success: false });
  }
}

export default function* loginPageSaga() {
  yield takeLatest(types.LOGIN, userLoginSage);
}
