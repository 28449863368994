import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { getAccountId } from '../../localStorage';

const PrivateRoute = ({ component: Component, token, ghost_user_id, dispatch, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        getAccountId() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                isNeedLogin: true,
                currentUrl: rest.location.pathname,
              }
            }}
          />
        )
      }
    />
  );
};

function mapStateToProps(store) {
  const { token, ghost_user_id } = store.get('auth').toJS();
  return {
    token,
    ghost_user_id,
  };
}

export default connect(
  mapStateToProps,
  null
)(PrivateRoute);
