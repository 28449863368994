import { countryList, stateUSData } from '../../constantsApp/countryList';

export default {
  filedCreateWithExistAccount: [
    {
      label: 'label.accountId',
      name: 'accountId',
    },
    {
      label: 'label.userId',
      name: 'userId',
    },
    {
      label: 'label.password',
      name: 'password',
      isPassword: true,
    },
    {
      label: 'Retype Password',
      name: 'retypePassword',
      isPassword: true,
    },
  ],
  accountDetailForm: {
    addressesUSForm: [
      {
        label: 'label.firstName',
        name: 'firstName',
        subField: 'selfCareContact',
        required: true,
      },
      {
        label: 'label.lastName',
        name: 'lastName',
        subField: 'selfCareContact',
        required: true,
      },
      {
        label: 'label.street',
        name: 'street',
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.country',
        name: 'country',
        options: countryList,
        isSelect: true,
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.state',
        name: 'state',
        options: stateUSData,
        subField: 'selfCareAddress',
        isSelect: true,
        required: true,
      },
      {
        label: 'label.city',
        name: 'city',
        options: [],
        isSelect: true,
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.postalCode',
        name: 'postalCode',
        options: [
          {
            label: 'message.msgSelectCity',
            value: 'Please select City before',
            isDisabled: true,
            isMultiLang: true,
          },
        ],
        isSelect: true,
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.email',
        name: 'email',
        subField: 'selfCareContact',
        required: true,
      },
      {
        label: 'label.phoneNumber',
        name: 'number',
        subField: 'selfCareContact',
        required: true,
      },
      {
        label: 'label.userId',
        name: 'userId',
        required: true,
      },
      {
        label: 'label.password',
        name: 'password',
        isPassword: true,
        required: true,
      },
      {
        label: 'label.retypePassword',
        name: 'retypePassword',
        isPassword: true,
        required: true,
      },
    ],
    addressesNormalForm: [
      {
        label: 'label.firstName',
        name: 'firstName',
        subField: 'selfCareContact',
        required: true,
      },
      {
        label: 'label.lastName',
        name: 'lastName',
        subField: 'selfCareContact',
        required: true,
      },
      {
        label: 'label.street',
        name: 'street',
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.country',
        name: 'country',
        options: countryList,
        isSelect: true,
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.state',
        name: 'state',
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.city',
        name: 'city',
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.postalCode',
        name: 'postalCode',
        subField: 'selfCareAddress',
        required: true,
      },
      {
        label: 'label.email',
        name: 'email',
        subField: 'selfCareContact',
        required: true,
      },
      {
        label: 'label.phoneNumber',
        name: 'number',
        subField: 'selfCareContact',
        required: true,
      },
      {
        label: 'label.userId',
        name: 'userId',
        required: true,
      },
      {
        label: 'label.password',
        name: 'password',
        isPassword: true,
        required: true,
      },
      {
        label: 'label.retypePassword',
        name: 'retypePassword',
        isPassword: true,
        required: true,
      },
    ],
  },
  creditCardDetailForm: [
    {
      name: 'cardToken',
      label: 'label.creditCardToken',
      required: true,
    },
    {
      name: 'cardExpiry',
      label: 'label.creditCardExpiry',
      required: true,
    },
    {
      name: 'last4CC',
      label: 'label.last4CC',
      required: true,
    },
  ],

  contentLeftViewAccountDetails: 'message.contentLeftViewAccountDetails',
  contentLeftViewCreateUser: 'message.contentLeftViewCreateUser',
  contentLeftViewCreditCardDetails: 'message.contentLeftViewCreditCardDetails',
  contentLeftViewSelectPackage: 'message.contentLeftViewSelectPackage',
  contentLeftViewCreatePackage: 'message.contentLeftViewCreatePackage',
};
