const formatStringUrl = (...args) => {
  let i = 1;
  const str = args[0];
  return str.replace(/\:[a-zA-Z0-9]*/g, () => args[i++]); // eslint-disable-line
};

const prettifyPath = (path, ...args) => formatStringUrl(path, ...args);

export default {
  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
  },

  accountInfo: {
    path: '/account-info',
    name: 'accountInfo',
    exact: true,
  },

  userPreferences: {
    path: '/dashboard/user-preferences',
    name: 'userPreferences',
    exact: true,
  },

  myDashboard: {
    path: '/dashboard/me',
    name: 'myDashboard',
    exact: true,
  },

  dashboardNewCustomer: {
    path: '/dashboard/customer-new',
    name: 'dashboardNewCustomer',
    exact: true,
  },

  dashboardCustomerChurn: {
    path: '/dashboard/customer-churn',
    name: 'dashboardCustomerChurn',
    exact: true,
  },

  dashboardAccountType: {
    path: '/dashboard/account-type',
    name: 'dashboardAccountType',
    exact: true,
  },

  dashboardSellingCompany: {
    path: '/dashboard/selling-company',
    name: 'dashboardSellingCompany',
    exact: true,
  },

  acitveSubscriptions: {
    path: '/dashboard/acitve-subscriptions',
    name: 'acitveSubscriptions',
    exact: true,
  },

  trialToPaidConversion: {
    path: '/dashboard/trial-to-paid-conversion',
    name: 'trialToPaidConversion',
    exact: true,
  },

  subscriptionCancellations: {
    path: '/dashboard/subscription-cancellations',
    name: 'subscriptionCancellations',
    exact: true,
  },

  billedRevenue: {
    path: '/dashboard/billed-revenue',
    name: 'billedRevenue',
    exact: true,
  },

  billedCustomer: {
    path: '/dashboard/billed-customer',
    name: 'billedCustomer',
    exact: true,
  },

  paymentSuccess: {
    path: '/dashboard/payment-success',
    name: 'paymentSuccess',
    exact: true,
  },

  paymentSuspenseDashboard: {
    path: '/dashboard/payment-suspense',
    name: 'paymentSuspenseDashboard',
    exact: true,
  },

  paymentFailed: {
    path: '/dashboard/payment-failed',
    name: 'paymentFailed',
    exact: true,
  },

  accountsWithFailedPayment: {
    path: '/dashboard/accounts-with-failed-payment',
    name: 'accountsWithFailedPayment',
    exact: true,
  },

  collection030Days: {
    path: '/dashboard/collection-0-30-days',
    name: 'collection030Days',
    exact: true,
  },

  collection3060Days: {
    path: '/dashboard/collection-30-60-days',
    name: 'collection030Days',
    exact: true,
  },

  collection6090Days: {
    path: '/dashboard/collection-60-90-days',
    name: 'collection6090Days',
    exact: true,
  },

  collectionOver90Days: {
    path: '/dashboard/collection-over-90-days',
    name: 'collectionOver90Days',
    exact: true,
  },

  totalRevenue: {
    path: '/dashboard/total-revenue',
    name: 'totalRevenue',
    exact: true,
  },

  recognizedRevenue: {
    path: '/dashboard/recongnized-revenue',
    name: 'recognizedRevenue',
    exact: true,
  },

  revenueType: {
    path: '/dashboard/revenue-type',
    name: 'revenueType',
    exact: true,
  },

  productCompany: {
    path: '/dashboard/product-company',
    name: 'productCompany',
    exact: true,
  },

  revenueForecastDashboard: {
    path: '/dashboard/revenue-forecast',
    name: 'revenueForecastDashboard',
    exact: true,
  },

  monthlyRecurringRevenue: {
    path: '/dashboard/monthly-recurring-revenue',
    name: 'monthlyRecurringRevenue',
    exact: true,
  },

  totalWriteOff: {
    path: '/dashboard/total-write-off',
    name: 'totalWriteOff',
    exact: true,
  },

  debitAdjustments: {
    path: '/dashboard/debit-adjustments',
    name: 'debitAdjustments',
    exact: true,
  },

  failedOrder: {
    path: '/dashboard/failed-order',
    name: 'failedOrder',
    exact: true,
  },

  submittedOrder: {
    path: '/dashboard/submitted-order',
    name: 'submittedOrder',
    exact: true,
  },

  jobsFailed: {
    path: '/dashboard/jobs-failed',
    name: 'jobsFailed',
    exact: true,
  },

  billsFailed: {
    path: '/dashboard/bills-failed',
    name: 'billsFailed',
    exact: true,
  },

  invoicesFailed: {
    path: '/dashboard/invoices-failed',
    name: 'invoicesFailed',
    exact: true,
  },

  totalJobs: {
    path: '/dashboard/total-jobs',
    name: 'totalJobs',
    exact: true,
  },

  totalCreditAdjustments: {
    path: '/dashboard/total-credit-adjustments',
    name: 'totalCreditAdjustments',
    exact: true,
  },

  totalDisputes: {
    path: '/dashboard/total-disputes',
    name: 'totalDisputes',
    exact: true,
  },

  totalSettlements: {
    path: '/dashboard/total-settlements',
    name: 'totalSettlements',
    exact: true,
  },

  usageTransaction: {
    path: '/dashboard/usage-transaction',
    name: 'usageTransaction',
    exact: true,
  },

  ratedUsageTransaction: {
    path: '/dashboard/reated-usage-transaction',
    name: 'ratedUsageTransaction',
    exact: true,
  },

  // handle Dashboard end

  customers: {
    path: '/customers',
    name: 'CustomersPage',
    exact: true,
  },

  customersNew: {
    path: '/customers/create/',
    name: 'CustomerNew',
    exact: true,
  },

  customersCreate: {
    path: '/customers/create/:childRoute',
    name: 'CustomerNew',
    exact: true,
  },

  customerInfo: {
    path: '/customers/:id/info',
    name: 'customerInfo',
    exact: true,
  },

  customerDetails: {
    path: '/customers/:id/:childRoute',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsInfo: {
    path: '/customers/:id/info',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsContacts: {
    path: '/customers/:id/contacts',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsAddresses: {
    path: '/customers/:id/addresses',
    name: 'CustomerDetails',
    exact: true,
  },

  customerDetailsPaymentProfiles: {
    path: '/customers/:id/payment-profile',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsBillingProfile: {
    path: '/customers/:id/billing-profile',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsServices: {
    path: '/customers/:id/services',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsAssets: {
    path: '/customers/:id/assets',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsBalances: {
    path: '/customers/:id/balances',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsBills: {
    path: '/customers/:id/bills',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsManualBills: {
    path: '/customers/:id/manual-billing',
    name: 'customerDetailsManualBills',
    exact: true,
  },
  customerDetailsTransactions: {
    path: '/customers/:id/transactions',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsArActivity: {
    path: '/customers/:id/ar-activity',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsActivities: {
    path: '/customers/:id/activities',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsHierarchy: {
    path: '/customers/:id/hierarchy',
    name: 'CustomerDetails',
    exact: true,
  },
  customerDetailsCustomAttributes: {
    path: '/custom-attributes',
    name: 'CustomerDetails',
    exact: true,
  },

  accountStatement: {
    path: '/customers/:id/account-statement',
    name: 'accountStatement',
    exact: true,
  },

  customerExternalPO: {
    path: '/customers/:id/external-po',
    name: 'customerExternalPO',
    exact: true,
  },

  customerAttribute: {
    path: '/customers/custom-attributes-config',
    name: 'CustomerAttribute',
    exact: true,
  },

  customerAttributeDetails: {
    path: '/customers/:id/custom-attributes-config',
    name: 'CustomerAttributeDetails',
    exact: true,
  },

  customerAttributeCreate: {
    path: '/customers/create/custom-attributes-config',
    name: 'CustomerAttributeDetails',
    exact: true,
  },

  creditProfile: {
    path: '/customers/credit-profile',
    name: 'CreditProfile',
    exact: true,
  },

  delayedBilling: {
    path: '/customers/delayed-billing',
    name: 'delayedBilling',
    exact: true,
  },

  creditProfileDetails: {
    path: '/customers/:id/credit-profile',
    name: 'CreditProfileDetails',
    exact: true,
  },

  creditProfileCreate: {
    path: '/customers/create/credit-profile',
    name: 'CreditProfileDetails',
    exact: true,
  },

  invoices: {
    path: '/invoices',
    name: 'Invoices',
    exact: true,
  },
  invoicesInfo: {
    path: '/invoices/:id',
    name: 'Invoices',
    exact: true,
  },
  pricingHub: {
    path: '/pricing-management',
    name: 'PricingManagement',
    exact: false,
  },
  pricingManagement: {
    path: '/pricing-management',
    name: 'PricingItem',
    exact: true,
  },
  pricingItem: {
    path: '/pricing-management/items',
    name: 'PricingItem',
    exact: true,
  },
  pricingItemEdit: {
    path: '/pricing-management/items/:id',
    name: 'PricingItemEdit',
    exact: true,
  },
  pricingItemAdd: {
    path: '/pricing-management/items/add',
    name: 'PricingItemAdd',
    exact: true,
  },
  pricingPriceOffer: {
    path: '/pricing-management/offers',
    name: 'PricingPriceOffer',
    exact: true,
  },
  pricingPriceOfferEdit: {
    path: '/pricing-management/offers/:id',
    name: 'PricingPriceOfferEdit',
    exact: true,
  },
  pricingPriceOfferAdd: {
    path: '/pricing-management/offers/add',
    name: 'PricingPriceOfferAdd',
    exact: true,
  },
  pricingDiscountOffer: {
    path: '/pricing-management/discount',
    name: 'PricingDiscountOffer',
    exact: true,
  },
  pricingDiscountAdd: {
    path: '/pricing-management/discount/add',
    name: 'pricingDiscountAdd',
    exact: true,
  },
  pricingDiscountEdit: {
    path: '/pricing-management/discount/:id',
    name: 'pricingDiscountEdit',
    exact: true,
  },
  bundleManagement: {
    path: '/bundle-management',
    name: 'BundleItem',
    exact: false,
  },
  bundleItem: {
    path: '/bundle-management/bundles',
    name: 'BundleItem',
    exact: true,
  },
  bundleItemAdd: {
    path: '/bundle-management/bundles/add',
    name: 'BundleItemAdd',
    exact: true,
  },
  bundleItemEdit: {
    path: '/bundle-management/bundles/:id',
    name: 'BundleItemEdit',
    exact: true,
  },
  packageItem: {
    path: '/bundle-management/packages',
    name: 'PackageItem',
    exact: true,
  },
  packageItemAdd: {
    path: '/bundle-management/packages/add',
    name: 'PackageItemAdd',
    exact: true,
  },
  packageItemEdit: {
    path: '/bundle-management/packages/:id',
    name: 'PackageItemEdit',
    exact: true,
  },
  dependencyItem: {
    path: '/bundle-management/dependencies',
    name: 'DependencyItem',
    exact: true,
  },
  dependencyItemAdd: {
    path: '/bundle-management/dependencies/add',
    name: 'DependencyItemAdd',
    exact: true,
  },
  dependencyItemEdit: {
    path: '/bundle-management/dependencies/:id',
    name: 'DependencyItemEdit',
    exact: true,
  },
  paymentHub: {
    path: '/payment',
    name: 'paymentHub',
    exact: true,
  },
  paymentConfiguration: {
    path: '/payment/payment-admin/configuration',
    name: 'paymentConfiguration',
    exact: false,
  },
  paymentAgent: {
    path: '/payment/payment-agent/:childRoute',
    name: 'CollectionConfig',
    exact: true,
  },

  paymentSearchAccount: {
    path: '/payment/payment-agent/search-accounts',
    name: 'paymentSearchAccount',
    exact: false,
  },

  paymentOneOff: {
    path: '/payment/payment-agent/one-off-payment',
    name: 'oneOffPayment',
    exact: false,
  },

  paymentActivity: {
    path: '/payment/payment-agent/view-payment-activity',
    name: 'paymentActivity',
    exact: false,
  },
  paymentAllocation: {
    path: '/payment/payment-agent/payment-allocation',
    name: 'paymentAllocation',
    exact: false,
  },
  paymentSuspense: {
    path: '/payment/payment-agent/payment-suspense',
    name: 'paymentSuspense',
    exact: false,
  },
  paymentCustomerRefunds: {
    path: '/payment/customer-refunds',
    name: 'paymentCustomerRefunds',
    exact: false,
  },

  paymentManualChargebacks: {
    path: '/payment/manual-chargebacks',
    name: 'paymentManualChargebacks',
    exact: false,
  },
  batchPayment: {
    path: '/payment/batch-payments',
    name: 'batchPayment',
    exact: false,
  },
  batchRefunds: {
    path: '/payment/batch-refunds',
    name: 'batchRefunds',
    exact: false,
  },
  batchReversals: {
    path: '/payment/batch-reversals',
    name: 'batchReversals',
    exact: false,
  },

  arOperations: {
    path: '/ar-operations',
    name: 'ArOperations',
    exact: true,
  },

  arOperationsConfig: {
    path: '/ar-operations/ar-ops-config',
    name: 'AROpsConfig',
    exact: true,
  },

  arOperationsAdjustments: {
    path: '/ar-operations/adjustments',
    name: 'Adjustments',
    exact: true,
  },

  arOperationsBulkAdjustments: {
    path: '/ar-operations/bulk-adjustments',
    name: 'BulkAdjustments',
    exact: true,
  },

  arOperationsAdjustmentDetail: {
    path: '/ar-operations/adjustments/:id/detail',
    name: 'AdjustmentDetail',
    exact: true,
  },

  arOperationsAdjustmentApply: {
    path: '/ar-operations/adjustments/apply',
    name: 'AdjustmentApply',
    exact: true,
  },

  arOperationsDisputes: {
    path: '/ar-operations/disputes',
    name: 'Disputes',
    exact: true,
  },

  arOperationsDisputesDetail: {
    path: '/ar-operations/disputes/:id/detail',
    name: 'DisputesDetail',
    exact: true,
  },

  arOperationsDisputesApply: {
    path: '/ar-operations/disputes/apply',
    name: 'DisputesApply',
    exact: true,
  },

  arOperationsSettleDisputes: {
    path: '/ar-operations/settlements/apply',
    name: 'SettleDisputes',
    exact: true,
  },

  arOperationsWriteOffs: {
    path: '/ar-operations/write-offs',
    name: 'WriteOffs',
    exact: true,
  },

  arOperationsWriteOffsDetails: {
    path: '/ar-operations/write-offs/:id/detail',
    name: 'WriteOffs',
    exact: true,
  },

  arOperationsWriteOffsApply: {
    path: '/ar-operations/write-offs/apply',
    name: 'WriteOffs',
    exact: true,
  },

  arOperationsReverseWriteOffs: {
    path: '/ar-operations/reverse-write-offs/apply',
    name: 'ReverseWriteOffs',
    exact: true,
  },

  arOperationsConfigGL: {
    path: '/ar-operations/config-gl-setup',
    name: 'arOperationsConfigGL',
    exact: true,
  },

  arOperationsConfigItemMap: {
    path: '/ar-operations/config-item-map',
    name: 'arOperationsConfigItemMap',
    exact: true,
  },

  arOperationsConfigReasonCode: {
    path: '/ar-operations/config-reason-code',
    name: 'arOperationsConfigReasonCode',
    exact: true,
  },

  revenueConfig: {
    path: '/revenue',
    name: 'revenueConfig',
    exact: true,
  },

  revenueConfiguration: {
    path: '/revenue-configuration',
    name: 'revenueConfiguration',
    exact: true,
  },

  revenueConfigurationLocation: {
    path: '/revenue-configuration/location',
    name: 'revenueConfigurationLocation',
    exact: true,
  },

  revenueConfigurationEnterprise: {
    path: '/revenue-configuration/enterprise',
    name: 'revenueConfiguration',
    exact: true,
  },

  revenueConfigurationDivisions: {
    path: '/revenue-configuration/divisions',
    name: 'revenueConfigurationDivisions',
    exact: true,
  },

  revenueConfigurationLegalEntity: {
    path: '/revenue-configuration/legal-entity',
    name: 'revenueConfigurationLegalEntity',
    exact: true,
  },

  revenueConfigurationBusinessUnit: {
    path: '/revenue-configuration/business-unit',
    name: 'revenueConfigurationBusinessUnit',
    exact: true,
  },
  revenueConfigurationDepartment: {
    path: '/revenue-configuration/department',
    name: 'revenueConfigurationDepartment',
    exact: true,
  },

  revenueConfigurationCostCenter: {
    path: '/revenue-configuration/cost-center',
    name: 'revenueConfigurationCostCenter',
    exact: true,
  },

  revenueConfigurationCalendar: {
    path: '/revenue-configuration/calendar',
    name: 'revenueConfigurationCalendar',
    exact: true,
  },

  revenueConfigurationGLSetup: {
    path: '/revenue-configuration/gl-setup',
    name: 'revenueConfigurationGLSetup',
    exact: true,
  },

  revenueConfigurationChartOfAccount: {
    path: '/revenue-configuration/chart-of-account',
    name: 'revenueConfigurationChartOfAccount',
    exact: true,
  },

  revenueConfigurationPrimary: {
    path: '/revenue-configuration/primary',
    name: 'revenueConfigurationPrimary',
    exact: true,
  },

  revenueConfigurationSecondary: {
    path: '/revenue-configuration/secondary',
    name: 'revenueConfigurationSecondary',
    exact: true,
  },

  revenueConfigurationPeporting: {
    path: '/revenue-configuration/peporting',
    name: 'revenueConfigurationPeporting',
    exact: true,
  },

  revenueConfigurationMilestone: {
    path: '/revenue-configuration/milestone',
    name: 'revenueConfigurationMilestone',
    exact: true,
  },

  revenueConfigurationMilestoneCreate: {
    path: '/revenue-configuration/milestone/create-new',
    name: 'revenueConfigurationMilestoneCreate',
    exact: true,
  },

  revenueConfigurationMilestoneModify: {
    path: '/revenue-configuration/milestone/modify/:id',
    name: 'revenueConfigurationMilestoneModify',
    exact: true,
  },

  revenueConfigurationCurrencyExchange: {
    path: '/revenue-configuration/currency-exchange',
    name: 'revenueConfigurationCurrencyExchange',
    exact: true,
  },

  revenueConfigurationCurrencyExchangeCreate: {
    path: '/revenue-configuration/currency-exchange/create-new',
    name: 'revenueConfigurationCurrencyExchangeCreate',
    exact: true,
  },

  revenueConfigurationCurrencyExchangeModify: {
    path: '/revenue-configuration/currency-exchange/modify/:id',
    name: 'revenueConfigurationCurrencyExchangeModify',
    exact: true,
  },

  revenueConfigurationGLAccounts: {
    path: '/revenue-configuration/gl-accounts',
    name: 'revenueConfigurationGLAccounts',
    exact: true,
  },

  revenueCompanySetup: {
    path: '/revenue/config/company-setup',
    name: 'revenueCompanySetup',
    exact: true,
  },

  revenueCurrencyExchange: {
    path: '/revenue/config/currency-exchange',
    name: 'revenueCurrencyExchange',
    exact: true,
  },

  revenueCurrencyExchangeCreate: {
    path: '/revenue/config/currency-exchange/create-new',
    name: 'revenueCurrencyExchangeCreate',
    exact: true,
  },

  revenueCurrencyExchangeModify: {
    path: '/revenue/config/currency-exchange/modify/:id',
    name: 'revenueCurrencyExchangeModify',
    exact: true,
  },

  revenueMilestone: {
    path: '/revenue/config/milestone',
    name: 'revenueMilestone',
    exact: true,
  },

  revenueMilestoneCreate: {
    path: '/revenue/config/milestone/create-new',
    name: 'revenueMilestoneCreate',
    exact: true,
  },

  revenueMilestoneModify: {
    path: '/revenue/config/milestone/modify/:id',
    name: 'revenueMilestoneModify',
    exact: true,
  },

  revenueGlAccount: {
    path: '/revenue/config/gl-account',
    name: 'revenueGlAccount',
    exact: true,
  },

  collections: {
    path: '/collections',
    name: 'Collections',
    exact: true,
  },

  collectionsAdmin: {
    path: '/collections/collection-admin/:childRoute',
    name: 'CollectionConfig',
    exact: true,
  },

  collectionsConfig: {
    path: '/collections/collection-admin/collection-config',
    name: 'CollectionConfig',
    exact: true,
  },

  collectionsAccounts: {
    path: '/collections/collection-admin/accounts-in-collection',
    name: 'CollectionAccounts',
    exact: true,
  },

  collectionsActivitySummary: {
    path: '/collections/collection-admin/agent-activity-summary',
    name: 'ActivitySummary',
    exact: true,
  },

  collectionsAgent: {
    path: '/collections/collection-agent',
    name: 'CollectionsAgent',
    exact: true,
  },

  collectionsAgentChild: {
    path: '/collections/collection-agent/:childRoute/:id?',
    name: 'CollectionsAgentChild',
    exact: true,
  },

  collectionsSearchAccount: {
    path: '/collections/collection-agent/search-accounts',
    name: 'CollectionsSearchAccount',
    exact: true,
  },

  collectionsInvoiceUnits: {
    path: '/collections/collection-agent/invoice-units',
    name: 'CollectionsSearchAccount',
    exact: true,
  },

  collectionsInvoiceUnitsDetails: {
    path: '/collections/collection-agent/invoice-units/:id',
    name: 'CollectionsSearchAccount',
    exact: true,
  },

  collectionsHistory: {
    path: '/collections/collection-agent/collection-history',
    name: 'CollectionsHistory',
    exact: true,
  },

  collectionsHistoryDetails: {
    path: '/collections/collection-agent/collection-history/:id',
    name: 'CollectionsHistoryDetails',
    exact: true,
  },

  searchAndList: {
    path: '/orders/search-list',
    name: 'searchList',
    exact: false,
  },
  orderDetail: {
    path: '/orders/:id/detail',
    name: 'searchList',
    exact: false,
  },
  orders: {
    path: '/orders',
    name: 'OrdersPage',
    exact: true,
  },
  newOrder: {
    path: '/orders/new-order',
    name: 'newOrder',
    exact: false,
  },
  trialSubscription: {
    path: '/orders/trial-subscription',
    name: 'trialSubscription',
    exact: false,
  },
  modifyOrder: {
    path: '/orders/modify-order',
    name: 'modifyOrder',
    exact: true,
  },
  suspendOrder: {
    path: '/orders/suspend',
    name: 'suspendOrder',
    exact: false,
  },
  resumeOrder: {
    path: '/orders/resume',
    name: 'resumeOrder',
    exact: false,
  },
  cancelOrder: {
    path: '/orders/cancel',
    name: 'cancelOrder',
    exact: false,
  },
  renewOrder: {
    path: '/orders/renew',
    name: 'renewOrder',
    exact: false,
  },
  downgradeOrder: {
    path: '/orders/downgrade',
    name: 'downgradeOrder',
    exact: false,
  },
  optIn: {
    path: '/orders/opt-in',
    name: 'optIn',
    exact: false,
  },
  optOut: {
    path: '/orders/opt-out',
    name: 'optOut',
    exact: false,
  },
  upgradeOrder: {
    path: '/orders/upgrade-order',
    name: 'upgradeOrder',
    exact: false,
  },
  userManagement: {
    path: '/user-management',
    name: 'UserManagement',
    exact: true,
  },
  userManagementUser: {
    path: '/user-management/users',
    name: 'UserManagementUser',
    exact: true,
  },
  userManagementUserAdd: {
    path: '/user-management/users/add',
    name: 'UserManagementUserAdd',
    exact: true,
  },
  userManagementUserEdit: {
    path: '/user-management/users/:id',
    name: 'UserManagementUserEdit',
    exact: true,
  },
  userManagementRole: {
    path: '/user-management/roles',
    name: 'UserManagementRole',
    exact: true,
  },
  userManagementRoleAdd: {
    path: '/user-management/roles/add',
    name: 'UserManagementRoleAdd',
    exact: true,
  },
  userManagementRoleEdit: {
    path: '/user-management/roles/:id',
    name: 'UserManagementRoleEdit',
    exact: true,
  },
  userManagementRoleGroup: {
    path: '/user-management/role-groups',
    name: 'UserManagementRoleGroup',
    exact: true,
  },
  userManagementRoleGroupAdd: {
    path: '/user-management/role-groups/add',
    name: 'UserManagementRoleGroupAdd',
    exact: true,
  },
  userManagementRoleGroupEdit: {
    path: '/user-management/role-groups/:id',
    name: 'UserManagementRoleGroupEdit',
    exact: true,
  },
  jobsManagment: {
    path: '/jobs-management',
    name: 'JobsManagment',
    exact: true,
  },
  jobsManagmentJobConfig: {
    path: '/jobs-management/job-config',
    name: 'JobsManagmentJobConfig',
    exact: true,
  },
  jobsManagmentJobSchedule: {
    path: '/jobs-management/job-schedule',
    name: 'JobsManagmentJobSchedule',
    exact: true,
  },

  // Tenant Management
  tenantManagement: {
    path: '/tenant-management',
    name: 'TenantManagement',
    exact: true,
  },

  tenantData: {
    path: '/tenant-management/tenant-data',
    name: 'tenantData',
    exact: true,
  },

  tenantProfile: {
    path: '/tenant-management/tenant-profile',
    name: 'tenantProfile',
    exact: true,
  },

  tenantPlatformDefaults: {
    path: '/tenant-management/platform-defaults',
    name: 'platformDefaults',
    exact: true,
  },

  tenantPaymentGateway: {
    path: '/tenant-management/payment-gateway',
    name: 'tenantPaymentGateway',
    exact: true,
  },

  tenantMerchantData: {
    path: '/tenant-management/payment-gateway/merchant-data',
    name: 'tenantMerchantData',
    exact: true,
  },

  tenantCustomRecords: {
    path: '/tenant-management/payment-gateway/custom-records',
    name: 'tenantCustomRecords',
    exact: true,
  },

  tenantUrlMapping: {
    path: '/tenant-management/payment-gateway/url-mapping',
    name: 'tenantUrlMapping',
    exact: true,
  },

  tenantRequestResponseMapping: {
    path: '/tenant-management/payment-gateway/request-response-mapping',
    name: 'tenantRequestResponseMapping',
    exact: true,
  },

  // Teanat tax gateway
  tenantTaxGateway: {
    path: '/tenant-management/tax-gateway',
    name: 'tenantTaxGateway',
    exact: true,
  },

  tenantTaxMerchantData: {
    path: '/tenant-management/tax-gateway/merchant-data',
    name: 'tenantTaxMerchantData',
    exact: true,
  },

  tenantTaxCustomRecords: {
    path: '/tenant-management/tax-gateway/custom-records',
    name: 'tenantTaxCustomRecords',
    exact: true,
  },

  tenantTaxUrlMapping: {
    path: '/tenant-management/tax-gateway/url-mapping',
    name: 'tenantTaxUrlMapping',
    exact: true,
  },

  tenantTaxRequestResponseMapping: {
    path: '/tenant-management/tax-gateway/request-response-mapping',
    name: 'tenantTaxRequestResponseMapping',
    exact: true,
  },

  // Teanat tax gateway
  tenantFinanceGateway: {
    path: '/tenant-management/finance-gateway',
    name: 'tenantFinanceGateway',
    exact: true,
  },

  tenantFinanceMerchantData: {
    path: '/tenant-management/finance-gateway/merchant-data',
    name: 'tenantFinanceMerchantData',
    exact: true,
  },

  tenantFinanceCustomRecords: {
    path: '/tenant-management/finance-gateway/custom-records',
    name: 'tenantFinanceCustomRecords',
    exact: true,
  },

  tenantFinanceUrlMapping: {
    path: '/tenant-management/finance-gateway/url-mapping',
    name: 'tenantFinanceUrlMapping',
    exact: true,
  },

  tenantFinanceRequestResponseMapping: {
    path: '/tenant-management/finance-gateway/request-response-mapping',
    name: 'tenantFinanceRequestResponseMapping',
    exact: true,
  },

  manageProfile: {
    path: '/manage-profile',
    name: 'ManageProfile',
    exact: true,
  },

  baseConfigurations: {
    path: '/base-configurations',
    name: 'BaseConfigurations',
    exact: true,
  },

  productFamily: {
    path: '/base-configurations/product-family',
    name: 'Product Family',
    exact: true,
  },

  resources: {
    path: '/base-configurations/resources',
    name: 'Resources',
    exact: true,
  },

  currency: {
    path: '/base-configurations/resources/currency',
    name: 'Currency',
    exact: true,
  },

  creditLimit: {
    path: '/base-configurations/resources/credit-limit',
    name: 'CreditLimit',
    exact: true,
  },

  grants: {
    path: '/base-configurations/resources/grants',
    name: 'Grants',
    exact: true,
  },

  accumulators: {
    path: '/base-configurations/resources/accumulators',
    name: 'Accumulators',
    exact: true,
  },

  usageRating: {
    path: '/base-configurations/usage-rating',
    name: 'usageRating',
    exact: true,
  },

  rateUnit: {
    path: '/base-configurations/usage-rating/rate-unit',
    name: 'RateUnit',
    exact: true,
  },

  rateUnitMap: {
    path: '/base-configurations/usage-rating/rate-unit-map',
    name: 'RateUnit',
    exact: true,
  },

  timeUnit: {
    path: '/base-configurations/usage-rating/time-unit',
    name: 'TimeUnit',
    exact: true,
  },

  zoneUnit: {
    path: '/base-configurations/usage-rating/zone-unit',
    name: 'ZoneUnit',
    exact: true,
  },

  usageMapping: {
    path: '/base-configurations/usage-mapping',
    name: 'usageMapping',
    exact: true,
  },

  fileMapping: {
    path: '/base-configurations/usage-mapping/file-mapping',
    name: 'FileMapping',
    exact: true,
  },

  customRecords: {
    path: '/base-configurations/usage-mapping/custom-records',
    name: 'CustomRecords',
    exact: true,
  },

  processingStreams: {
    path: '/base-configurations/usage-mapping/processing-streams',
    name: 'ProcessingStreams',
    exact: true,
  },

  // Report page
  report: {
    path: '/report',
    name: 'report',
    exact: true,
  },

  newCustomerReport: {
    path: '/report/new-customer',
    name: 'newCustomerReport',
    exact: true,
  },

  accountClosure: {
    path: '/report/account-closure',
    name: 'accountClosure',
    exact: true,
  },

  subscriptionExpiry: {
    path: '/report/subscription-expiry',
    name: 'subscriptionExpiry',
    exact: true,
  },

  trialExpiry: {
    path: '/report/trial-expiry',
    name: 'trialExpiry',
    exact: true,
  },

  trialToPaidSubscription: {
    path: '/report/trial-to-paid-subscription',
    name: 'trialToPaidSubscription',
    exact: true,
  },

  activeSubscriptions: {
    path: '/report/active-subscriptions',
    name: 'activeSubscriptions',
    exact: true,
  },

  subscriptionContractExpiry: {
    path: '/report/active-subscription-contract-expiry',
    name: 'subscriptionContractExpiry',
    exact: true,
  },

  orderReport: {
    path: '/report/order',
    name: 'orderReport',
    exact: true,
  },

  billingReport: {
    path: '/report/billing',
    name: 'billingReport',
    exact: true,
  },

  productSummary: {
    path: '/report/product-summary',
    name: 'productSummary',
    exact: true,
  },

  invoicingReport: {
    path: '/report/invoicing',
    name: 'invoicingReport',
    exact: true,
  },

  transactionSummary: {
    path: '/report/transaction-summary',
    name: 'transactionSummary',
    exact: true,
  },

  taxSummary: {
    path: '/report/tax-summary',
    name: 'taxSummary',
    exact: true,
  },

  taxDetail: {
    path: '/report/tax-detail',
    name: 'taxDetail',
    exact: true,
  },

  paymentReport: {
    path: '/report/payment',
    name: 'paymentReport',
    exact: true,
  },

  failPayment: {
    path: '/report/fail-payment',
    name: 'failPayment',
    exact: true,
  },

  paymentSuspenseReport: {
    path: '/report/payment-suspense-report',
    name: 'paymentSuspenseReport',
    exact: true,
  },

  collection030DaysReport: {
    path: '/report/collection-0-30-days',
    name: 'collection030DaysReport',
    exact: true,
  },

  collection3060DaysReport: {
    path: '/report/collection-30-60-days',
    name: 'collection3060DaysReport',
    exact: true,
  },

  collection6090DaysReport: {
    path: '/report/collection-60-90-days',
    name: 'collection6090DaysReport',
    exact: true,
  },

  collectionOver90DaysReport: {
    path: '/report/collection-over-90-days',
    name: 'collectionOver90DaysReport',
    exact: true,
  },

  collectionAgeingSummaryReport: {
    path: '/report/ageing-summary',
    name: 'collectionAgeingSummaryReport',
    exact: true,
  },

  arOpsDetails: {
    path: '/report/ar-ops-details',
    name: 'arOpsDetails',
    exact: true,
  },

  openDispute: {
    path: '/report/open-dispute',
    name: 'openDispute',
    exact: true,
  },

  openWriteOff: {
    path: '/report/open-write-off',
    name: 'openWriteOff',
    exact: true,
  },

  usageSummary: {
    path: '/report/usage-summary',
    name: 'usageSummary',
    exact: true,
  },

  usageDetail: {
    path: '/report/usage-details',
    name: 'usageDetail',
    exact: true,
  },

  usageReconciliationSummary: {
    path: '/report/usage-reconciliation-summary',
    name: 'usageReconciliationSummary',
    exact: true,
  },

  revenueSummary: {
    path: '/report/revenue-summary',
    name: 'revenueSummary',
    exact: true,
  },

  revenueRecognition: {
    path: '/report/revenue-recognition',
    name: 'revenueRecognition',
    exact: true,
  },
  revenueForecast: {
    path: '/report/revenue-forecast',
    name: 'revenueForecast',
    exact: true,
  },

  revenueMMRForecast: {
    path: '/report/revenue-mmr-forecast',
    name: 'revenueMMRForecast',
    exact: true,
  },

  jobScheduleSummary: {
    path: '/report/job-schedule-summary',
    name: 'jobScheduleSummary',
    exact: true,
  },

  jobFailureActivity: {
    path: '/report/job-failure-activity',
    name: 'jobFailureActivity',
    exact: true,
  },

  jobDetail: {
    path: '/report/job-detail',
    name: 'jobDetail',
    exact: true,
  },

  unbilledTransactions: {
    path: '/report/unbilled-transactions',
    name: 'unbilledTransactions',
    exact: true,
  },

  billingInvoicing: {
    path: '/report/billing-invoicing',
    name: 'billingInvoicing',
    exact: true,
  },

  billingAR: {
    path: '/report/invoicing-ar',
    name: 'billingAR',
    exact: true,
  },

  creditNotes: {
    path: '/credit-notes',
    name: 'creditNotes',
    exact: true,
  },

  creditNotesInfo: {
    path: '/credit-notes/:id',
    name: 'creditNotesInfo',
    exact: true,
  },

  customerDetailsCreditNotes: {
    path: '/customers/:id/credit-notes',
    name: 'creditNotes',
    exact: true,
  },
  // report page end
  rawCdr: {
    path: '/raw-cdr-report',
    name: 'rawCdr',
    exact: true,
  },

  nonBroadSoftRawCdrData: {
    path: '/non-broad-soft-raw-cdr-data',
    name: 'nonBroadSoftRawCdrData',
    exact: true,
  },

  nonBroadsoftZeroDurationCdrs: {
    path: '/non-broadsoft-zero-duration-cdrs',
    name: 'nonBroadsoftZeroDurationCdrs',
    exact: true,
  },

  rawSMSFiles: {
    path: '/raw-sms-report',
    name: 'rawSMSFiles',
    exact: true,
  },

  onDemand: {
    path: '/report/on-demand',
    name: 'onDemand',
    exact: true,
  },
  prettifyPath,
};
