import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';
import { getFirstTimeLogin, getPassword, getAccountId } from '../../localStorage';
import injectSaga from '../../utils/injectSaga';
import { MainHeader } from '../../components/Header';
import { InfoCard } from '../../components/DashBoard';
import injectReducer from '../../utils/injectReducer';
import { ChangePassword } from '../../components/Login';
import makeSelectDashBoardPage from './selectors';
import { makeGetS3Config } from '../App/selectors';
import reducer from './reducer';
import saga from './saga';
import { SliderContainer, GenericInput, DataTable, TooltipGroup } from '../../components/common';
import { handleChangePassword, getAccountInformation } from './actions';
import { getBase64 } from '../../utils/utils';
import { getTenantSelfCareConfig, getS3Buctket, readFileFromS3 } from '../App/actions';
import './styles.scss';

const keyLinksToShare = [
  {
    name: 'text',
    label: 'label.linkText',
    render: (colName, item) => (
      <GenericInput value={item.text} readOnly wrapperClass="col-md-12 p-0 m-0" onChange={() => {}} name="text" />
    ),
  },
  {
    name: 'url',
    label: 'label.URL',
    style: { textAlign: 'center', minWidth: '110px' },
    render: (colName, item) => (
      <GenericInput value={item.url} readOnly wrapperClass="col-md-12 p-0 m-0" onChange={() => {}} name="url" />
    ),
  },
];

const headerTableAdvertisement = [
  {
    name: 'header',
    label: 'label.advHeader',
    style: { textAlign: 'center', minWidth: '110px' },
    render: (colName, item) => (
      <GenericInput value={item.header} readOnly wrapperClass="col-md-12" onChange={() => {}} name="header" />
    ),
  },
  {
    name: 'shortDescription',
    label: 'label.advShortDescription',
    style: { textAlign: 'center', minWidth: '110px' },
    render: (colName, item) => (
      <GenericInput
        value={item.shortDescription}
        wrapperClass="col-md-12"
        onChange={() => {}}
        name="shortDescription"
        readOnly
      />
    ),
  },
];
export class DashBoardPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: getFirstTimeLogin() === 'true',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      accountInformation: {},
      data: { mediaFiles: [], advertisements: [], keyLinks: [] },
      imagesList: [],
      videosList: [],
    };
  }

  componentDidMount() {
    this.props.getAccountInformation(getAccountId(), ({ success, data }) => {
      if (success && data) {
        this.setState({ accountInformation: data });
      }
    });
    const { getS3Buctket } = this.props;
    getS3Buctket('', () => {
      this.getSelfCareConfig();
    });
  }

  getSelfCareConfig = () => {
    const { getTenantSelfCareConfig, s3Config, readFileFromS3 } = this.props;
    getTenantSelfCareConfig('', ({ data, success }) => {
      this.setState({ imagesList: [] });
      if (data && success) {
        this.setState({
          data: data[0],
        });
        if (data && data[0] && data[0].mediaFiles) {
          data[0].mediaFiles.forEach(val => {
            readFileFromS3({ url: val.path, ...s3Config }, ({ success, data }) => {
              if (success) {
                getBase64(data, result => {
                  const { imagesList, videosList } = this.state;
                  if (val.type === 'IMAGE') imagesList.push(result);
                  if (val.type === 'VIDEO') videosList.push(result);
                  this.setState({ imagesList: [...imagesList], videosList: [...videosList] });
                });
              }
            });
          });
        }
      }
    });
  };

  redirectPage = pageName => {
    this.props.history.push(pageName);
  };

  handleChange = (evt, key) => {
    this.setState({ [key]: evt.target.value });
  };

  handleSubmit = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    if (oldPassword !== getPassword()) {
      return alert('Please check password');
    }

    if (newPassword && newPassword === confirmPassword && newPassword !== oldPassword) {
      this.props.handleChangePassword(newPassword, success => {
        if (success) {
          this.setState({ isOpenModal: !success });
        }
      });
    } else {
      return alert('Please check the new password');
    }
  };

  render() {
    const {
      isOpenModal,
      oldPassword,
      newPassword,
      confirmPassword,
      accountInformation,
      imagesList,
      data,
      videosList,
    } = this.state;
    const { t } = this.props;
    return (
      <div className="dashboard-page">
        <header>
          <MainHeader activeTab="Dashboard" />
        </header>
        <div>
          <InfoCard accountInformation={accountInformation} />
        </div>
        <div className="col-md-12 row mt-0">
          <div className="col-md-3">
            <SliderContainer imagesList={imagesList} />
          </div>
          <div className="col-md-3">
            <div className="group-viewer-dashboard">
              <div className="title-header">
                <span className="ml-2">{t('label.keyLink')}</span>
              </div>
              <div className="col-md-12 mt-0 p-0 mt-1">
                {/* <DataTable columns={keyLinksToShare} data={data && data && data.keyLinks ? data.keyLinks : []} /> */}
                {data.keyLinks &&
                  data.keyLinks.length > 0 &&
                  data.keyLinks.map(val => (
                    <div className="pt-2">
                      <a className="key-link" href={val.url} target="_blank">
                        {val.text}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="group-viewer-dashboard">
              <div className="col-md-12">
                {/* <DataTable
                  columns={headerTableAdvertisement}
                  data={data && data && data.advertisements ? data.advertisements : []}
                /> */}
                <div className="container-advertisement">
                  {data.advertisements &&
                    data.advertisements.length > 0 &&
                    data.advertisements.map((val, index) => (
                      <>
                        <div className={`title-hover ${index > 0 ? 'pt-3' : ''}`}>{val.header}</div>
                        <div>{val.shortDescription}</div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 video-group">
            {videosList && videosList[0] && (
              <video controls muted height="250 !important" width="100%" autoPlay controlsList="nodownload">
                <source src={videosList && videosList[0] ? videosList[0] : null} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            )}
          </div>
        </div>
        <br />
        <br />
        <ChangePassword
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          isOpenModal={isOpenModal}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
        />
      </div>
    );
  }
}

DashBoardPage.propTypes = {
  history: PropTypes.object.isRequired,
  handleChangePassword: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  dashBoardPage: makeSelectDashBoardPage(),
  s3Config: makeGetS3Config() || {},
});

const withConnect = connect(mapStateToProps, {
  handleChangePassword,
  getAccountInformation,
  getTenantSelfCareConfig,
  getS3Buctket,
  readFileFromS3,
});

const withReducer = injectReducer({ key: 'dashBoardPageReducer', reducer });
const withSaga = injectSaga({ key: 'dashBoardPageSaga', saga });

export default withTranslation('common')(compose(withReducer, withSaga, withConnect)(DashBoardPage));
