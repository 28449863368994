import React, { Component } from 'react';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { SLIDE_UP } from 'react-ladda';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import XMLViewer from 'react-xml-viewer';
import { MainHeader } from '../../../components/Header';
import { ButtonCustom } from '../../../components/common';
import { checkPermissionViewInvoice } from '../../../utils/CheckPermissions';
import { makeGetPermissionSelfCare, makeGetS3Config } from '../../App/selectors';
import { getInvoiceById, getNotesUnitById, getTenantInvoiceTemplates } from '../actions';
import { getBase64, splitF } from '../../../utils/utils';
import { getAccountById, getS3Buctket, readFileFromS3, getAwsS3Credential, getObjectFileById } from '../../App/actions';
import { makeErrorMessage, makeGetInvoiceTemplate } from '../selectors';
import '../styles.scss';

let fileSelect = '';
let pthSelect = null;

class InvoiceLayout extends Component {
  constructor() {
    super();
    this.state = {
      contentReportUrl: null,
      base64FilePdf: null,
      base64FileHtml: null,
      isHtml: true,
      fileData: null,
      base64File: null,
      isXML: false,
      xmlData: null,
    };
    this.dataInvoice = null;
  }

  componentDidMount() {
    const {
      id,
      getInvoiceById,
      getAccountById,
      getNotesUnitById,
      getTenantInvoiceTemplates,
      getAwsS3Credential,
      getObjectFileById,
      history: { location },
    } = this.props;
    const doGetDetails =
      location && location.pathname.indexOf('credit-notes') !== -1 ? getNotesUnitById : getInvoiceById;
    // doGetDetails(id, data => {
    //   if (data && data.accountId)
    //     getAccountById({ id: data.accountId }, dt => {
    //       const newData = cloneDeep(data);
    //       if (dt.success && newData.customerAddress && newData.customerAddress[0]) {
    //         newData.customerAddress[0].companyName =
    //           dt && dt.data && dt.data.contacts && dt.data.contacts[0] ? dt.data.contacts[0].organization : '';
    //       }
    //       this.dataInvoice = newData;
    //       this.handleRunReport(newData, 'html');
    //     });
    // });

    getAwsS3Credential('', config => {
      getTenantInvoiceTemplates('', () => {
        if (location && location.state && location.state.fileData && location.state.fileData.length) {
          const { state } = location;
          let pdfPath = '';
          const pdfData = state.fileData.find(val => val.fileType === 'PDF');
          if (pdfData) {
            pdfPath = pdfData.path;
            this.doReadFileFromS3({ config, path: pdfPath });
          }
          this.setState({ fileData: state.fileData });
        } else {
          getObjectFileById(id, data => {
            let pdfPath = '';
            if (data && data.length) {
              const pdfData = data.find(val => val.fileType === 'PDF');
              if (pdfData) {
                pdfPath = pdfData.path;
              }
              this.setState({ fileData: data });
            }
            if (pdfPath) this.doReadFileFromS3({ config, path: pdfPath });
          });
        }
      });
    });
  }

  doReadFileFromS3 = ({ config, path }) => {
    const { readFileFromS3 } = this.props;
    readFileFromS3({ ...config.data, url: path }, ({ success, data }) => {
      if (success) {
        const { isXML } = this.state;
        fileSelect = data;
        pthSelect = path;
        if (isXML) {
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({ xmlData: reader.result });
          };
          reader.readAsText(fileSelect);
        }
        getBase64(data, result => {
          this.setState({ base64File: result });
        });
      }
    });
  };

  handleClickButton = path => {
    const { s3Config } = this.props;
    this.doReadFileFromS3({ path, config: { data: s3Config } });
  };

  downloadFile = (theBlob, fileName) => {
    saveAs(theBlob, fileName);
  };

  render() {
    const {
      errorMessage,
      permissionsSelfCare,
      history: {
        location: { state },
      },
      t,
    } = this.props;

    let modeViewInvoice = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewInvoice = checkPermissionViewInvoice({
        listPermission,
      });
    }
    if (errorMessage) {
      return (
        <div className="m-t">
          <p className="txt-error">{errorMessage}</p>
        </div>
      );
    }

    const { buttonActive, isHtml, base64FilePdf, base64FileHtml, base64File, fileData, isXML, xmlData } = this.state;
    return (
      <div className="view-invoices-details">
        <MainHeader />
        {modeViewInvoice !== 0 && (
          <div className="view-invoices-details__form">
            <div className="form-wrapper">
              <div className="m-t pb-3 pl-1">
                <ButtonCustom
                  className="btnmodify ml-2"
                  type="button"
                  title={t('label.back')}
                  datastyle={SLIDE_UP}
                  onClick={() => {
                    this.props.history.push(state && state.fromPath ? state.fromPath : '/view-invoices');
                  }}
                />
                {fileData && fileData.length && (
                  <div className="float-right pr-2">
                    {fileData.map(val => {
                      if (val.type === 'TXT') return '';
                      return (
                        <Button
                          className={classNames('btn', {
                            'btn-primary': !buttonActive,
                            'btn-default': buttonActive,
                          })}
                          onClick={() => {
                            this.handleClickButton(val.path || null);
                            if (val.fileType === 'XML') {
                              this.setState({ isXML: true });
                            } else {
                              this.setState({ isXML: false });
                            }
                          }}
                        >
                          {val.fileType}
                        </Button>
                      );
                    })}
                    <Button
                      type="button"
                      className={classNames('btn', {
                        'btn-primary': !buttonActive,
                        'btn-default': buttonActive,
                      })}
                      onClick={() => this.downloadFile(fileSelect, splitF(pthSelect))}
                    >
                      {t('label.download')}
                    </Button>
                  </div>
                )}
                {/* <div className=" float-right pr-2">
                  <ButtonToolbar className="form__button-toolbar">
                    <Button
                      className={classNames('btn', {
                        'btn-primary': !buttonActive,
                        'btn-default': buttonActive,
                      })}
                      onClick={() => this.handleClickButton(0)}
                    >
                      HTML
                    </Button>
                    <Button
                      className={classNames('btn', {
                        'btn-primary': buttonActive,
                        'btn-default': !buttonActive,
                      })}
                      onClick={() => this.handleClickButton(1)}
                    >
                      PDF
                    </Button>
                  </ButtonToolbar>
                </div> */}
              </div>

              {/* <iframe
                type="application/pdf"
                src={this.state.contentReportUrl}
                title="report_view"
                style={{ width: '100%', minHeight: '640px' }}
              /> */}
              <div>
                {!isXML && (
                  <iframe
                    type="application/pdf"
                    src={base64File || (isHtml ? base64FileHtml : base64FilePdf)}
                    title="report_view"
                    style={{ width: '95%', marginLeft: '30px', minHeight: '640px' }}
                  />
                )}
                {isXML && (
                  <div
                    className="xml-viewer"
                    style={{ width: '95%', marginLeft: '30px', minHeight: '740px', overflow: 'auto' }}
                  >
                    <XMLViewer xml={xmlData} />
                  </div>
                )}
              </div>
              <div className="button-back" style={{ paddingTop: 10 }}>
                <ButtonCustom
                  className="btnmodify"
                  type="button"
                  title={t('label.cancel')}
                  datastyle={SLIDE_UP}
                  onClick={() => {
                    this.props.history.push(state && state.fromPath ? state.fromPath : '/view-invoices');
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

InvoiceLayout.propTypes = {
  getInvoiceById: PropTypes.func,
  getNotesUnitById: PropTypes.func,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  history: PropTypes.object,
  permissionsSelfCare: PropTypes.object,
  getAccountById: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || '',
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
  invoiceTemplates: makeGetInvoiceTemplate() || [],
  s3Config: makeGetS3Config() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    getInvoiceById,
    getAccountById,
    getNotesUnitById,
    getTenantInvoiceTemplates,
    getS3Buctket,
    readFileFromS3,
    getAwsS3Credential,
    getObjectFileById,
  })(InvoiceLayout)
);
