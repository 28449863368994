import React from 'react';
import PropTypes from 'prop-types';
import { SLIDE_UP } from 'react-ladda';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { MainHeader } from '../../components/Header';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import { SearchFilterAccountStatement } from '../../components/ViewAccountStatement';
import {
  ButtonCustom,
  TablePagination,
  DataTable,
  SwitchExport,
  ButtonExport,
  ExcelExport,
  MultiInputGroup,
  AccountHeaderForm,
} from '../../components/common';
import { getAccountId } from '../../localStorage';
import { checkPermissionViewTransactions } from '../../utils/CheckPermissions';
import { makeGetPermissionSelfCare, makeGetAccountInfo } from '../App/selectors';
import { uploadMultiPartFiles, getAccountById, getObjectFileById } from '../App/actions';
import reducer from './reducer';
import saga from './saga';
import './styles.scss';
import {
  getAccountStatement,
  getAllAccountStatement,
  getClientAccountStatement,
  getAllClientAccountStatement,
} from './actions';
import {
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
  getPageTotalCount,
  formatMoneyValue,
} from '../../utils/utils';
import convertJson2Sheet from '../../utils/ExcelHelper/exportExcelAccountStatement';
import convertJson2Pdf from '../../utils/PdfHelper/exportPdfAccountStatement';
import { makeGetListAllAccountStatement } from './selectors';

export class ViewAccountStatement extends React.PureComponent {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActivePdf: false,
      page: 0,
      size: 20,
      filter: {},
      sort: '',
      sorted: {},
      totalCount: null,
      isSearching: false,
      data: [],
    };
  }

  componentDidMount() {
    const { accountInfo, getAccountById } = this.props;
    if (!accountInfo || !accountInfo.id) {
      getAccountById({ id: getAccountId() }, () => {
        this.doGetReportAccountStatement();
      });
    } else {
      this.doGetReportAccountStatement();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.arOpsUnit !== nextProps.arOpsUnit) {
      const {
        params: { size },
      } = nextProps;

      if (nextProps.arOpsUnit.length < size) {
        this.setState({ isActiveNext: false });
      } else {
        this.setState({ isActiveNext: true });
      }
    }
  }

  onHandleSearch = filter => {
    this.setState({ filter }, () => this.doGetReportAccountStatement());
  };

  doGetReportAccountStatement = () => {
    const { filter, page, size, totalCount } = this.state;
    const { accountInfo, getAccountStatement, getClientAccountStatement } = this.props;
    const payload = {
      page: page + 1,
      accountId: getAccountId(),
      size,
      filter,
    };
    this.setState({ isSearching: true });
    const doGetDatFromApi =
      accountInfo && accountInfo.type === 'CLIENT' ? getClientAccountStatement : getAccountStatement;
    doGetDatFromApi(payload, ({ data, success }) => {
      if (success) {
        this.setState({
          data,
          isActiveNext: data?.length === size,
          totalCount: getPageTotalCount({ totalCount: totalCount || data?.length, size, items: data || [], page }),
        });
      } else {
        this.setState({ data: [], isActiveNext: data?.length === size });
      }
      this.setState({ isSearching: false });
    });
  };

  doGetAllAccountStatement = cb => {
    const { filter } = this.state;
    const { getAllAccountStatement, accountInfo, getAllClientAccountStatement } = this.props;
    const payload = {
      page: 1,
      accountId: getAccountId(),
      size: 250000,
      filter,
    };
    const doGetDatFromApi =
      accountInfo && accountInfo.type === 'CLIENT' ? getAllClientAccountStatement : getAllAccountStatement;
    doGetDatFromApi(payload, () => {
      if (cb) cb();
    });
  };

  onPageChange = pageChange => {
    const { page } = this.state;
    if (page === pageChange) return '';
    return this.setState({ page: pageChange }, () => this.doGetReportAccountStatement());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetReportAccountStatement());
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAllAccountStatement(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllAccountStatement, t, accountInfo } = this.props;
        convertJson2Pdf({
          data: listAllAccountStatement,
          t,
          title: t('label.accountStatement').toLocaleUpperCase(),
          fileName: `${t('label.accountStatement')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          customerInfo: accountInfo,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ columns }) => {
    this.doGetAllAccountStatement(() => {
      const { listAllAccountStatement, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_SERVICE_BILLING_REPORT',
          subject: `Embrix Report ${t('label.accountStatement')}`,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('label.accountStatement')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllAccountStatement,
            t,
            columns,
            listOptionFields: { lineType: 'accountStatementLineType' },
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllAccountStatement,
          t,
          title: t('label.accountStatement').toLocaleUpperCase(),
          fileName: `${t('label.accountStatement')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('label.accountStatement')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      // formData.append('file', blobToFile(pdfFile, 'attachment'));
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  doGetObjectFileById = (invoiceId, isInvoice) => {
    const { history, getObjectFileById } = this.props;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: isInvoice ? `/invoices/${invoiceId}` : `/view-file/${invoiceId}`,
          state: {
            fromPath: '/account-statement',
            fileData: data,
          },
        });
      }
    });
  };

  renderContent() {
    const { t, listAllAccountStatement, accountInfo } = this.props;
    const { data, page, size, isActivePdf, emails, totalCount } = this.state;
    const currency = [];
    const tableConfigColumns = [];
    if (accountInfo?.type === 'CLIENT') {
      currency.push({
        name: 'currency',
        label: 'label.invoiceCurrency',
      });
    }
    if (accountInfo?.type === 'CLIENT') {
      tableConfigColumns.push({
        name: 'amountInFunctionalCurrency',
        label: 'label.totalFunctionalCurrency',
        isSupportDangerouslySetInnerHTML: true,
        style: { textAlign: 'center' },
        render: (colName, item) => {
          return (
            <span className={`${item.amountInFunctionalCurrency < 0 ? 'red-color' : ''}`}>
              {formatMoneyValue(item.amountInFunctionalCurrency)}
            </span>
          );
        },
      });
      tableConfigColumns.push({
        name: 'functionalCurrency',
        label: 'label.functionalCurrency',
      });
      tableConfigColumns.push({
        name: 'exchangeRate',
        label: 'label.exchangeRate',
        render: (colName, item) => {
          return (
            <span className={`${item.exchangeRate < 0 ? 'red-color' : ''}`}>{formatMoneyValue(item.exchangeRate)}</span>
          );
        },
      });
    }
    const tabelColumnExportAccountStatement = [
      {
        name: 'accountId',
        label: 'label.accountNumber',
      },
      {
        name: 'lineType',
        label: 'label.documentType',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:accountStatementLineType')().find(val => val.value === item.lineType) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'invoiceId',
        label: 'label.invoiceId',
        style: { minWidth: '130px' },
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border no-background"
              type="button"
              onClick={() => this.doGetObjectFileById(item.invoiceId, true)}
            >
              {item.invoiceId}
            </button>
          );
        },
      },
      {
        name: 'invoiceDate',
        label: 'label.invoiceDate',
      },
      ...currency,
      {
        name: 'subTotal',
        label: 'label.subTotal',
        render: (colName, item) => {
          return <span className={`${item.subTotal < 0 ? 'red-color' : ''}`}>{formatMoneyValue(item.subTotal)}</span>;
        },
      },
      {
        name: 'taxes',
        label: 'label.taxes',
        render: (colName, item) => {
          return <span className={`${item.taxes < 0 ? 'red-color' : ''}`}>{formatMoneyValue(item.taxes)}</span>;
        },
      },
      {
        name: 'amount',
        label: 'label.total',
        render: (colName, item) => {
          return <span className={`${item.amount < 0 ? 'red-color' : ''}`}>{formatMoneyValue(item.amount)}</span>;
        },
      },
      ...tableConfigColumns,
      {
        name: 'dueDate',
        label: 'label.dueDate',
      },
      {
        name: 'lineReferenceId',
        label: 'label.folioId',
        render: (colName, item) => {
          return (
            <button
              className="text-success no-border no-background"
              type="button"
              onClick={() => this.doGetObjectFileById(item.lineReferenceId, false)}
            >
              {item.lineReferenceId}
            </button>
          );
        },
      },
      {
        name: 'lineDate',
        label: 'label.folioDate',
      },
      {
        name: 'folioStatus',
        label: 'label.folioStatus',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:folioStatus')().find(val => val.value === item.folioStatus) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'status',
        label: 'label.status',
      },
      {
        name: 'openingBalance',
        label: 'label.openingBalance',
        render: (colName, item) => {
          if (accountInfo?.type === 'CLIENT') {
            return <span />;
          }
          return (
            <span className={`${item.openingBalance < 0 ? 'red-color' : ''}`}>
              {formatMoneyValue(item.openingBalance)}
            </span>
          );
        },
      },
      {
        name: 'closingBalance',
        label: 'label.closingBalance',
        render: (colName, item) => {
          if (accountInfo?.type === 'CLIENT') {
            return <span />;
          }
          return (
            <span className={`${item.closingBalance < 0 ? 'red-color' : ''}`}>
              {formatMoneyValue(item.closingBalance || 0)}
            </span>
          );
        },
      },
    ];

    return (
      <div className="transactions">
        <div className="col-md-12 p-0">
          <br />
          <Row>
            <Col md={12}>
              {/* <h4 className="card-title-mb">
                {t('label.accountStatement')}
                <span className="account-number">
                  <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                  &nbsp;&nbsp;
                  <span className="account-detail">{getAccountId()}</span>
                </span>
              </h4> */}
              <AccountHeaderForm title={t('label.accountStatement')} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={12} className="p-0">
              <SearchFilterAccountStatement onHandleSearch={this.onHandleSearch} isSearching={this.state.isSearching} />
            </Col>
            <Col md={12} className="p-0">
              <div className="mt-3 d-flex float-right mb-4 pt-3 pb-3">
                <div className="ml-auto mt-2 mb-auto mr-3">
                  <SwitchExport
                    onChange={this.onChangeSwitch}
                    wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                    title={t('label.excel')}
                    checked={isActivePdf}
                    rightTitle={t('label.pdf')}
                  />
                </div>
                <>
                  <ButtonExport onExport={this.onExport} />
                  <ExcelExport
                    element={<button type="button" className="display-none" ref={this.buttonRef} />}
                    nameSheet={t('label.accountStatement').toLocaleUpperCase()}
                    multiDataSet={convertJson2Sheet({
                      data: listAllAccountStatement || [],
                      t,
                      title: t('label.accountStatement').toLocaleUpperCase(),
                      customerInfo: accountInfo,
                    })}
                    fileName={`${t('label.accountStatement')
                      .toLocaleLowerCase()
                      .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                  />
                </>
                <div className="email-group">
                  <MultiInputGroup
                    label={t('label.emails')}
                    wrapperClass="email-form"
                    value={emails || []}
                    inputProps={{
                      placeholder: t('label.addAEmail'),
                    }}
                    onChange={this.onChangeEmail}
                  />
                  <button
                    type="submit"
                    onClick={() => this.sendEmail({ columns: tabelColumnExportAccountStatement })}
                    disabled={!emails || !emails.length}
                    className="ladda-button btn btn-submit x-small mr-3 mt-0 float-right btn-default-height"
                  >
                    {t('label.email')}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <br />
          <DataTable
            isFixedHeaderTable
            tableClass="table table-hover"
            data={data}
            columns={tabelColumnExportAccountStatement}
          />
          {/* <ButtonCustom
            className="btn-cancel float-right mr-4 mt-2"
            type="button"
            title={t('label.cancel')}
            datastyle={SLIDE_UP}
            onClick={() => {
              this.props.history.push('/');
            }}
          /> */}
          <br />
          <br />
          <div className="mb-30">
            <TablePagination
              pageNumber={page}
              pageSize={size}
              totalCount={totalCount}
              onPageChange={this.onPageChange}
              onSizeChange={this.onSizeChange}
            />
          </div>
        </div>
        <br />

        <br />
      </div>
    );
  }

  render() {
    const { permissionsSelfCare } = this.props;
    let modeViewTransactions = 0;
    if (permissionsSelfCare && permissionsSelfCare.selfCareModulePermissions) {
      const listPermission = permissionsSelfCare.selfCareModulePermissions;
      modeViewTransactions = checkPermissionViewTransactions({
        listPermission,
      });
    }
    return (
      <div className="view-transactions-page">
        <MainHeader activeTab="Reports" />
        {modeViewTransactions !== 0 && (
          <div className="view-transactions-page__form">
            <div className="form-wrapper table-content">{this.renderContent()}</div>
          </div>
        )}
      </div>
    );
  }
}

ViewAccountStatement.propTypes = {
  history: PropTypes.object,
  arOpsUnit: PropTypes.array,
  params: PropTypes.object,
  getAccountStatement: PropTypes.func,
  permissionsSelfCare: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
  listAllAccountStatement: makeGetListAllAccountStatement() || {},
  accountInfo: makeGetAccountInfo() || {},
});

const withConnect = connect(mapStateToProps, {
  getAccountStatement,
  getAllAccountStatement,
  uploadMultiPartFiles,
  getClientAccountStatement,
  getAllClientAccountStatement,
  getAccountById,
  getObjectFileById,
});

const withReducer = injectReducer({
  key: 'ViewAccountStatementReducer',
  reducer,
});
const withSaga = injectSaga({ key: 'ViewAccountStatementSaga', saga });

export default withTranslation('common')(compose(withReducer, withSaga, withConnect)(ViewAccountStatement));
