import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { FaLock } from 'react-icons/fa';
import { createStructuredSelector } from 'reselect';
import logo from '../../assets/images/logo-dark.png';
import injectSaga from '../../utils/injectSaga';
import { itemLangSelected } from '../../utils/listLangue';
import saga from './saga';
import { GenericInput } from '../../components/common';
import { getBase64 } from '../../utils/utils';
import { userLogin } from './action';
import { makeGetLogoConfig } from '../App/selectors';
import { getTenantDefaultConfig, getAwsS3Credential, readFileFromS3, getLogoSuccess } from '../App/actions';
import './styles.scss';
import { setLogoBase64 } from '../../localStorage';

const optionsLang = [
  {
    label: 'English',
    value: 'en-US',
  },
  {
    label: 'Español',
    value: 'es',
  },
];

export class LoginPage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isLogin: false,
      userName: '',
      password: '',
      lang: localStorage.getItem('i18nextLng') || 'en-US',
    };
  }

  componentDidMount() {
    const { getTenantDefaultConfig, getAwsS3Credential, readFileFromS3, getLogoSuccess, logoBase64 } = this.props;
    if (!logoBase64) {
      getTenantDefaultConfig(({ success, data }) => {
        if (success && data && data.tenantProfile && data.tenantProfile.logoPath) {
          const pathFile = data.tenantProfile.logoPath;
          getAwsS3Credential('', ({ success, data }) => {
            if (success && data) {
              readFileFromS3({ ...data, url: pathFile }, ({ success, data }) => {
                if (success) {
                  getBase64(data, result => {
                    setLogoBase64(result);
                    getLogoSuccess(result);
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  onChangeText = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    this.doLogin();
  };

  doLogin = () => {
    this.setState({ isLogin: true });
    const { userName, password } = this.state;
    this.props.userLogin(
      {
        userName,
        password,
      },
      () => {
        this.setState({
          isLogin: false,
        });
      }
    );
  };

  handleKeyDown = evt => {
    if (evt.key === 'Enter') {
      this.doLogin();
    }
  };

  onChangeLanguage = ({ value }) => {
    const { i18n } = this.props;
    this.setState({ lang: value });
    i18n.changeLanguage(value);
  };

  render() {
    const { isLogin, userName, password, lang } = this.state;
    const { t, logoBase64 } = this.props;
    return (
      <div className="container-full">
        <div className="login-page login-page--photo">
          <div className="container">
            <div className="row h-100 row align-items-center">
              <div className="col-sm" />
              <div className="col-sm">
                <div className="card ">
                  <div className="card-body ">
                    <center>{logoBase64 && <img src={logoBase64} alt="logo" />}</center>
                    <hr />
                    <center>
                      <h1>{t('label.signIn')}</h1>
                    </center>
                    <br />
                    <br />
                    <div className="md-form">
                      <input
                        type="text"
                        value={userName}
                        name="userName"
                        onChange={this.onChangeText}
                        onKeyDown={this.handleKeyDown}
                        className="form-control"
                        placeholder={t('label.username')}
                      />
                    </div>
                    <br />
                    <div className="md-form">
                      <input
                        type="password"
                        value={password}
                        name="password"
                        onChange={this.onChangeText}
                        onKeyDown={this.handleKeyDown}
                        className="form-control"
                        placeholder={t('label.password')}
                      />
                    </div>
                    <br />
                    <div className="custom-control d-inline">
                      <input type="checkbox" className="custom-control-input" id="customCheck1" />
                      <label className="custom-control-label" htmlFor="customCheck1">
                        {t('label.rememberMe')}
                      </label>
                      <i />
                      <label className="float-right">
                        <i className="icon">
                          <FaLock />
                        </i>
                        {t('label.forgotPassword')}
                      </label>
                    </div>
                    <br />
                    <br />
                    <div className="loginbtn">
                      <button type="button" className="btn btn-login rounded logbtn" onClick={this.handleSubmit}>
                        {t('label.login')}
                      </button>
                    </div>
                    <br />
                    <p>
                      {`${t('label.doNotHaveAAccount')}?`}
                      <Link to="/register">
                        <span className="reglink color-default">{t('label.register')}</span>
                      </Link>
                    </p>
                    <div className="group-language d-flex">
                      <span>{`${t('label.chooseLanguage')} :`}</span>
                      <GenericInput
                        label="Choose Language:"
                        value={itemLangSelected(lang || 'en-US').key}
                        type="select"
                        name="lang"
                        options={optionsLang}
                        onChange={this.onChangeLanguage}
                        wrapperClass="col-md-5 p-0 group-select-lang"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  userLogin: PropTypes.func,
  // history: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  logoBase64: makeGetLogoConfig() || {},
});

const withConnect = connect(mapStateToProps, {
  userLogin,
  getTenantDefaultConfig,
  getAwsS3Credential,
  readFileFromS3,
  getLogoSuccess,
});
const withSaga = injectSaga({ key: 'loginSaga', saga });

export default withTranslation('common')(compose(withConnect, withSaga)(LoginPage));
