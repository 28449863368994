import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Cards from 'react-credit-cards';
import { AccountHeaderForm } from '../common';
import { getAccountId, getUserName } from '../../localStorage';
import { cardTokenFields, urlGetCreditCard } from '../../constantsApp';
import { getDataCardFromURL } from '../../utils/utils';

class MakePayment extends Component {
  constructor() {
    super();
    this.state = {
      cardToken: '',
      cardExpiry: '',
      last4CC: '',
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { search },
      },
      handleAddCreditCardFromURL,
    } = this.props;
    let token = '';
    let expiryDate = '';
    let last4 = '';
    if (search) {
      token = getDataCardFromURL(search, cardTokenFields.token);
      expiryDate = decodeURIComponent(getDataCardFromURL(search, cardTokenFields.expiryDate));
      last4 = getDataCardFromURL(search, cardTokenFields.last4cc);
    }
    if (token && expiryDate && last4) {
      handleAddCreditCardFromURL({
        accountId: getAccountId(),
        userId: getUserName(),
        creditCard: {
          cardToken: token,
          cardExpiry: expiryDate,
          last4CC: last4,
          merchant: 'qabrain3', // hard code
        },
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.length) {
      this.setState({
        cardToken: nextProps.data[0].cardToken,
        cardExpiry: nextProps.data[0].cardExpiry,
        last4CC: nextProps.data[0].last4CC,
      });
    }
  }

  changeText = () => {};

  render() {
    const { cardToken, cardExpiry, last4CC } = this.state;
    const { modeApplyPayment, t } = this.props;
    const domCVV = document.querySelector('.rccs__number');
    const domCardExpiry = document.querySelector('.rccs__expiry__valid');
    if (domCardExpiry) document.querySelector('.rccs__expiry__valid').textContent = 'Expiry';
    if (domCVV) document.querySelector('.rccs__number').textContent = `CVV: ${last4CC || ''}`;

    return (
      <div>
        <div className="cardtop">
          {/* <h4 className="card-title-payment">
            {`${t('label.services')} | ${t('label.accountNumber')}`}
            <span className="account-number">
              <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
              &nbsp;&nbsp;
              <span className="account-detail">{getAccountId()}</span>
            </span>
          </h4> */}
          <br />
          <AccountHeaderForm title={`${t('label.services')} | ${t('label.accountNumber')}`} />

          <div className="group-payment-control">
            <div className="card-body">
              <form action="#">
                <div className="form-body">
                  <div className="row p-t-0">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t('label.oneOffAmount')}</label>
                        <Input
                          style={{ marginRight: 20 }}
                          name="One-Off Amount"
                          value={t('label.oneOffAmount')}
                          placeholder={t('label.oneOffAmount')}
                          onChange={this.changeText}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>{t('label.applyToInvoiceUnitID')}</label>
                        <Input
                          style={{ marginRight: 20 }}
                          name="50.0099"
                          value="50.0099"
                          placeholder="50.0099"
                          onChange={this.changeText}
                        />
                      </div>
                    </div>
                    {modeApplyPayment !== 0 && (
                      <div className="col-md-2">
                        <button
                          type="button"
                          disabled={modeApplyPayment === 1}
                          className="btn-submit btn-apply btn-rounded waves-effect waves-light m-t-20"
                        >
                          {t('label.apply')}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body make-payment-card">
                <ul className="list-inline font-14 card-on-file">
                  <h4 className="card-title">{t('label.cardOnFile')}</h4>
                </ul>
                <Cards
                  cvc={last4CC || ''}
                  expiry={cardExpiry || ''}
                  focused=""
                  name={`Token: ${cardToken || ''}`}
                  number="asd"
                />
                <br />
                <br />
                <button type="button" className="btn-submit btn-rounded waves-effect waves-light m-t-20">
                  {t('label.payNow')}
                </button>
                &nbsp;
                <button
                  onClick={() => {
                    window.open(`${urlGetCreditCard.URL}${window.location}`, '_self');
                  }}
                  type="button"
                  className="btn btn-add btn-rounded waves-effect waves-light m-t-20"
                >
                  {t('label.addCard')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MakePayment.propTypes = {
  history: PropTypes.object,
  data: PropTypes.array,
  handleAddCreditCardFromURL: PropTypes.func,
};

export default withTranslation('common')(MakePayment);
