import { call, put, takeLatest } from 'redux-saga/effects';
import { queryRequest } from '../../utils/request';
import {} from 'react-router-redux';
import { getServiceUnitsByAccountId, getSubscriptionByAccountId } from '../../api';
import {} from '../Notification/actions';
import {} from '../../constantsApp';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';

export function* getSubscriptionSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getSubscriptionByAccountId(payload));
    if (response.getSubscriptionByAccountId) {
      yield put(actions.getSubscriptionSuccess(response.getSubscriptionByAccountId));
    } else {
      yield put(actions.getSubscriptionFailure(`Can not get subscription for account ${payload}`));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(actions.getSubscriptionFailure(`Can not get subscription for account ${payload}`));
    yield put(isEndConnected());
  }
}

export function* getServiceUnitsAcSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getServiceUnitsByAccountId(payload));
    if (response.getServiceUnitsByAccountId) {
      yield put(actions.getServiceUnitAcSuccess(response.getServiceUnitsByAccountId));
    } else {
      yield put(actions.getServiceUnitAcFailure(`Can not get service units for account ${payload}`));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(actions.getServiceUnitAcFailure(`Can not get service units for account ${payload}`));
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* customersSaga() {
  yield takeLatest(types.GET_SERVICE_UNITS_AC, getServiceUnitsAcSaga);
  yield takeLatest(types.GET_SUBSCRIPTION, getSubscriptionSaga);
}
