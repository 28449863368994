import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { SLIDE_UP } from 'react-ladda';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import { MainHeader } from '../../components/Header';
import { GenericInput, DataTable, FormCollapse, ButtonCustom, AccountHeaderForm } from '../../components/common';
import { getAccountId } from '../../localStorage';
import { makeGetPermissionSelfCare } from '../App/selectors';
import { getExternalPOByAccountId } from '../App/actions';

import './styles.scss';

const listFields = [
  {
    label: 'label.PONumber',
    name: 'purchaseOrderId',
  },
  {
    label: 'label.startDate',
    name: 'startDate',
    type: 'date',
  },
  {
    label: 'label.endDate',
    name: 'endDate',
    type: 'date',
  },
];

export class ViewExternalPO extends React.Component {
  state = {
    page: 1,
    size: 20,
    filter: {},
    sorted: {},
    totalCount: null,
    isSearching: false,
    isActiveNext: false,
    data: [],
    activeTab: {},
  };

  componentDidMount = () => {
    this.doGetExternalPOByAccountId();
  };

  doGetExternalPOByAccountId = () => {
    const { getExternalPOByAccountId } = this.props;
    this.setState({ isSearching: false });
    getExternalPOByAccountId(getAccountId(), ({ success, data }) => {
      if (success) this.setState({ data: data.poList });
      this.setState({ isSearching: false });
    });
  };

  onToggleTab = name => {
    const { activeTab } = this.state;
    if (activeTab.name === name) {
      this.setState({ activeTab: { name, isActive: !activeTab.isActive } });
    } else {
      this.setState({ activeTab: { name, isActive: true } });
    }
  };

  render() {
    const { permissionsSelfCare, t } = this.props;
    const { data, page, size, isSearching, activeTab } = this.state;
    const POColumns = [
      {
        name: 'itemid',
        label: t('label.id'),
      },
      {
        name: 'name',
        label: t('label.name'),
      },
      {
        name: 'description',
        label: t('label.description'),
      },
      {
        name: 'altdescription',
        label: t('label.PODescription'),
      },
    ];

    return (
      <div className="manage-page global-page">
        <MainHeader activeTab="AccountData" />
        <div className="manage-page__form p-3">
          <div className="form-billing">
            <div className="col-md-12 row pt-4 group-input-billing">
              <div className="col-md-12 p-0">
                <Row>
                  {/* <div className="col-md-12 pl-2 pr-0 pt-0 pb-3">
                    <h4 className="card-title-mb">
                      {t('label.externalPurchaseOrder')}
                      <span className="account-number">
                        <span className="account-detail">{`${t('label.accountNumber')}:`}</span>
                        &nbsp;&nbsp;
                        <span className="account-detail">{getAccountId()}</span>
                      </span>
                    </h4>
                  </div> */}
                  <AccountHeaderForm title={t('label.externalPurchaseOrder')} />
                </Row>
                {!data ||
                  (!data.length && (
                    <div className="text-center">
                      {t('message.noRecord')}
                      <br />
                      <br />
                    </div>
                  ))}
                {data &&
                  data.map((item, index) => {
                    return (
                      <FormCollapse
                        key={`key-${index}`}
                        isActive={`state-${index}` === activeTab.name && activeTab.isActive}
                        title={`${t('label.PO')} #: ${item.purchaseOrderId || ''}, ${t(
                          'label.startDate'
                        )}: ${item.startDate || ''}`}
                        state={`state-${index}`}
                        isNoT
                        onToggleTab={this.onToggleTab}
                      >
                        <div className="col-md-12 row">
                          <div className="col-md-12 ml-3 pt-3 row">
                            {listFields.map(val => (
                              <GenericInput
                                value={item[val.name]}
                                wrapperClass="col-md-4"
                                onChange={({ name, value }) => this.onHandleChange({ value, name, index: item.index })}
                                {...val}
                                readOnly
                              />
                            ))}
                          </div>
                        </div>
                        <br />
                        <div className="col-md-12 p-4">
                          <DataTable
                            columns={POColumns}
                            data={item && item.poItems && item.poItems.length > 0 ? item.poItems : []}
                            isLoading={isSearching}
                            indexParent={index}
                          />
                        </div>
                      </FormCollapse>
                    );
                  })}
                <ButtonCustom
                  className="btn-cancel float-right mr-4"
                  type="button"
                  title={t('label.cancel')}
                  datastyle={SLIDE_UP}
                  onClick={() => {
                    this.props.history.push('/');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ViewExternalPO.propTypes = {
  getExternalPOByAccountId: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  permissionsSelfCare: makeGetPermissionSelfCare() || {},
});

function mapDispatchToProps(dispatch) {
  return {
    getExternalPOByAccountId: (data, cb) => dispatch(getExternalPOByAccountId(data, cb)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common'])(compose(withConnect)(ViewExternalPO));
