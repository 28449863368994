export default {
  label: {
    userProfile: 'Perfil del Usuario',
    contactAgent: 'Agente de Contacto',
    myProfile: 'Mi Perfil',
    accountSetting: 'Configuración de la Cuenta',
    logout: 'Cerrar Sesión',
    subscription: 'Suscripción',
    activity: 'Actividad',
    managePaymentProfile: 'Gestionar Perfil de Pago',
    makePayment: 'Realizar Pago',
    billingData: 'Datos de Facturación',
    viewBalances: 'Ver Saldos',
    viewTransactions: 'Ver Transacciones',
    viewBills: 'Ver Facturas',
    aRActivity: 'Actividad CxC',
    balances: 'Balances',
    accountStatement: 'Estado de Cuenta',
    accountNumber: 'Número de Cuenta',
    state: 'Estado',
    city: 'Ciudad',
    firstName: 'Primer Nombre',
    lastName: 'Apellido',
    street: 'Calle',
    address: 'Dirección',
    country: 'País',
    email: 'Correo Electrónico',
    phoneNumber: 'Número Telefónico',
    userId: 'Id del Usuario',
    password: 'Contraseña',
    retypePassword: 'Recapturar Contraseña',
    rememberMe: 'Recuérdame',
    postalCode: 'Código Postal',
    submit: 'Enviar',
    cancel: 'Cancelar',
    services: 'Servicios',
    serviceUnits: 'Unidades de Servicio',
    id: 'Documento de Identidad',
    accountId: 'Id de la Cuenta',
    status: 'Estátus',
    reason: 'Razón',
    initialTerm: 'Plazo Inicial',
    initialTermUnit: 'Unidad del Plazo Inicial',
    renewalTerm: 'Período de Renovación',
    renewalTermUnit: 'Unidad del Período de Renovación',
    trialTerm: 'Plazo de prueba',
    trialTermUnit: 'Unidad del Plazo de Prueba',
    effectiveDate: 'Fecha Efectiva',
    type: 'Tipo',
    parentId: 'Id Padre',
    provisioningId: 'Id de Aprovisionamiento',
    bundleName: 'Nombre del Bundle',
    packageName: 'Nombre del Paquete',
    subscriptionId: 'Id de la Suscripción',
    creditCardToken: 'Token de la Tarjeta de Crédito',
    creditCardCVV: 'Código de Verificación de la Tarjeta de Crédito',
    creditCardExpiry: 'Vencimiento de la Tarjeta de Crédito',
    removeCreditCard: 'Remove credit card',
    creditCardNumber: 'Credit Card Number',
    addCreditCard: 'Añadir Tarjeta de Crédito',
    // token: 'Token',
    // expiry: 'Expiry',
    oneOffAmount: 'Monto A Aplicar',
    applyToInvoiceUnitID: 'ID de Factura Para Aplicar',
    cardOnFile: 'Tarjeta en Archivo',
    payNow: 'Pagar ahora',
    apply: 'Aplicar',
    addCard: 'Añadir Tarjeta de Crédito',
    currency: 'Divisa',
    grants: 'Grants',
    accumulators: 'Acumuladores',
    balance: 'Saldo',
    creditLimit: 'Límite de Crédito',
    amountUsed: 'Monto Usado',
    validityStart: 'Inicio de Validez',
    validityEnd: 'Término de Validez',
    amount: 'Monto',
    transactions: 'Transacciones',
    itemId: 'Identificación del Artículo',
    startDate: 'Fecha de Inicio',
    endDate: 'Fecha de Finalización',
    search: 'Búsqueda',
    searching: 'Buscando',
    transactionType: 'Tipo de Transacción',
    transactionUnit: 'Unidad de Transacción',
    itemDescription: 'Descripción del Artículo',
    serviceType: 'Tipo de Servicio',
    NET: 'NETO',
    source: 'Fuente',
    view: 'Ver',
    previous: 'Previo',
    next: 'Siguiente',
    recurringData: 'Datos Recurrentes',
    usageData: 'Datos de Uso',
    taxData: 'Datos Fiscales',
    transactionId: 'Id de Transacción',
    description: 'Descripción',
    billUnitId: 'Id de Unidad de Factura',
    serviceUnitId: 'Id de la Unidad de Servicio',
    netAmount: 'Importe Neto',
    index: 'Índice',
    balanceType: 'Tipo de Saldo',
    offerType: 'Tipo de Oferta',
    unitPrice: 'Precio Unitario',
    code: 'Código',
    taxCode: 'Código de Impuestos',
    jurisdiction: 'Jurisdicción',
    taxableAmount: 'Monto Gravable',
    exemptAmount: 'Monto Exento',
    taxAmount: 'Monto del Impuesto',
    accumulatorId: 'Id del Acumulador',
    balanceUnitIndex: 'Índice de Unidad de Equilibrio',
    accumulatorStartDate: 'Fecha de Inicio Del Acumulador',
    accumulatorEndDate: ' Fecha de Finalización Del Acumulador',
    grantId: 'Id del Subsidio',
    grantType: 'Tipo de Subvención',
    grantStartDate: 'Fecha de Inicio de La Subvención',
    grantEndDate: 'Fecha de Finalización de La Subvención',
    recurringType: 'Tipo Recurrente',
    recurringUnit: 'Unidad Recurrente',
    recurringDuration: 'Duración Recurrente',
    proration: 'Prorrateo',
    scale: 'Escala',
    cycleStart: 'Inicio Del Ciclo',
    cycleEnd: 'Fin de Ciclo',
    appliedStart: 'Inicio Aplicado',
    appliedEnd: 'Fin Aplicado',
    rateUnit: 'Unidad Tarifaria',
    timeUnit: 'Unidad de Tiempo',
    zoneUnit: 'Unidad de Zona',
    impactUnit: 'Unidad de Impacto',
    holidayName: 'Nombre del Día Festivo',
    salesChannel: 'Canal de Ventas',
    marketSegment: 'Segmento de Mercado',
    accountType: 'Tipo de Cuenta',
    accountSubType: 'Subtipo de Cuenta',
    quantity: 'Cantidad',
    scaledQuantity: 'Cantidad Escalada',
    batchid: 'Id Del Lote',
    batchFileName: 'Archivo Del Lote',
    OK: 'OKAY',
    invoiceDetails: 'Detalles de la Factura',
    pendingBills: 'Facturas Pendientes',
    openCloseBills: 'Cuentas Abiertas / Cerradas',
    total: 'Total',
    nonPayingTotal: 'Total No Pagado',
    billTotal: 'Total de la Factura',
    due: 'Debido',
    dueDate: 'Fecha de Vencimiento',
    invoiceNumber: 'Número de Factura',
    invoiceStatus: 'Estado de la Factura',
    invoiceType: 'Tipo de Factura',
    back: 'Atrás',
    aRType: 'Tipo de CxC',
    arTaxRule: 'Regla de Impuestos CxC',
    arCrDrType: 'Tipo de CR DB CxC',
    invoiceUnitId: 'Id de la Unidad de Factura',
    arOpsData: 'Datos de Operaciones AR',
    invoiceId: 'Id de Factura',
    documentType: 'Tipo de Documento',
    invoiceDate: 'Fecha de Factura',
    subTotal: 'Subtotal',
    taxes: 'Impuestos',
    folioDate: 'Fecha del Folio',
    folioId: 'Id del Folio',
    openingBalance: 'Saldo Inicial',
    closingBalance: 'Saldo Final',
    clear: 'Limpiar',
    forgotPassword: 'Olvidé la Contraseña',
    login: 'Iniciar Sesión',
    signIn: 'Registrarse',
    username: 'Nombre de usuario',
    doNotHaveAAccount: 'Do not have a account ',
    register: 'Register',
    chooseLanguage: 'Choose language',
    accountDetails: 'Account Details',
    creditCardDetails: 'Credit Card Details',
    selectCreatePackage: 'Select/Create Package',
    createYourPackage: 'Create Your Package',
    last4CC: 'Last 4CC',
    packageSelected: 'Package Selected',
    selectPackage: 'Select Package',
    createPackage: 'Create Package',
    createUser: 'Create User',
    currentBalance: 'Saldo Actual',
    currentBillTotal: 'Total actual de factura',
    lastInvoiceDate: 'Fecha de Última Factura',
    lastInvoiceTotal: 'Total de la Última Factura',
    lastInvoiceDueDate: 'Fecha de Vencimiento de la Última Factura',
    lastInvoiceDue: 'Última Factura Vencida',
    lastPaymentDate: 'Fecha del Último Pago',
    lastPaymentAmount: 'Último Monto Pagado',
    crARSinceLastInvoice: 'Cr AR desde la última factura',
    drARSinceLastInvoice: 'Db AR desde la última factura',
    accountData: 'Datos de la Cuenta',
    billingProfile: 'Perfil de Facturación',
    purchaseOrder: 'Orden de Compra',
    subscriptionData: 'Datos de Suscripción',
    orders: 'Pedidos',
    viewCreditNotes: 'Ver Notas de Crédito',
    viewARActivity: 'Ver Actividad CxC',
    reports: 'Reportes',
    accountActivity: 'Actividad de la Cuenta',
    collectionActivity: 'Actividad de Cobranza',
    collectionUnitId: 'Id del Unidad de Cobranza',
    daysInCollection: 'Días en Cobranza',
    lastActionDate: 'Fecha de Última Acción',
    closeDate: 'Fecha de Cierre',
    notes: 'Notas',
    collectionActionsForInvoice: 'Acciones de Cobro por Factura',
    date: 'Fecha',
    billingDom: 'Día del mes de Facturación',
    billingSegment: 'Segmento de Facturación',
    billingFrequency: 'Frecuencia de Facturación',
    invoiceDelivery: 'Entrega de Facturas',
    submittedDate: 'Fecha de Envío',
    action: 'Acción',
    entityType: 'Tipo de Entidad',
    activityDate: 'Fecha de Actividad',
    accountInfo: 'Informacion de Cuenta',
    customerActivity: 'Actividad del Cliente',
    paymentProfileId: 'Id del Perfil de Pago',
    creditProfileIndex: 'Indice del Perfil Crediticio',
    customerSegment: 'Segmento de Clientes',
    sellingCompany: 'Empresa Vendedora',
    lineOfBusiness: 'Línea de Negocio',
    legalEntity: 'Entidad Legal',
    contact: 'Contacto',
    addresses: 'Direcciones',
    paymentProfile: 'Perfil de pago',
    customAttributes: 'Atributos del Cliente',
    salutation: 'Saludo',
    position: 'Puesto',
    organization: 'Organización',
    phone: 'Teléfono',
    roles: 'Roles',
    useAsBilling: 'Usar como Facturación',
    useAsSoldTo: 'Usar como vendido a',
    useAsPayment: 'Usar como Pago',
    useAsService: 'Usar como Servicio',
    addPhone: 'Añadir teléfono',
    middleName: 'Segundo Nombre',
    extraLine: 'Linea adicional',
    landmark: 'Punto de referencia',
    paymentTerm: 'Agregar nueva dirección',
    paymentMethod: 'Forma de Pago',
    cardToken: 'Token de la Tarjeta de Crédito',
    cardExp: 'Exp de Tarjeta',
    noteType: 'Tipo de Notas',
    creditNotes: 'Notas de Crédito',
    externalPurchaseOrder: 'Orden de Compra Externa',
    PONumber: 'Número de Código Postal',
    name: 'Nombre',
    PODescription: 'Descripción de Orden de Compra',
    PO: 'Código Postal',
    clientId: 'Id del Cliente',
    salesAgentId: 'ID de Agente de Ventas',
    keyLink: 'Enlaces Clave',
    linkText: 'Texto del Enlace',
    URL: 'URL',
    advertisement: 'Anuncio',
    advHeader: 'Encabezado de Anuncio',
    advShortDescription: 'Descripción Corta Anuncio',
    home: 'Inicio',
    percent: 'Porcentaje',
    selectInvoice: 'Seleccionar Factura',
    select: 'Seleccionar',
    download: 'Descargar',
    viewPayment: 'Ver Pago',
    externalId: 'Id externo',
    remainingAmount: 'Cantidad Restante',
    paymentDate: 'Fecha de Pago',
    payments: 'Pagos',
    request: 'Solicitud',
    response: 'Respuesta',
    entityId: 'Id de Entidad',
    excel: 'Excel',
    pdf: 'Pdf',
    emails: 'Email(‘s)',
    addAEmail: 'Agregar un email',
    invoiceCurrency: 'Moneda de la Factura',
    totalFunctionalCurrency: 'Total </br> (Moneda Funcional)',
    functionalCurrency: 'Moneda Funcional',
    totalFunctionalCurrencyExport: 'Total (Moneda Funcional)',
    exchangeRate: 'Tipo de Cambio',
    commName: 'Nombre Comercial',
    commercialName: 'Nombre Comercial',
    folioStatus: 'Estado del Folio',
    historicalFolios: 'Folios Históricos',
    CDRReports: 'CDR Reports',
    platform: 'Plataforma',
    fileName: 'Nombre del Archivo',
    fileId: 'Id del Archivo',
    dateTime: 'Fecha/Hora',
    totalInputRecords: 'Registros de Entrada Totales',
    totalImportedRecords: 'Total de Registros Importados',
    totalErrorRecords: 'Total de Registros de Error',
    rawUsageFiles: 'Archivos de Uso Crudos',
    switchName: 'Cambiar Nombre',
    aPartyNumber: 'Número A Party',
    bPartyNumber: 'Número B Party',
    aPartyNumberStartRange: 'Rango de Inicio de Número A Party',
    aPartyNumberEndRange: 'Rango Final de Número A Party',
    bPartyNumberStartRange: 'Rango de Inicio de Número B Party',
    bPartyNumberEndRange: 'Rango Final de Número B Party',
    sourceIpAddress: 'Dirección IP Origen',
    destinationIpAddress: 'Dirección IP de Destino',
    rawBroadsoftZeroDurationCDR: 'Raw Broadsoft Zero Duration CDR',
    sServiceProvider: 'S_SERVICEPROVIDER',
    sUserNumber: 'S_USERNUMBER',
    sGroupNumber: 'S_GROUPNUMBER',
    sDirection: 'S_DIRECTION',
    sCallingNumber: 'S_CALLINGNUMBER',
    sCalledNumber: 'S_CALLEDNUMBER',
    sStartTime: 'S_STARTTIME',
    sAnswerIndicator: 'S_ANSWERINDICATOR',
    sAnswerTime: 'S_ANSWERTIME',
    sReleaseTime: 'S_RELEASETIME',
    sTerminationCause: 'S_TERMINATIONCAUSE',
    sDailedDigits: 'S_DIALEDDIGITS',
    sCallCategory: 'S_CALLCATEGORY',
    sNetworkCallType: 'S_NETWORKCALLTYPE',
    sNetworkTranslatedNumber: 'S_NETWORKTRANSLATEDNUMBER',
    sReleasingParty: 'S_RELEASINGPARTY',
    sRoute: 'S_ROUTE',
    sNetworkCallId: 'S_NETWORKCALLID',
    sCodec: 'S_CODEC',
    sAccessDeviceAddress: 'S_ACCESSDEVICEADDRESS',
    sGroup: 'S_GROUP',
    sDepartment: 'S_DEPARTMENT',
    sAuthorizationCode: 'S_AUTHORIZATIONCODE',
    sOriginalCalledNumber: 'S_ORIGINALCALLEDNUMBER',
    sOriginalCalledPresentationIndicator: 'S_ORIGINALCALLEDPRESENT_INDIC',
    sOriginalCalledReason: 'S_ORIGINALCALLEDREASON',
    sRedirectingNumber: 'S_REDIRECTINGNUMBER',
    sRedirectingPresentationIndicator: 'S_REDIRECTINGPRESENT_INDIC',
    sRedirectingReason: 'S_REDIRECTINGREASON',
    sChargeIndicator: 'S_CHARGEINDICATOR',
    sVoicePortalInvocationTime: 'S_VOICEPORTALCALLING_INVOCTIME',
    dExtAnswerDateTime: 'D_EXT_ANSWER_DATE_TIME',
    nExtDurationMinutes: 'N_EXT_DURATION_MINUTES',
    nExtDurationSeconds: 'N_EXT_DURATION_SECONDS',
    sReserved: 'S_EXT_RESERVED',
    sUserid: 'S_USERID',
    sOtherPartyName: 'S_OTHERPARTYNAME',
    durationMinutesStart: 'Duración Minutos Inicio',
    durationMinutesEnd: 'Duración Minutos Fin',
    incomingRoute: 'Ruta Entrante',
    outgoingRoute: 'Ruta Saliente',
    failureReason: 'Razón de Falla',
    idd: 'IDD',
    IDO: 'IDO',
    reserved: 'Reserved',
    rawBroadsoft: 'Registros Crudos Broadsoft',
    metaswitch: 'Metaswitch',
    metaswitchZero: 'Metaswitch Zero',
    callingNumber: 'Número que Llama',
    calledNumber: 'Número Llamado',
    answerTime: 'Tiempo de Respuesta',
    sreserved: 'SReservado',
    disconnectReason: 'Motivo de Desconexión',
    failureCode: 'Código de Falla',
    callStatus: 'Estado de la Llamada',
    terminatingAccess: 'Terminación de Acceso',
    extIddAbc: 'Ext IDD ABC',
    extIddBcd: 'Ext IDD BCD',
    commercial: 'Comercial',
    callDuration: 'Duración de la Llamada',
    extIdoBcd: 'Ext. IDO BCD',
    extDurationMinutes: 'Minutos de Duración Ext',
    extDurationSeconds: 'Segundos de Duración Ext',
    partySipCallIdZero: 'Party Sip Call Id Zero',
    partySipCallIdOne: 'Party Sip Call Id One',
    startTime: 'Hora de Inicio',
    endTime: 'Hora de Finalización',
    rawSMSFiles: 'Archivo de SMS Sin Procesar',
    networkId: 'Id de Red',
    messageLength: 'Longitud del Mensaje',
    messageType: 'Tipo de Mensaje',
    first20Chars: 'Primeros 20 Caracteres',
    optTag: 'Opt Tag',
    optLength: 'Opt Length',
    optVal: 'Opt Val',
    host: 'Host',
    esmClass: 'Esm Class',
    gsmsiei: 'Gsmsiei',
    gsmudh: 'Gsmudh',
    messageDeliveryStatus: 'Estado de Entrega de Mensajes',
    extIdCinta: 'Ext Id Cinta',
    messageId: 'Id del Mensaje',
    sequenceNumber: 'Número Secuencial',
    commandId: 'Id del Comando',
    commandLength: 'Longitud del Comando',
    pduType: 'Tipo PDU',
    aspName: 'Nombre ASP',
    messageStatus: 'Estado del Mensaje',
    submitDate: 'Fecha de Envío',
    addressSrcDigits: 'Dígitos Dirección Origen',
    srcTon: 'Ton Destino',
    srcApi: 'API Destino',
    addressDestDigits: 'Digitos Dirección Destino',
    destTon: 'Dest Ton',
    destApi: 'Dest Api',
    scheduleDeliveryTime: 'Programar Tiempo de Entrega',
    validityPeriod: 'Período de Validez',
    protocolId: 'Id del Protocolo',
    priority: 'Prioridad',
    registerDelivery: 'Registrar Entrega',
    replacePresent: 'Reemplazar Presente',
    dataCoding: 'Codificación de Datos',
    onDemand: 'On Demand/IDRS Cablevisión',
    ipAddress: 'Dirección IP',
    countLessThan: 'Conteo <',
    volumeUpload: 'Carga de Volumen',
    volumeDownload: 'Descarga de Volumen',
    recordCount: 'Conteo de Registros',
    recordDate: 'Fecha del Registro',
    uploadVolume: 'Cargar Volumen',
    downloadVolume: 'Descargar Volumen',
    details: 'Detalles',
  },

  message: {
    canNotBeBlank: 'no puede quedarse en blanco.',
    noRecord: 'No se ha encontrado ningún registro!',
    cvvMsg: '4 caracteres.',
    msgDeleteToken: '¿Está seguro que quiere borrar este token de la tarjeta?',
    msgHaveAccount: '¿Ya tienes un Número de Cuenta?',
    msgSelectCity: 'Por favor seleccione la Ciudad antes',
    msgSelectState: 'Por favor seleccione el Estado antes',
    contentLeftViewAccountDetails:
      'Proporcione información básica como su nombre y dirección para crear su cuenta de cliente. Seleccione su UserId y contraseña para su acceso al Portal de Auto-Atención.',
    contentLeftViewCreateUser:
      'Ya debería tener una cuenta con una suscripción. Seleccione su UserId y contraseña y vincule el accountId con su cuenta de usuario.',
    contentLeftViewCreditCardDetails:
      'Para agregar una nueva tarjeta de crédito, la interfaz de usuario lo llevará al widget de la cuenta del comerciante y obtendrá los tokens encriptados para garantizar un cumplimiento total con PCI. Proporcione sus datos como se indica en el widget.',
    contentLeftViewSelectPackage:
      'Utilice esta opción para seleccionar un paquete preconfigurado para su nueva suscripción. Esto es necesario si no tiene un ID de cuenta establecido para la suscripción. Solo un paquete puede ser seleccionado.',
    contentLeftViewCreatePackage:
      'Utilice esta opción para crear su propio paquete. Seleccione el Tipo de Servicio de la lista desplegable y seleccione uno o más Bundles  para el tipo de servicio. Repita lo mismo para tipos de servicios adicionales.',
  },

  apiMessage: {
    sentEmailSuccessfully: '¡Email Enviado con Éxito!',
    createFileSuccessfully: '¡Archivo Creado con Éxito!',
  },

  dropdown: {
    subscriptionStatus: {
      ACTIVE: 'ACTIVO',
      SUSPENDED: 'SUSPENDIDO',
      CLOSED: 'CERRADO',
    },

    termUnit: {
      MONTHS: 'MESES',
      QUARTERS: 'TRIMESTRES',
      YEARS: 'AÑOS',
      NONE: 'NINGUNA',
    },

    trialTermUnit: {
      DAYS: 'DÍAS',
      WEEKS: 'SEMANAS',
      MONTHS: 'MONTHS',
      QUARTERS: 'TRIMESTRES',
      YEARS: 'AÑOS',
    },

    trialStatus: {
      OPT_IN: 'OPT_IN (Adhesión)',
      OPT_OUT: 'OPT_OUT (Exclusión)',
    },

    trialTypes: {
      OPT_IN_TRIAL: 'PRUEBA_OPT_IN (Adhesión)',
      OPT_OUT_TRIAL: 'PRUEBA_OPT_OUT (Exclusión)',
    },

    serviceType: {
      DEFAULT: 'PREDETERMINADO',
      VOIP: 'VOIP',
      CABLE: 'CABLE',
      UTILITIES: 'SERVICIOS',
      RETAIL: 'MINORISTA',
      WHOLESALE: 'MAYOREO',
      WIRELINE: 'CONEXIÓN_POR_CABLE',
      TELEPHONY: 'TELEFONÍA',
      IPTV: 'IPTV',
      DATA: 'DATOS',
      PROFESSIONAL: 'PROFESIONAL',
      INTERNET: 'INTERNET',
      HOMESECURITY: 'SEGURIDAD_EN_CASA',
      VOICE: 'VOZ',
      SMS: 'SMS',
      MMS: 'MMS',
      EMAIL: 'CORREO_ELECTRÓNICO',
      EQUIPMENT: 'EQUIPO',
      DATACENTER: 'CENTRO_DE_DATOS',
    },

    transactionType: {
      ONE_TIME: 'UNA_VEZ',
      ONE_TIME_CANCEL: 'CANCELAR_UNA_VEZ',
      // FORWARD_RECURRING: 'ADELANTOS_RECURRENTES',
      FORWARD_RECURRING: 'RECURRENTE_ANTICIPADO',
      ARREARS_RECURRING: 'RECURRENTE_VENCIDO',
      USAGE: 'USO',
      VOLUME_DISCOUNT: 'DESCUENTO_POR_VOLUMEN',
      BILL_DISCOUNT: 'DESCUENTO_FACTURA',
      TAX: 'IMPUESTO',
      MANUAL_TRANSACTION: 'TRANSACCIÓN_MANUAL',
      TRUE_UP: 'TRUE_UP',
      NON_TAX: 'SIN_IMPUESTO',
    },

    transactionSource: {
      ORDERS: 'PEDIDOS',
      BILLING: 'FACTURACIÓN',
      JOBS: 'TRABAJOS',
      COLLECTION: 'COBRANZAS',
      MEDIATION: 'MEDIACIÓN',
      BACKOUT: 'RETROCEDER',
    },

    accountSubType: {
      SILVER: 'PLATA',
      GOLD: 'ORO',
      PLATINUM: 'PLATINO',
      NONE: 'NINGUNA',
    },

    accountType: {
      DIRECT_CUSTOMER: 'CLIENTE_DIRECTO',
      PARTNER: 'ASOCIADO',
      PARTNER_CUSTOMER: 'CLIENTE_ASOCIADO',
      RESELLER: 'REVENDEDOR',
      RESELLER_CUSTOMER: 'CLIENTE_REVENDEDOR',
      INTERNAL_TEST_ACCOUNT: 'CUENTA_DE_PRUEBAS_INTERNA',
      EXTERNAL_TEST_ACCOUNT: 'CUENTA_DE_PRUEBAS_EXTERNA',
      BROKER_CHANNEL_PARTNER: 'BROKER_ASOCIADO',
      DATA_SUPPLIER: 'PROVEEDOR_DE_DATOS',
      INTERCOMPANY: 'INTERCOMPAÑÍA',
      CONNECTOR_PARTNER: 'CONECTOR_ASOCIADO',
      AFFILIATE: 'AFILIADO',
      SALES_AGENT: 'AGENTE_VENTAS',
      SALES_CONSULTANT: 'VENTAS_CONSULTOR',
      CLIENT: 'CLIENTE',
      PARTY: 'PARTE',
      INTERCONNECT: 'INTERCONECTAR',
      NONE: 'NINGUNA',
      DISTRIBUTOR: 'DISTRIBUIDOR',
      CARRIER: 'CARRIER',
      ADMIN: 'ADMIN',
      EMPLOYEE: 'EMPLOYEE',
    },

    marketSegment: {
      INDIVIDUAL: 'INDIVIDUAL',
      SMALL_ENTERPRISE: 'PEQUEÑA_EMPRESA',
      SMB: 'PYME',
      LARGE_ENTERPRISE: 'GRAN_EMPRESA',
      NONE: 'NINGUNA',
    },

    rateUnit: {
      EACH: 'CADA',
      DURATION: 'DURACIÓN',
      VOLUME: 'VOLUMEN',
      QOS: 'QOS',
      DOWNLINK: 'ENLACE_DESCENDENTE',
      UPLINK: 'ENLACE_ASCENDENTE',
      SIZE: 'TAMAÑO',
      QUANTITY: 'CANTIDAD',
      VOLUMEDOWNLOAD: 'VOLUMEN_DE_DESCARGA',
      VOLUMEUPLOAD: 'VOLUMEN_DE_CARGA',
      NONE: 'NINGUNA',
    },

    salesChannel: {
      WEB: 'WEB',
      POS: 'POS',
      ECOMMERCE: 'COMERCIO_ELECTRÓNICO',
      ADVERTISEMENT: 'ANUNCIO',
      TELE_MARKET: 'TELE_MARKET',
      AGENT_CARE: 'AGENT_CARE',
      SELFCARE: 'DE_AUTOATENCIÓN',
      NONE: 'NINGUNA',
    },

    timeUnitPricing: {
      WORKWEEK: 'SEMANA_DE_TRABAJO',
      WEEKEND: 'FIN_DE_SEMANA',
      PEAKHOURS: 'HORAS_PICO',
      OFFPEAKHOURS: 'HORARIO_REDUCIDO',
      HOLIDAYSPECIAL: 'DÍAFESTIVO_ESPECIAL',
      ANYTIME: 'EN_CUALQUIER_MOMENTO',
      NONE: 'NINGUNA',
    },

    prorationType: {
      FULL: 'LLENO',
      ZERO: 'CERO',
      PRORATE: 'PRORRATEAR',
    },

    recurringUnit: {
      MONTHS: 'MESES',
      QUARTERS: 'TRIMESTRES',
      YEARS: 'AÑOS',
    },

    recurringType: {
      FORWARD: 'ADELANTE',
      ARREARS: 'ATRASOS',
    },

    billStatus: {
      PENDING: 'PENDIENTE',
      ACTIVE: 'ACTIVO',
    },

    invoiceStatus: {
      ACTIVE: 'ACTIVO',
      CLOSED: 'CERRADO',
      COLLECTION: 'COBRANZAS',
      // SENT: 'ENVIADO',
      PENDING_APPROVAL: 'APROBACIÓN_PENDIENTE',
      APPROVED: 'APROBADO',
      DISAPPROVED: 'DESAPROBADO',
      SYNC_FAILED: 'SINCRONIZACIÓN_FALLIDA',
      // SEND_FAILED: 'ENVÍO_FALLIDO',
      // STAMPING_REQUESTED: 'ESTAMPADO_SOLICITADO',
      // STAMPED: 'ESTAMPADO',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      // CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
    },

    invoiceUnitType: {
      REGULAR: 'REGULAR',
      CORRECTIVE: 'CORRECTIVO',
      REGENERATED: 'REGENERADO',
    },

    arOpsType: {
      BILL_ADJUSTMENT: 'AJUSTE_DE_FACTURA',
      TRANSACTION_ADJUSTMENT: 'AJUSTE_DE_TRANSACCIÓN',
      BILL_DISPUTE: 'DISPUTA_FACTURA ',
      TRANSACTION_DISPUTE: 'DISPUTA_TRANSACCIÓN',
      BILL_SETTLEMENT: 'LIQUIDACIÓN_DE_FACTURA',
      ACCOUNT_WRITEOFF: 'CANCELACIÓN_DE_CUENTA',
      INVOICE_WRITEOFF: 'CANCELACIÓN_DE_FACTURA',
      TRANSACTION_SETTLEMENT: 'ACUERDO_DE_TRANSACCIÓN',
      WRITE_OFF: 'CANCELACIÓN',
      WRITE_OFF_REVERSAL: 'CANCELACIÓN_DE_REVERSIÓN',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      PAYMENT: 'PAGOS',
      REFUND: 'REEMBOLSO',
      CHARGEBACK: 'DEVOLUCIÓN',
      TAX: 'IMPUESTO',
      TAX_ADJUSTMENT: 'AJUSTE_DE_IMPUESTOS',
      TAX_SETTLEMENT: 'LIQUIDACIÓN_DE_IMPUESTOS',
      TAX_DISPUTE: 'DISPUTA_DE_IMPUESTOS',
      TAX_WRITEOFF: 'CANCELACIÓN_DE_IMPUESTOS',
      TAX_WRITEOFF_REVERSAL: 'REVERSIÓN_DE_CANCELACIÓN_DE_IMPUESTOS',
    },

    taxRule: {
      WITH_TAX: 'CON_IMPUESTO',
      WITHOUT_TAX: 'SOLO_IMPUESTO',
      TAX_ONLY: 'SIN_IMPUESTO',
    },

    CRDRType: {
      CREDIT: 'CRÉDITO',
      DEBIT: 'DÉBITO',
    },

    source: {
      EXTERNAL: 'EXTERNO',
      SELF_CARE: 'AUTO_ATENCIÓN',
      AGENT_CARE: 'AGENT_CARE',
      RERATING: 'RECALIFICACIÓN',
      PAYMENTS: 'PAGOS',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
      INTERNAL_BATCH_JOBS: 'TRABAJOS_POR_LOTES_INTERNOS',
    },

    arCrDrType: {
      DEBIT: 'DÉBITO',
      CREDIT: 'CRÉDITO',
    },

    statusCollections: {
      OPEN: 'ABIERTO',
      CLOSED: 'CERRADO',
      CUSTOMER_CONTACT_INITIATED: 'CONTACTO_CON_CLIENTE_INICIADO',
      CUSTOMER_CONTACT_ESTABLISHED: 'CONTACTO_CON_CLIENTE_ESTABLECIDO',
      PROMISE_TO_PAY: 'PROMESA_DE_PAGO',
      PROMISE_BROKEN: 'PROMESA_INCUMPLIDA',
      SUSPEND: 'SUSPENDER',
      WRITEOFF: 'CANCELACIÓN',
      GRACE_PERIOD_REQUESTED: 'PERIODO_DE_GRACIA_SOLICITADO',
    },

    billingFrequency: {
      MONTHLY: 'MENSUALMENTE',
      BI_MONTHLY: 'BIMENSUAL',
      QUARTERLY: 'TRIMESTRAL',
      SEMI_ANNUAL: 'SEMESTRAL',
      ANNUAL: 'ANUAL',
    },

    invoiceType: {
      SUMMARY: 'RESUMEN',
      DETAIL: 'DETALLE',
      DETAIL_SUPPRESS: 'SUPRIMIR_DETALLE',
    },

    invoiceDelivery: {
      DOWNLOAD: 'DESCARGAR',
      EMAIL: 'CORREO ELECTRÓNICO',
      MAIL: 'CORREO',
    },

    statusOrder: {
      CREATED: 'CREADO',
      PENDING: 'PENDIENTE',
      CANCELED: 'CANCELADO',
      SUBMITTED: 'ENVIADO',
      APPROVED: 'APROBADO',
      PROCESSING: 'PROCESANDO',
      COMPLETED: 'COMPLETADO',
      FAILED: 'FALLIDO',
      PROVISIONING_INITIATED: 'APROVISIONAMIENTO_INICIADO',
      PROVISIONING_ERROR: 'ERROR_DE_APROVISIONAMIENTO',
      BILLING_ERROR: 'ERROR_FACTURACIÓN',
    },

    types: {
      NEW: 'NUEVO',
      MODIFY: 'MODIFICAR',
      SUSPEND: 'SUSPENDER',
      RESUME: 'REANUDAR',
      CANCEL: 'CANCELAR',
      RENEW: 'RENOVAR',
      // OPT_IN: 'PARTICIPACIÓN',
      OPT_IN: 'ADHESIÓN',
      OPT_OUT: 'EXCLUSIÓN',
      UPGRADE: 'SUBBIR_DE_CATEGORÍA',
      DOWNGRADE: 'BAJAR_DE_CATEGORÍA',
      TRIAL: 'PRUEBA',
      TRIAL_SERVICE: 'SERVICIO_PRUEBA',
    },

    entityType: {
      ORDER: 'PEDIDO',
      CUSTOMER: 'CLIENTE',
      CONTACT: 'CONTACTO',
      ADDRESS: 'DIRECCIÓN',
      ITEM: 'ARTÍCULO',
      PAYMENT_PROFILE: 'PERFIL_PAGO',
      BILLING_PROFILE: 'PERFIL_DE_FACTURACIÓN',
      PRICE_OFFER: 'PRECIO_DE_OFERTA',
      BUNDLE: 'BUNDLE',
      PACKAGE: 'PAQUETE',
      DEPENDENCY: 'DEPENDENCIA',
      AROPS: 'OPS_DE_CxC',
      IDENTITY: 'IDENTIDAD',
      TAX: 'IMPUESTO',
      PAYMENT: 'PAGO',
      COLLECTION: 'COBRANZAS',
      INTEGRATION: 'INTEGRACIÓN',
      PAYMENT_SUSPENSE: 'SUSPENSIÓN_DE_PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      DISCOUNT_OFFER: 'OFERTA_DE_DESCUENTO',
      CUSTOM_ATTRIBUTES: 'ATRIBUTOS_PERSONALIZADOS',
      ACCOUNT_INFO: 'INFORMACIÓN_DE_LA_CUENTA',
      BILLING: 'FACTURACIÓN',
      PROVISIONING: 'APROVISIONAMIENTO',
    },

    paymentMethod: {
      CHECK: 'CHEQUE',
      CREDIT_CARD: 'TARJETA_DE_CRÉDITO',
      NON_PAYING: 'NO_PAGADO',
      ECHECK: 'CHEQUE_ELECTRÓNICO',
      MANUAL: 'MANUAL',
    },

    customerSegment: {
      B2B: 'B2B',
      B2C: 'B2C',
      B2B2C: 'B2B2C',
    },

    status: {
      ACTIVE: 'ACTIVO',
      INACTIVE: 'INACTIVO',
      CLOSED: 'CERRADO',
    },

    reason: {
      PAYMENT_FAILURE: 'FALLO_EN_PAGO',
      CUSTOMER_REQUEST: 'SOLICITUD_DEL_CLIENTE',
      COLLECTIONS: 'COBRANZAS',
      INTERNAL_CONTROL: 'CONTROL_INTERNO',
      NONE: 'NINGUNA',
    },

    paymentTerm: {
      NETO_0: 'NETO_0',
      NETO_15: 'NETO_15',
      NET_30: 'NETO_30',
      NET_45: 'NETO_45',
      NET_60: 'NETO_60',
      NET_75: 'NETO_75',
      NET_90: 'NETO_90',
    },

    phoneType: {
      WORK: 'TRABAJO',
      HOME: 'HOGAR',
      MOBILE: 'MÓVIL',
      FAX: 'FAX',
    },

    noteStatus: {
      ACTIVE: 'ACTIVO',
      CLOSED: 'CERRADO',
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      APPROVED: 'APPROVED',
      DISAPPROVED: 'DESAPROBAR',
      SENT: 'SENT',
      COLLECTION: 'COBRANZAS',
      SYNC_FAILED: 'SINCRONIZACIÓN_FALLIDA',
      SEND_FAILED: 'ENVÍO_FALLIDO',
      // STAMPING_REQUESTED: 'ESTAMPADO_SOLICITADO',
      // STAMPED: 'ESTAMPADO',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      // CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
    },

    arNotesType: {
      CREDIT_NOTE: 'NOTA_DE_CRÉDITO',
      DEBIT_NOTE: 'NOTA_DE_DÉBITO',
    },

    statusPayment: {
      OPEN: 'ABIERTO',
      CLOSED: 'CERRADO',
      REVERSED: 'REVOCADO',
      REVERSE_ALLOCATED: 'REVERSIÓN_ASIGNADA',
      REVERSED_TO_SUSPENSE: 'REVERTIDO_A_SUSPENSO',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      // CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
    },

    accountStatementLineType: {
      INVOICE: 'DE_FACTURA',
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      PAYMENT: 'PAGO',
      PAYMENT_REVERSAL: 'REVERSIÓN_DE_PAGO',
      WRITE_OFF: 'CANCELACIÓN',
      WRITE_OFF_REVERSAL: 'CANCELACIÓN_DE_REVERSIÓN',
      CHARGEBACK: 'DEVOLUCIÓN',
      REFUND: 'REEMBOLSO',
      ADJUSTMENT: 'AIUSTES',
      SETTLEMENT: 'LIQUIDACIÓN',
      DISPUTE: 'DISPUTA',
      UNDO_CREDIT_NOTE: 'DESHACER_NOTA_DE_CRÉDITO',
      UNDO_DEBIT_NOTE: 'DESHACER_NOTA_DE_DÉBITO',
    },

    selectBool: {
      TRUE: 'VERDADERO',
      FALSE: 'FALSO',
    },

    folioStatus: {
      STAMPING_REQUESTED: 'ESTAMPADO_SOLICITADO',
      STAMPED: 'ESTAMPADO',
      CANCELLATION_STAMPING_REQUESTED: 'CANCELACIÓN_DE_ESTAMPADO_SOLICITADA',
      CANCELLATION_STAMPED: 'CANCELACIÓN_ESTAMPADA',
      AWAITING_CANCELLATION_STAMPING: 'EN_ESPERA_DE_CANCELACIÓN_DEL_ESTAMPADO',
    },

    switchName: {
      SMS: 'SMS',
      DATA: 'DATA',
      MET: 'MET',
      METII: 'METII',
      METITX: 'METITX',
      SSMETMX: 'SSMETMX',
      SSMET: 'SSMET',
      BROADSOFT: 'BROADSOFT',
      BROADSOFTMTY: 'BROADSOFTMTY',
      NEWSMS: 'NEWSMS',
    },

    switchNameRawFileData: {
      BROADSOFT: 'BROADSOFT',
      BROADSOFTMTY: 'BROADSOFTMTY',
    },

    switchNameRawNonBroadsoft: {
      MET: 'MET',
      METII: 'METII',
      METITX: 'METITX',
      SSMETMX: 'SSMETMX',
      SSMET: 'SSMET',
      DIDWW: 'DIDWW',
      TWILIO: 'TWILIO',
    },

    switchNameRawSmsFile: {
      SMS: 'SMS',
      NEWSMS: 'NEWSMS',
    },
  },
};
